import { useEffect, useRef, useState } from "react";
import MaskedImage from './MaskedImage'
import MobileSectionTextDetails from './MobileSectionTextDetails'

const BasicSectionLayout = ({ content }) => {
  console.log(content);


  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const handleResize = (e) => {

    setIsMobile(window.innerWidth <= 768)
  }


  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  return <div style={{ scrollSnapAlign: 'start' }} key={content?.title || ""}
    className={`flex relative  h-[85vh] md:h-[86vh] lg:h-[79.6vh] w-full overflow-hidden text-white   ${content?.type == 'rtl' ? "flex-row-reverse " : ""}`} >

    <div className={` hidden md:flex relative w-full md:w-[27%] h-full  flex-col justify-end pb-20  px-4 z-10 ${content?.type == 'rtl' ? "text-left border-l-4" : " text-end border-r-4 "}`} style={{ borderColor: content?.color || "#84BE3F" }}>

      {/* Title Image */}<MaskedImage type={content?.type} src={content?.titleImage} dividerType='' />
      {/* Section tiltle */}
      <div>
        <h2 className={`text-3xl  w-2/3 ${content?.type == 'rtl' ? "" : " ml-auto "} font-anton`}>
          <span style={{ color: content?.color || "#84BE3F", }}>{content?.title && content?.title[0]}</span>{content?.title && content?.title.slice(1)}
        </h2>
        <div className={`border-b-2 border-b-main w-2/3  py-2 ${content?.type == 'rtl' ? "" : " ml-auto "}`}> </div>
        <ul className={` text-sm w-[75%]  py-2 ${content?.type == 'rtl' ? "" : " ml-auto "}`}>
          {content?.subElements?.map(li => <li>{content?.subElements.length - 1 ? '-' : ''} {li.title}</li>)}
        </ul>
      </div>

    </div>

    {/* Content Image */}
    {content?.bodyImage ? Array.isArray(content?.bodyImage) ?
      <div className='w-full md:w-[73%] h-full overflow-x-auto section-container '>
        <div className=' flex  w-[200%] h-full'>

          <div className={`w-full h-full ${content?.type == "rtl" ? 'animate-left' : "animate-right"}`} style={{
            backgroundImage: (isMobile && content?.mobile_body) ? `url(${content?.mobile_body})` : `url(${content?.bodyImage[0]})`,
            backgroundRepeat: "no-repeat", backgroundSize: "cover"
          }}>
            <MobileSectionTextDetails content={content} />

          </div>
          <div className='w-full h-full' style={{
            backgroundImage: (isMobile && content?.mobile_body) ? `url(${content?.mobile_body})` : `url(${content?.bodyImage[1]})`,
            backgroundRepeat: "no-repeat", backgroundSize: "cover"
          }}> </div>
        </div>


      </div> : <div className={`w-full md:w-[78%] h-full ${content?.type == "rtl" ? 'animate-left' : "animate-right"}`}
        style={{
          backgroundImage: (isMobile && content?.mobile_body) ? `url(${content?.mobile_body})` : `url(${content?.bodyImage})`,
          backgroundPosition: 'center',
          backgroundRepeat: "no-repeat", backgroundSize: "cover"
        }}>
        <MobileSectionTextDetails content={content} />

      </div> : <></>}
    {/* Content Video */}
    {content?.bodyVideo ? <> <video className='w-full  object-cover' autoPlay loop muted>
      <source src={content?.bodyVideo} type="video/webm" />


    </video>
      <div className="absolute bottom-0 left-0 w-full px-4  ">
        <MobileSectionTextDetails content={content}/>
      </div>
    </> : <></>}

  </div>
}

export default BasicSectionLayout