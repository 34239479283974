import { Link } from 'react-router-dom'

const CoverSectionContent = ({ props }) => {

  const { title, description, listItems, relatedProjects } = props

  return <div className='text-white lg:flex items-end h-[85vh] md:h-[86vh] lg:h-[79.6vh]  px-4 lg:px-[10%] pt-4 lg:pt-0 ' >
    <div className=' mb-36 lg:flex justify-between  gap-x-16 items-end'>
      {/* Left section */}
      <div className='lg:w-2/3'>
        <div>
          <h1 className='text-5xl lg:text-7xl font-anton tracking-tighter' style={{ fontFamily: "Bahnschrift" }} >
            <span className='text-main'>{title[0]}</span>{title.slice(1)}</h1>
        </div>
        <div>
          <p className=' text-sm md:text-[15px]  text-justify py-8'>{description}
          </p>
        </div>
        <div className='mb-2 lg:mb-0'>
          <ul className='flex md:pr-8 flex-wrap gap-x-8 gap-y-4 text-sm md:text-md w-2/3'>
            {listItems?.map((item, index) => <li className={` border-main p-2 border-[1.5px] hover:bg-main `}>{item}</li>)}
          </ul>
        </div>
      </div>
      {/* Right section */}
      <div className='pt-4 lg:w-1/3 '>

        {relatedProjects?.length ? <div className=' py-2 lg:px-2   lg:ml-auto '>
          <p className='border-b-[1px]  border-main '>Related Projects</p>

          <ul className='flex flex-col justify-start max-h-96 overflow-y-scroll custom-scroll  text-sm space-y-2 py-2  opacity-70'>
            {(relatedProjects?.map(item => {
              return <li className='w-2/3 cursor-pointer tracking-wider  hover:text-main hover:border-main border-b-2 '>
                <Link to={`/projects/${item.id}`}>{item?.title}</Link></li>
            }))}
          </ul>
        </div> : <></>}
      </div>
    </div>

  </div>

}
export default CoverSectionContent