import React from 'react'
import maint from '../Assets/Images/maint.jpg'

const Maintenance = () => {
  return (
    <div className='w-screen h-full'>
      <img src={maint} className=' -z-10 w-full object-cover h-[calc(100vh-150px)]' />
      <div className='absolute  right-20 top-40 w-96 mx-40 '>
        <div className='font-bold text-2xl'>
          <p>Under</p>
          <p>Construction</p>
        </div>

        <p>the process of preserving a condition or situation or the state of being preserved.
          "we support local initiatives that ensure the maintenance of community spirit"  Similar:  preservation

          2.
          financial support provided for a person's living expenses.
          "a chance of going to university with fees and maintenance paid"
          Similar:  nurture  feeding     life support     financing     supporting
          support
        </p>
      </div>

    </div>
  )
}

export default Maintenance