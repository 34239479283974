import React, { useEffect, useState } from 'react';
import NewsCard from '../../Components/News/NewsCard';

const NewsForm = () => {
    const [title, setTitle] = useState('');
    const [order, setOrder] = useState(5);
    const [description, setDescription] = useState('');
    const [image, setImage] = useState(null);
    const [schedulePublish, setSchedulePublish] = useState(false);
    const [autoHide, setAutoHide] = useState(false);
    const [publishTime, setPublishTime] = useState('');
    const [autoHideTime, setAutoHideTime] = useState('');
    const [remainingChars, setRemainingChars] = useState(250);
    const [isPreviewOn, setIsPreviewOn] = useState(false)

    const handleOrderDecrease = () => {
        setOrder((prevOrder) => Math.max(0, prevOrder - 1));
    };

    const handleOrderIncrease = () => {
        setOrder((prevOrder) => Math.min(10, prevOrder + 1));
    };

    const handleImageSelect = (e) => {
        const selectedImage = e.target.files[0];
        console.log(selectedImage);
        setImage(selectedImage);
    };

    const handleNewsSubmit = (e) => {
        e.preventDefault();
        // Your submission logic here
    };


    const handleDescriptionChange = (e) => {
        const inputText = e.target.value;
        if (inputText.length <= 250) {
            setDescription(inputText);
            setRemainingChars(250 - inputText.length);
        }
    };


    useEffect(() => {
        if (!schedulePublish) {
            setPublishTime("")
        }
    }, [schedulePublish])
    useEffect(() => {
        if (!autoHide) {
            setAutoHideTime("")
        }
    }, [autoHide])

    return (
        <div className='relative h-full w-full py-4 '>
            <h2 className='font-semibold tracking-wider text-3xl'>Latest News</h2>
            <form className='space-y-4' onSubmit={handleNewsSubmit}>
                <div className='grid grid-cols-6 gap-4'>
                    <div className='flex flex-col col-span-2'>
                        <label className='opacity-100 font-semibold text-lg text-start'>Title</label>
                        <input
                            className='px-2 h-full outline-none border-b-2 focus:border-b-gray-400 py-2'
                            type='text'
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                        />
                    </div>
                    <div className='flex flex-col col-start-4'>
                        <label className='opacity-100 font-semibold text-lg text-start'>Order</label>
                        <div className='flex items-center'>
                            <button onClick={handleOrderDecrease} className='rotate-180 text-3xl'>&#10151;</button>
                            <input
                                className='px-2 h-full outline-none border-b-2 focus:border-b-gray-400 py-2'
                                type='text'
                                value={order}
                                readOnly
                            />
                            <button onClick={handleOrderIncrease} className='text-3xl'>&#10151;</button>
                        </div>
                    </div>
                </div>
                <div className='relative w-full text-lg group '>
                    <label className='opacity-100 px-2 font-semibold text-lg text-start'>Description</label>
                    <textarea
                        className='max-h-48 pb-5 px-2 h-36 min-h-36 outline-none border-b-2 focus:border-b-gray-400 py-2 w-full'
                        value={description}
                        onChange={handleDescriptionChange}
                    >

                    </textarea>
                    <div className="absolute bottom-4 right-10 text-right text-gray-500 text-xs z-10 ">{250 - remainingChars}/250</div>

                </div>
                <div className='relative flex space-x-4'>
                    <input type='file' onChange={handleImageSelect} />
                    <div className='w-full bg-gray-500 bg-opacity-30 h-48 flex justify-center items-center'>
                        <img src={image && URL.createObjectURL(image)} className=' h-[90%]' />
                    </div>

                    {!image && <p className='absolute  z-10 right-1/4 top-1/2 -translate-y-1/2 p-2 text-white font-semibold'>No Image Selected</p>}
                    {image && <button className='absolute  z-10 right-2 top-0 font-bold text-red-600 shadow-lg h-fit' onClick={() => setImage(null)}>X</button>}
                </div>
                <div className='grid grid-cols-7 space-x-4'>
                    <div className='col-span-3 text-start'>
                        <label className='text-start opacity-100'>

                            <input

                                className='mr-2'
                                type='checkbox'
                                checked={schedulePublish}
                                onChange={() => setSchedulePublish(!schedulePublish)}
                            />Schedule Publish
                        </label>

                        <input
                            disabled={!schedulePublish}
                            type='datetime-local'
                            value={publishTime}
                            onChange={(e) => setPublishTime(e.target.value)}
                        />

                    </div>
                    <div className='col-span-3 text-start'>
                        <label className='text-start opacity-100  '>

                            <input
                                className='mr-2'
                                type='checkbox'
                                checked={autoHide}
                                onChange={() => setAutoHide(!autoHide)}
                            />Auto Hide After
                        </label>

                        <input
                            disabled={!autoHide}
                            type='datetime-local'
                            value={autoHideTime}
                            onChange={(e) => setAutoHideTime(e.target.value)}
                        />

                    </div>
                </div>
                <div className='form-btn-container justify-center gap-4'>
                    <button disabled={!image || !title  || !order} onClick={(e) => { setIsPreviewOn(!isPreviewOn) }} className="form-btn">{isPreviewOn ? "Close" : ""} Preview</button>
                    <button className="form-btn" type='submit'>Publish</button>
                </div>

            </form>

            {isPreviewOn && <div className='absolute top-20 z-20 w-full h-full  bg-black bg-opacity-70 p-10'>
                <div
                    className='relative !flex w-full section-container my-auto overflow-x-scroll gap-[2.5rem] py-8 '
                    style={{ scrollSnapType: 'x mandatory', overflowY: 'hidden', overflowX: 'scroll',/*  border: '1px solid #ccc', */ borderRadius: '10px' }}>
                    <NewsCard currentIndex={0} handleHover={() => { }} external={true} index={0}
                        item={{ name: title, description: description, photo: image ? URL.createObjectURL(image) : "", order: order }} />
                    <button className='absolute  z-10 right-2 top-0 font-bold text-red-600 shadow-lg h-fit' onClick={() => setIsPreviewOn(false)}>X</button>

                </div>
            </div>}
        </div >
    );
};

export default NewsForm;
