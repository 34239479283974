import React, { useRef, useState } from 'react';
import BannerImg from '../Assets/Images/Misc/Logo_Competition.jpg';
import BackToTop from '../Components/BackToTop';
import LogoContestModal from '../Components/LogoContestModal';
import '../Assets/Styles/logoContest.css'




const svg_mail = <svg width="28" height="22" viewBox="0 0 28 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M27.3218 6.23917V18.1339C27.3218 20.19 25.6551 21.8567 23.599 21.8567H4.09827C2.04219 21.8567 0.375244 20.19 0.375244 18.1339V6.23917L13.4215 13.4149C13.6874 13.5612 14.0098 13.5612 14.2758 13.4149L27.3218 6.23917ZM23.599 0.583252C25.6252 0.583252 27.2734 2.202 27.3208 4.21683L13.8486 11.6266L0.376663 4.21677L0.378515 4.15266C0.458992 2.16774 2.09359 0.583252 4.09827 0.583252H23.599Z" fill="white" />
</svg>

const svg_prize = <svg width="34" height="31" viewBox="0 0 34 31" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d={`M8.95677 5.93925H6.70059C6.60473 5.93925 6.52703 6.01696 6.52703 6.11281V10.5384C6.52703 12.0095
     7.5729 13.2363 8.96163 13.5158C8.9584 13.4204 8.95677 13.3246 8.95677 13.2285V5.93925ZM9.21601 15.2986C6.74268 
     15.1203 4.7915 13.0572 4.7915 10.5384V6.11281C4.7915 5.05845 5.64623 4.20373 6.70059 4.20373H8.96179C9.05163 
     2.65505 10.336 1.42688 11.9072 1.42688H22.6674C24.2386 1.42688 25.523 2.65505 25.6128 4.20373H27.874C28.9284
      4.20373 29.7831 5.05845 29.7831 6.11281V10.5384C29.7831 13.0572 27.8319 15.1203 25.3586 15.2986C24.5076 18.6266
       21.6525 21.1524 18.1551 21.5144V23.9887H22.1468C24.0638 23.9887 25.6178 25.5428 25.6178 27.4598V27.8069C25.6178
        28.5737 24.9962 29.1953 24.2294 29.1953H10.3452C9.57839 29.1953 8.95677 28.5737 8.95677 27.8069V27.4598C8.95677 
        25.5428 10.5108 23.9887 12.4278 23.9887H16.4195V21.5144C12.9221 21.1524 10.067 18.6266 9.21601 15.2986ZM25.613
         13.5158C27.0017 13.2363 28.0476 12.0095 28.0476 10.5384V6.11281C28.0476 6.01696 27.9699 5.93925 27.874
          5.93925H25.6178V13.2285C25.6178 13.3246 25.6162 13.4204 25.613 13.5158ZM10.6923 4.37728V13.2285C10.6923 16.8708 
          13.645 19.8235 17.2873 19.8235C20.9296 19.8235 23.8823 16.8708 23.8823 13.2285V4.37728C23.8823 3.70632 23.3384 3.16241 22.6674 
          3.16241H11.9072C11.2362 3.16241 10.6923 3.70632 10.6923 4.37728ZM10.6923 27.4598H23.8823C23.8823 26.5013 23.1053 25.7243 22.1468
           25.7243H12.4278C11.4693 25.7243 10.6923 26.5013 10.6923 27.4598Z`} fill="white" />
</svg>

const items_list = [{
    name: 'Guidelines', content: [" Logos that emphasize Tiller's multifaceted strength in fields such as Built Environment, Nature, and Automation will be preferred.",
        "The logo must be the original work of the designer.",
        "Explanation and mockups (e.g., visiting card, letterhead, presentation slide, T-shirt, etc.) of the logo should be submitted.",
        "The logo must be created using any computer drawing program (Preferred Software: Adobe Illustrator).",
        "The logo resolution should be a minimum of 800px*800px.",
        "Kindly rename the files in the your-name_mobile-number.zip format."]
},
{
    name: 'Color Scheme', content: ["Logo should be submitted in four different color background and both the original file and PNG format should be submitted.",
        "One monochrome logo should be submitted."]
},
{ name: 'Requirements/ Eligibility', content: "Single or joint submission (maximum 3 persons per team) will be accepted." },
{
    name: 'Judging Criteria', content: ["Logos will be judged based on their visual appeal, adherence to the concept prompting the contest, the quality of design, and ease of reproduction for the stated purposes.",
        "The judge's decision will be final."]
},
{ name: 'Copyright', content: "Tiller will acquire ownership of the winning logo by assignment of copyright, and the winning designer will disclaim any trademarks and without limitation, all other rights related to the design. By submitting a logo for entry in the competition, the designer acknowledges that he/she is the person that created the logo and is the rightful owner. The designer also certifies that the logo does not infringe upon the rights of any third party and that it does not violate any copyright." },]



const LogoContest = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };
    const guidelinesRef = useRef(null);

    // Function to bring guidelines div into view
    const scrollToGuidelines = () => {
        guidelinesRef.current.scrollIntoView({ behavior: 'smooth' });
    };
    return (
        <div className=' w-full mb-20 md:mb-0 px-4 lg:px-[12%] pb-8 font-poppins'>

            <section className={`${isModalOpen ? 'blur-sm ' : ""} z-10 lg:sticky top-0   w-full  flex flex-col items-start justify-center `}>

                <div className=' relative w-full bg-white h-fit  mt-4 overflow-hidden'>
                    <div className='float-right w-full lg:w-[62%] rounded-3xl mb-4'>
                        <img src={BannerImg} className=' w-full h-full object-fit rounded-3xl' alt='competetion image' />
                    </div>
                    <div className=' lg:absolute left-0 w-full lg:w-[45%] bg-white rounded-tr-[3rem]
                      lg:top-1/2 lg:-translate-y-1/2 py-8 lg:pr-10'>
                        <h1 className='font-[700] text-[2rem] xl:text-[3.5rem] leading-tight text-center lg:text-start '>Company Logo Design Competition</h1>
                        <p className='text-justify py-2'>As a top IT-enabled {/* multidisciplinary */} consultancy, we employ innovative technology for sustainable solutions. Our
                            specialized services cover Urban and Rural Development, Software Development, Water and Sanitation,
                            Smart Cities, Transportation, Environment, Climate Change, Energy, Power, and Capacity Building.</p>
                        <div className='flex w-full  justify-center lg:justify-start items-center gap-8'>
                            <button className={`${isModalOpen ? "" : "blinking-button"} hover:animate-none text-lg  active:scale-95  bg-main py-2 px-8
                         text-white rounded-lg font-semibold hover:cursor-pointer`} onClick={handleOpenModal}>Enter Contest</button>
                            <button className={` hover:animate-none text-lg  active:scale-95  bg-main py-2 px-8
                         text-white rounded-lg font-semibold hover:cursor-pointer  `} onClick={scrollToGuidelines}>See Guidelines</button>
                        </div>

                    </div>



                </div>


                <div className='lg:flex w-full  py-6 gap-10 justify-between text-2xl space-y-8 lg:space-y-0'>
                    <div className='space-y-[1px] text-center lg:w-[calc(38%-40px)]'>
                        <div className='bg-main text-white font-semibold px-20 text-lg  rounded-tr-xl py-2  flex justify-center items-center  gap-4 '><span>{svg_prize}</span> Prize</div>
                        <div className='bg-main text-white font-semibold py-2  text-xl rounded-bl-xl '>15,000 BDT</div>
                    </div>
                    <div className='space-y-[1px] lg:w-[calc(59.5%+40px)]'>
                        <div className='bg-main text-white font-semibold rounded-tr-xl py-2 px-8  flex justify-center items-center  gap-4 text-lg'><span>{svg_mail}</span>Submission {/* Method and */} deadline</div>
                        <div className='bg-main text-white font-semibold rounded-bl-xl py-2 px-8 text-start sm:text-center text-lg '>15th February 2024, 11:59pm BST {/* at contest.tiller@gmail.com */}</div>
                    </div>
                </div>
            </section>
            <section ref={guidelinesRef} className={` ${isModalOpen ? 'blur-sm ' : ""}  relative z-20 w-full bg-white bg-opacity-95 border-t-2  pt-4`} style={{ 'list-style': 'none' }}>

                <div className='space-y-4 py-4 px-4'>
                    {items_list.map(item => {
                        return (<div >
                            <h2 className='font-semibold text-2xl'>{item.name}</h2>
                            <ul className=" py-3 list-disc space-y-2 text-justify">     {Array.isArray(item.content) ? item.content.map(it => <li>{it}</li>) : <p>{item.content}</p>}</ul>
                        </div>)
                    })}


                </div>
            </section>

            <LogoContestModal isOpen={isModalOpen} onClose={handleCloseModal} />
            <BackToTop />
        </div>
    )
}
export default LogoContest