import React, { useState } from 'react'
import Tabs from './Tabs'
import NewsForm from './NewsForm'
import BreadCrumb from '../../Components/BreadCrumb'

const Admin = () => {

    const [selectedTab, setSelectedTab] = useState("News")

    return (
        <div className='w-full h-[calc(76.9*10px)]  '>
            <BreadCrumb />
            <div className='py-2 px-2 md:px-[10%] lg:px-[25%]'>
                <Tabs selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
                <NewsForm />
            </div>


        </div>
    )
}

export default Admin