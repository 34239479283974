import React, { useEffect, useRef, useState } from 'react'
import { servicesArray } from '../../Utils/service_util'
import { Link } from 'react-router-dom'
// import '../../Assets/Styles/serviceitem.css'
const ServiceSideNav = ({ id }) => {

    const [expanded, setExpanded] = useState(false)
    const navRef = useRef(null);
    let timeoutId;

    const toggleExpansion = (e, state) => {
        setExpanded(state || !expanded)
    }


    const handleOutsideClick = (e) => {
        if (navRef.current && !navRef.current.contains(e.target)) {
            setExpanded(false);
        }
    };


    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);
    return (
        <div
            ref={navRef}

            className={`animate-sliding-from-left hidden md:flex absolute  -left-6 top-1/2 -translate-y-1/2  pr-4 pl-8 py-4  z-[60]  w-fit
         h-fit  flex-col gap-6 justify-center bg-[#363636]  
         rounded-r-md  transition-all  text-white font-semibold font-poppins `}>
            {expanded ? servicesArray.sort((prev, next) => prev.order - next.order).map((item, index) => {

                return (<div className={`animate-sliding-from-left group relative rounded-r- md group w-full border-[2px]
hover: border-main border-${id == item.id ? 'main' : 'transparent'} rounded-md`}>
                    <Link to={`/services/${item.title} `}>
                        <img src={item?.icon} className='bg-[#606060]  rounded-md w-12 p-2 cursor-pointer ' />
                    </Link>
                    <div className='group-hover:block hidden absolute left-full  top-1/2 
                    -translate-y-1/2 w-full '>

                        <Link to={`/services/${item.title} `}
                            className={`bg-[#363636] whitespace-nowrap rounded-md border-[2px]
group-hover: border-main  border-${id == item.id ? 'main' : 'transparent'} ml-8  px-2  py-1 w-fit`}>{item?.title}</Link>
                    </div>
                </div>)
            }) : <svg xmlns="http://www.w3.org/2000/svg" onClick={e => toggleExpansion(e)} fill="none" viewBox="0 0 24 24"
                strokeWidth={2.5} stroke="currentColor" className="w-6 h-6 cursor-pointer">
                <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3" />
            </svg>
            }
        </div>
    )
}

export default ServiceSideNav