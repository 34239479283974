import React, { useEffect, useRef, useState } from 'react'

/* const SinglePhotoLayout = ({ name, photo_item ,text_type}) => {
    return <div className='items-start h-full '>
        <h2 className='title'>{name}</h2>
        <img src={(photo_item?.length)?photo_item[0]:""}
            className='image    ' />
    </div>
} */

const OnlyPhotoLayout = ({ name, photo_item }) => {
    return <div className={`  grid grid-cols-${photo_item?.length} gap-8 items-start h-full `}>
        {name ? <h2 className='title'>{name}</h2> : <></>}
        {photo_item?.map(item => <div className='relative'>
            <img src={item.photo}
                className='relative  image   border-[#e2e2e2] border-[1px] object-cover w-full' />

            <caption className='absolute bottom-0 left-0 bg-slate-400 bg-opacity-60 text-[#f2f2f2] px-4 
            italic text-xs font-semibold'>{item.caption}</caption>
            <caption className='absolute bottom-0 right-0 bg-slate-400 bg-opacity-60 text-[#f2f2f2] px-4 
            italic text-xs font-semibold'>{item.source}</caption>
        </div>)}
    </div>
}

const TextOnlyLayout = ({ name, data, text_type }) => {
    return <div className='sections-content items-start h-full '>

        {name && <h2 className='title w-full'>{name}</h2>}
        {data?.map(text => <p className='font-oswald'>{`${text_type == 'bullet-points' ? '• ' : ''}${text}`}</p>)}
    </div>
}
const LeftTextLayout = ({ name, data, photo_item, text_type }) => {

    return <div className=' relative sections-grid-layout '>

        <div className='sections-content'>


            <div className='relative group'>
                <h2 className='title'>{name}</h2>
                <div className="border-b-2 border-b-main left-0 right-[60%] group-hover:right-[40%] absolute bottom-0 transition-all duration-1500"></div>
            </div>


            {data?.map(text => <p className='font-oswald'>{`${text_type == 'bullet-points' ? '• ' : ''}${text}`}</p>)}

        </div>
        <div className='relative'>
            <img src={(photo_item?.length) ? photo_item[0].photo : ""}
                className='  image   border-[#e2e2e2] border-[1px]' />
            <caption className='absolute bottom-0 left-0 bg-slate-400 bg-opacity-60 text-[#f2f2f2] px-4 
            italic text-xs font-semibold'>{photo_item[0].caption}</caption>
            <caption className='absolute bottom-0 right-0 bg-slate-400 bg-opacity-60 text-[#f2f2f2] px-4 
            italic text-xs font-semibold'>{photo_item[0].source}</caption>
        </div>

    </div>
}

const RightTextLayout = ({ name, data, photo_item, text_type }) => {
    // return < ParagraphWithImage imageUrl={(photo_item?.length) ? photo_item[0].photo : ""} paragraphText={data?.map(text => <p className='font-oswald'>{`${text_type == 'bullet-points' ? '• ' : ''}${text}`}</p>)} />
    return <div className='sections-grid-layout relative w-full'>
        <h2 className='title block lg:hidden '>{name}</h2>
        <div className='relative'>
            <img src={(photo_item?.length) ? photo_item[0].photo : ""}
                className='  image   border-[#e2e2e2] border-[1px]' />
            <caption className='absolute bottom-0 left-0 bg-slate-400 bg-opacity-60 text-[#f2f2f2] px-4 
            italic text-xs font-semibold'>{photo_item[0].caption}</caption>
            <caption className='absolute bottom-0 right-0 bg-slate-400 bg-opacity-60 text-[#f2f2f2] px-4 
            italic text-xs font-semibold'>{photo_item[0].source}</caption>
        </div>

        <div className='sections-content'>
            <h2 className='title hidden lg:block '>{name}</h2>
            {data?.map(text => <p className='font-oswald'>{`${text_type == 'bullet-points' ? '• ' : ''}${text}`}</p>)}

        </div>


    </div>
}

const LeftMultiTextLayout = ({ data, photo_item, text_type }) => {
    return <div className='sections-grid-layout relative'>
        <div>
            {data?.map(text_content => {
                return <div className='sections-content'>
                    <h2 className='title'>{text_content.name}</h2>
                    {text_content.content?.map(text => <p className='font-oswald'>{`${text_type == 'bullet-points' ? '• ' : ''}${text}`}</p>)}
                </div>
            })}
        </div>
        <div className='relative'>
            <img src={(photo_item?.length) ? photo_item[0].photo : ""}
                className='  image   border-[#e2e2e2] border-[1px]' />
            <caption className='absolute bottom-0 left-0 bg-slate-400 bg-opacity-60 text-[#f2f2f2] px-4 
            italic text-xs font-semibold'>{photo_item[0].caption}</caption>
            <caption className='absolute bottom-0 right-0 bg-slate-400 bg-opacity-60 text-[#f2f2f2] px-4 
            italic text-xs font-semibold'>{photo_item[0].source}</caption>
        </div>

    </div>
}

const RightMultiTextLayout = ({ data, photo_item, text_type }) => {
    return <div className='sections-grid-layout relative'>

        <div className='relative'>
            <img src={(photo_item?.length) ? photo_item[0].photo : ""}
                className='  image   border-[#e2e2e2] border-[1px]' />
            <caption className='absolute bottom-0 left-0 bg-slate-400 bg-opacity-60 text-[#f2f2f2] px-4 
            italic text-xs font-semibold'>{photo_item?.length && photo_item[0].caption}</caption>
            <caption className='absolute bottom-0 right-0 bg-slate-400 bg-opacity-60 text-[#f2f2f2] px-4 
            italic text-xs font-semibold'>{photo_item?.length && photo_item[0].source}</caption>
        </div>
        <div>
            {data?.map(text_content => {
                return <div className='sections-content'>
                    <h2 className='title'>{text_content.name}</h2>
                    {text_content.content?.map(text =>
                        <p className='font-oswald'>
                            {`${text_type == 'bullet-points' ? '• ' : ''}${text}`}</p>)}
                </div>
            })}
        </div>
    </div>
}
const ParagraphWithImage = ({ imageUrl, paragraphText }) => {
    const [paragraphHeight, setParagraphHeight] = useState('auto');
    const imageRef = useRef(null);
    useEffect(() => {
        const paragraphElement = imageRef.current.previousSibling;
        const height = paragraphElement?.offsetHeight;
        setParagraphHeight(height);
    }, [paragraphText]);
    return (
        <div className="flex">
            <div className="flex-1 p-6">
                <p ref={imageRef} className="text-lg">
                    {paragraphText}
                </p>
            </div>
            <div className="flex-1 flex justify-center items-center">
                <img src={imageUrl} alt="Image" style={{ maxHeight: paragraphHeight }} />
            </div>
        </div>
    );
};
const HorizontalMultiTextLayout = ({ data, photo_item, text_type }) => {
    return <div className=' gap-8  h-full '>
        <div className='flex justify-between items-start'>
            {data?.map(text_content => {
                return <div className='sections-content '>
                    <h2 className='title'>{text_content.name}</h2>
                    {text_content.content?.map(text => <p className='font-oswald'>{`${text_type == 'bullet-points' ? '• ' : ''}${text}`}</p>)}
                </div>
            })}
        </div>

        <div className='relative'>
            <img src={(photo_item?.length) ? photo_item[0] : ""}
                className='  image    ' />
            <caption className='absolute bottom-0 left-0 bg-slate-400 bg-opacity-60 text-[#f2f2f2] px-4 
            italic text-xs font-semibold'>{photo_item?.length && photo_item[0].caption}</caption>
            <caption className='absolute bottom-0 right-0 bg-slate-400 bg-opacity-60 text-[#f2f2f2] px-4 
            italic text-xs font-semibold'>{photo_item?.length && photo_item[0].source}</caption>
        </div>
    </div>
}



const handleTypetoLayout = (props) => {

    switch (props.type) {
        case 'only-photo':
            return <OnlyPhotoLayout   {...props} />



        case 'text-only':
            return <TextOnlyLayout   {...props} />


        case 'left-text':

            return <LeftTextLayout   {...props} />


        case 'right-text':

            return <RightTextLayout   {...props} />


        case 'left-multi-text':
            return <LeftMultiTextLayout   {...props} />
        case 'right-multi-text':
            return <RightMultiTextLayout   {...props} />
        case 'text-horizontal':
            return <HorizontalMultiTextLayout {...props} />
        default:
            return <></>

    }
}

const ProjectDetailContent = (props) => {



    return (
        <div>
            {handleTypetoLayout(props)}
        </div>
    )
}

export default ProjectDetailContent