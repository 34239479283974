import { toast } from "react-toastify";

const getType = (type) => {
    switch (type) {
        case 'success':
            return 'success';
        case 'error':
            return 'error';

        case 'warning':
            return 'warning';
        case 'info':
            return 'info';
        default:
            return 'success';
    }
}

const getPosition = (position) => {
    switch (position) {
        case 'top-left':
            return 'top-left';
        case 'top-right':
            return 'top-right';
        case 'top-center':
            return 'top-center';
        case 'bottom-left':
            return 'bottom-left';
        case 'bottom-right':
            return 'bottom-right';
        case 'bottom-center': 
            return 'bottom-center';
        default:
            return 'bottom-right';
       
    }
}


export const showToast = ({message = "", type, position='bottom-right' , duration = 1000, bar = true}) => {


    toast[getType(type)](message, {
        
        autoClose: duration,
        hideProgressBar: !bar,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });
}

export default showToast