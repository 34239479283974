import React, { useEffect, useRef, useState } from 'react'
import cover from '../../Assets/Images/Services/ServicesNew/SoftwareDevelopment/softwareservicecover.gif'
import Typewriter from 'typewriter-effect';
import { motion } from 'framer-motion';

import dash_front from '../../Assets/Images/Services/ServicesNew/SoftwareDevelopment/dash_front.png'
import dashboard from '../../Assets/Images/Services/ServicesNew/SoftwareDevelopment/dashboard.png'
import plis_top from '../../Assets/Images/Services/ServicesNew/SoftwareDevelopment/plis_top.png'
import plis_top_mobile from '../../Assets/Images/Services/ServicesNew/SoftwareDevelopment/plis_top_mobile.png'

import sanboard_bg from '../../Assets/Images/Services/ServicesNew/SoftwareDevelopment/sanboard bg.png'
import sanboard_1 from '../../Assets/Images/Services/ServicesNew/SoftwareDevelopment/sanboard 1.png'
import sanboard_2 from '../../Assets/Images/Services/ServicesNew/SoftwareDevelopment/sanboard 2.png'
import nsdtop_mobile from '../../Assets/Images/Services/ServicesNew/SoftwareDevelopment/nsd_up_mobile.png'
import nsdbottom_mobile from '../../Assets/Images/Services/ServicesNew/SoftwareDevelopment/nsd_bottom_mobile.png'

import city_img from '../../Assets/Images/Services/ServicesNew/SoftwareDevelopment/cityyyy.png'
import building from '../../Assets/Images/Services/ServicesNew/SoftwareDevelopment/building (1).gif'
import checklist1 from '../../Assets/Images/Services/ServicesNew/SoftwareDevelopment/list-select-3.gif'
import checklist2 from '../../Assets/Images/Services/ServicesNew/SoftwareDevelopment/list-select-2.gif'
import checklist3 from '../../Assets/Images/Services/ServicesNew/SoftwareDevelopment/list-select-1.gif'

import traffic_light from '../../Assets/Images/Services/ServicesNew/SoftwareDevelopment/traffic-lights.gif'
import transport_top from '../../Assets/Images/Services/ServicesNew/SoftwareDevelopment/transport_top.png'
import transport_bottom from '../../Assets/Images/Services/ServicesNew/SoftwareDevelopment/transport_bottom.png'

import satalite_gif from '../../Assets/Images/Services/ServicesNew/SoftwareDevelopment/satellite.gif'
import e_gis from '../../Assets/Images/Services/ServicesNew/SoftwareDevelopment/e_gis.png'
import electric_tower_gif from '../../Assets/Images/Services/ServicesNew/SoftwareDevelopment/electric-tower.gif'
import holding_tax from '../../Assets/Images/Services/ServicesNew/SoftwareDevelopment/holding_tax.png'
import inheritance_gif from '../../Assets/Images/Services/ServicesNew/SoftwareDevelopment/inheritance.gif'
import remote_sensing from '../../Assets/Images/Services/ServicesNew/SoftwareDevelopment/remote_sensing.png'


const SelfWritingText = ({ content }) => {
    return (
        <div className="relative uppercase text-main text-[5rem] lg:text-[10rem] font-bebes text-center">
            <motion.div
                initial={{ opacity: 0, scale: 0.5 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.5 }}
                transition={{ duration: 0.5 }}
            >
                <Typewriter
                    options={{

                        cursorClassName: '!font-thin',
                        cursor: "|",
                        strings: ['Technology', 'Innovation', 'Creative-Mind'],
                        pauseFor: 0,
                        autoStart: true,
                        loop: true,
                        deleteSpeed: 150,
                        delay: 150

                    }}
                />
            </motion.div>
        </div>
    );
};

const Separator = ({ height = "10vh", width = '100%', content = [] }) => {
    return <div className={`h-[${height}]  text-2xl w-full bg-transparent font-poppins  px-4
    font-400 lg:font-[600] text-center
     lg:text-[50.67px] leading-relaxed  py-10 mb-8`} style={{ width: width }}>
        {content.length ? content.map(text_item => {
            return <p>{text_item}</p>
        }) : <></>}

    </div>
}

const SoftwareService = () => {
    const projectsSectionRef = useRef(null);
    const handleGetStartedClick = (e) => {
        if (projectsSectionRef.current) {
            window.scrollTo({
                top: projectsSectionRef.current.getBoundingClientRect().top +
                    window.scrollY - (document.getElementById('bread-crumbs').getBoundingClientRect().height +
                        document.getElementById('main-header').getBoundingClientRect().height),
                behavior: "smooth"
            });

        }
    };
    return (
        <div>
            <section className={` relative w-full  `} >
                <img src={cover} className='h-screen lg:h-[calc(100vh-7rem)] w-full object-cover' />
                <button onClick={handleGetStartedClick} className='font-poppins absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-3xl lg:text-5xl text-white font-[500]
                 px-4 lg:px-24 py-2 lg:py-7 bg-main rounded-r-full rounded-l-full'>Get Started </button>
            </section>

            <section ref={projectsSectionRef} className='relative h-[96vh] w-full z-[-1]'
                style={{ backgroundImage: `url(${cover})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>
                <div className='absolute top-0 left-0 right-0 bottom-0 bg-[#000] bg-opacity-[68%] z-[-1]'></div>
                <div className=' border-b-main border-b-[1px] bg-transparent  z-10 mx-[8%]  pt-8   '>
                    <p className='font-inter truncate  text-white  text-xl font-[300] py-1 px-10'>{/* Lorem ipsum dolor sit amet, consectetur . Lorem ipsum dolor sit amet, consectetur. Lorem ipsum dolor
                        Lorem ipsum dolor Lorem ipsum dolor Lorem ipsum dolor L */}</p>
                </div>
                <div className='text-[#ffffff96] text-[40px] sm:text-[54px]  lg:text-[74px] font-poppins text-center leading-tight font-bold py-10'>

                    <p>Building</p>
                    <p>tomorrow's</p>
                    <p><span className='text-white  text-[44px] sm:text-[64px] lg:text-[90px] '>Cities</span> through</p>
                </div>
                <SelfWritingText content={['Technology', 'Innovation', 'Creative-Mind']} />
            </section>

            <section className='w-full bg-white   text-gray-500 text-[4.5rem] lg:text-[9rem] xl:text-[14rem] font-bold font-montserrat px-8'>
                <p className='h-full my-5 lg:my-0'>Our Projects</p>
            </section>

            <section className='lg:flex relative w-full'>
                <div className='lg:sticky basis-[50%] top-0 left-0 w-full h-screen bg-main text-[10rem] font-bold font-mono flex flex-col justify-center items-center'>
                    <div className=' h-full text-white 
                 font-poppins px-12 flex flex-col items-center lg:items-start justify-center'>
                        <h2 className='lg:text-[17rem]  leading-[150px] font-[500] lg:leading-[250px]'>PLIS</h2>
                        <p className=' text-center text-3xl lg:text-5xl -pl-20 font-[500]'>Planning Information System</p>

                        <div className=' text-3xl hidden lg:block
                     text-start font-semibold pt-40 space-y-4 '>
                            <h2>Interactive tool</h2>
                            <h2>Uses climate risk information <br /> during project appraisal</h2>
                            <h2>Functionalities to make <br />project management easier</h2>
                        </div>


                    </div>
                </div>
                <div className='relative w-full lg:w-1/2 h-full  flex flex-col  items-end font-inter text-[140px]  basis-[50%] '>
                    <div className='hide-small-visible-flex absolute -right-36 top-20 w-[90vw] h-screen    flex-row-reverse'>
                        <img className=' absolute  bg-transparent w-full ' src={plis_top} />
                    </div>

                    <div className='hide-small-visible-block h-screen bg-transparent w-full mb-14'></div>
                    <div className=' text-center font-[600] text-[#434343] w-full'>
                        <h2 className='text-[72px] xl:text-[112px] hide-small-visible-block leading-10'>Interactive</h2>
                        <h2 className='hide-small-visible-block text-[72px] xl:text-[112px]'>tool</h2>
                        <img className='lg:hidden w-full  h-full' src={plis_top_mobile} />
                        <img className='!w-[250%] object-cover' src={dashboard} />


                        {/* <p className='hide-small-visible-block text-[64px] xl:text-[84px] leading-[1.1] px-32 pb-8'>Uses climate risk information during project appraisal</p> */}
                        <img className='!w-full ' src={dash_front} />
                        {/* <p className='hide-small-visible-block  text-start xl:text-center text-[54px] xl:text-[84px] leading-[1.1]  px-20 xl:px-32 pb-8'>Functionalities to make project management easier</p> */}
                    </div>
                    <div className='visible-small-hide-block text-4xl px-[8%] mx-auto
                     text-center font-semibold pt-40 space-y-10 text-[#525252]'>
                        <h2>Interactive tool</h2>
                        <h2>Uses climate risk information <br /> during project appraisal</h2>
                        <h2>Functionalities to make <br />project management easier</h2>
                    </div>
                </div>

            </section >
            <div className='hide-small-visible-block'>
                <Separator height="45vh" />
            </div>


            <section className='py-20 lg:py-0 lg:flex relative w-full flex-row-reverse'>
                <div className='lg:sticky top-0 left-0 w-full h-[65vh] xl:h-screen bg-[#2f4b7c] text-[10rem] font-bold font-mono
                 lg:flex flex-col justify-center items-center'>
                    <div className=' h-full text-white  font-poppins font-[600]
                justify-center  px-12 flex flex-col items-center '>
                        <h2 className='text-[60px]  xl:text-[82px] 2xl:text-[126px]  font-[700] font-poppins '>National</h2>
                        <h2 className='text-[60px]  xl:text-[82px] 2xl:text-[126px]  font-[700] font-poppins '>Sanitation</h2>
                        <h2 className='text-[60px]  xl:text-[82px] 2xl:text-[126px]  font-[700] font-poppins '>Dashboard</h2>
                    </div>
                </div>
 
 
                <div className='hide-small-visible-block w-full  text-center  text-[#434343] basis-[100%]'>
                    <div className=' h-[65vh] xl:h-[50vh] w-full'></div>
                    <img className='ml-auto w-[80%] ' src={sanboard_1} />
                </div>
            </section>
 
            <section className='lg:flex relative w-full '>
                <div className='lg:sticky top-0 left-0 w-full lg:h-screen lg:bg-[#2f4b7c] text-[10rem] font-bold 
                font-mono lg:flex flex-col justify-center items-center'>
                    <img className='visible-small-hide-block pt-20 px-4 sm:px-10' src={nsdtop_mobile} />
                    <div className=' h-full text-[#2f4b7c] lg:text-white text-[32px] lg:text-[60px]  font-inter font-[600]
                justify-center  px-[15%] text-center flex flex-col items-center lg:leading-[72px] '>
                        <p>Effective management of solid waste management and fecal sludge management</p>
                        <img className='visible-small-hide-block pt-20 px-4 sm:px-10' src={nsdbottom_mobile} />
                    </div>
                </div>

                <div className='hide-small-visible-block w-full  text-center  text-[#434343]'>
                    <div className='h-[50vh] w-full'></div>
                    <img className=' w-[80%] ' src={sanboard_2} />
                </div>
            </section>
            <Separator height="45vh" />

            <section className='lg:flex relative w-full '>
                <div className='lg:sticky top-0 left-0 w-full h-screen bg-[#33cccc] text-[10rem] font-bold font-mono lg:flex flex-col justify-center items-center'>
                    <div className=' h-full text-white  font-inter 
                justify-center  text-center flex flex-col items-end  '>
                        <h2 className='self-center lg:self-end text-[56.5px] lg:text-[66.5px] xl:text-[96.5px] font-[600] font-poppins leading-3 py-6 '>AI enabled</h2>
                        <h2 className='self-center lg:self-end text-[65.45px] lg:text-[78.5px] xl:text-[102.45px] 2xl:text-[125.45px] font-[600] font-poppins  '>SMART CITY</h2>
                        <h2 className='self-center lg:text-end lg:self-end text-[36.5px] lg:text-[56.607px] xl:text-[76.607px] font-[500] font-poppins leading-snug'>management system</h2>
                    </div>
                </div>
                <div className='w-full  text-center  text-[#434343] h-full'>
                    <div className='hide-small-visible-block h-[50vh] w-full'></div>
                    <img className='w-[80%] mx-auto ' src={building} />

                    <div className='hide-small-visible-block'>
                        <Separator height='25vh' width='100%' />
                    </div>
                    <img className='w-[80%] mx-auto' src={city_img} />
                    <div className='hide-small-visible-block'>
                        <Separator height='25vh' width='100%' />
                    </div>

                    <div className='text-3xl  lg:text-4xl xl:text-5xl text-[#33cccc] font-bold text-left 
                         flex flex-col justify-between h-full bg-white py-28'>
                        <div className='flex gap-4 items-center px-8'>
                            <img src={checklist1} className='h-1/5 w-1/5 lg:h-1/4 object-fit ' />
                            <p className='py-12 '>Urban Management</p>
                        </div>

                        <div className='flex gap-4 items-center px-8'>
                            <img src={checklist2} className='h-1/5 w-1/5 lg:h-1/4 object-fit ' />
                            <p className='py-12'>Smart City Technologies</p>
                        </div>

                        <div className='flex gap-4 items-center px-8'>
                            <img src={checklist3} className='h-1/5 w-1/5 lg:h-1/4 object-fit ' />
                            <p className='py-12'>Quality of Life Improvement</p>
                        </div>
                    </div>



                </div>
            </section >

            <section className='lg:flex relative w-full flex-row-reverse'>
                <div className='lg:sticky top-0 left-0 w-full h-screen bg-[#3c4a67] text-[10rem] font-bold font-mono lg:flex flex-col justify-center items-center'>
                    <div className=' px-8 h-full text-white  font-poppins font-[600] flex flex-col  justify-center 
                    items-center lg:items-start space-y-2 mt-20'>
                        <p className='md:hidden lg:block text-[40px] '>- - - - - - </p>
                        <h2 className='text-[30px] lg:text-[60px]  font-[500] font-poppins '>AI enabled Transportation</h2>
                        <h2 className='text-[30px] lg:text-[60px]  font-[500] font-poppins '>Planning and Traffic</h2>
                        <h2 className='text-[30px] lg:text-[60px]   font-[500] font-poppins '>Management</h2>
                        <p className='md:hidden lg:block text-[40px] '>- - - - - - </p>

                        <p className=' hide-small-visible-block text-2xl'>Artificial Intelligence is successfully implememented in Transportation and Traffic
                            management planning for numerous projects completed by Tiller funded by international donors. </p>
                    </div>
                </div>

                <div className='w-full  text-center text-white  '>
                    <div className='hide-small-visible-block h-screen w-full'></div>
                    <div className='relative w-full '>
                        <img className='w-full' src={transport_top} />
                        <h2 className='absolute top-[8%] lg:top-[20%] px-6 py-2 lg:p-12 right-0 
                      text-[calc(4.5vw)] lg:text-[calc(2.5vw)]   --text-2xl--lg:text-7xl-- bg-[#3c4a67]'>Timed Intersection</h2>
                    </div>

                    <img className='hide-small-visible-block mx-auto w-[80%] py-20' src={traffic_light} />
                    <p className='font-inter visible-small-hide-block text-3xl text-[#3c4a67] py-20 px-8 leading-loose font-[500]'>Artificial Intelligence is successfully implememented in Transportation and Traffic
                        management planning for numerous projects completed by Tiller funded by international donors. </p>
                    <div className='relative text-white'>
                        <img className='w-full' src={transport_bottom} />
                        <h2 className='absolute top-[8%] lg:top-[20%] px-6 py-2 lg:p-12 right-0 
                         text-[calc(4.5vw)] lg:text-[calc(2.5vw)]   --text-2xl--lg:text-7xl-- bg-[#3c4a67]'>Adaptive Intersection</h2>
                        <h2 className='absolute top-[20%] lg:top-[40%] px-6 py-2 lg:p-12 right-0 
                         text-[calc(4.5vw)] lg:text-[calc(2.5vw)]   --text-2xl--lg:text-7xl-- bg-[#3c4a67]'>Smart Traffic Management</h2>
                    </div>


                </div>
            </section>
            <Separator height="45vh" />

            <section className=' relative w-full  lg:px-[7%]'>
                <div className='lg:flex w-full overflow-hidden items-center'>
                    <div className='w-full h-[60vh] lg:h-screen bg-[#000] text-[10rem] font-bold font-mono flex flex-col justify-center items-center'>
                        <div className=' h-full text-white  font-inter 
                justify-center  text-center flex flex-col lg:items-end  '>
                            <h2 className='text-[96.5px] font-[600] font-poppins leading-3 py-6 '>Electrical</h2>
                            <h2 className='text-[96.5px] font-[600] font-poppins '>GIS</h2>
                        </div>
                    </div>
                    <div className='w-full'>
                        <img className=' w-[40%] mx-auto ' src={electric_tower_gif} />
                    </div>
                </div>
                <div className='hide-small-visible-block'>
                    <Separator height='4vh' width='100%' />
                    <img className='    w-full' src={e_gis} />
                </div>


                <Separator height='45vh'
                    content={['Aggregated visualisations of Zone', 'Sector based statisctics', 'Track down any single subscriber’s problem']} />
                <img className=' visible-small-hide-block   w-full' src={e_gis} />
                <div className='visible-small-hide-block'>
                    <Separator height='5vh' />
                </div>
            </section >

            <section className=' relative w-full  lg:px-[7%]'>
                <div className='lg:flex w-full overflow-hidden items-center flex-row-reverse'>
                    <div className='w-full h-screen bg-[#aa2b3b] text-[10rem] font-bold font-mono flex flex-col justify-center items-center'>
                        <div className=' h-full text-white  font-inter 
                justify-center  text-center flex flex-col items-end  '>
                            <h2 className='text-[96.5px] font-[600] font-poppins leading-3 py-6 '>Remote</h2>
                            <h2 className='text-[96.5px] font-[600] font-poppins  '>Sensing</h2>
                        </div>
                    </div>
                    <div className='w-full'>
                        <img className=' w-[40%] mx-auto ' src={satalite_gif} />
                    </div>
                </div>
                <Separator height='5vh' width='100%' />
                <div className='lg:flex w-full gap-6 lg:h-[90vh] items-center'>
                    <div className='w-full lg:h-[70vh] mx-auto'>
                        <img className='h-full object-scale-down px-10  mx-auto lg:mx-0' src={remote_sensing} />
                        <div className='lg:hidden mx-auto w-full font-poppins font-[400]  px-10  py-8 text-[18px] md:text-[24px] lg:text-[30px] space-y-4'>
                            <p >Tiller employs remote sensing for urban heat measurement</p>
                            <p >Assesses residents' exposure to hazards</p>
                            <p >Creates a spatial information database</p>
                            <p >Aids policymakers and stakeholders in risk reduction strategies</p>
                        </div>
                    </div>
                    <div className='hidden lg:block mx-auto w-full font-poppins font-[400]  px-10  py-8 text-[18px] md:text-[24px] lg:text-[30px] space-y-4'>
                        <p >Tiller employs remote sensing for urban heat measurement</p>
                        <p >Assesses residents' exposure to hazards</p>
                        <p >Creates a spatial information database</p>
                        <p >Aids policymakers and stakeholders in risk reduction strategies</p>
                    </div>


                </div>

            </section >

            <section className=' relative w-full  lg:px-[7%]'>
                <div className='lg:flex w-full overflow-hidden items-center '>
                    <div className='w-full lg:w-[50%]  xl:w-full h-screen bg-[#94a6d6] text-[10rem] font-bold font-mono flex flex-col justify-center items-center'>
                        <div className=' h-full text-white  font-inter 
                justify-center  text-center flex flex-col items-start  px-10 '>
                            <h2 className='text-[56px] sm:text-[46px] md:text-[66px] lg:text-[56px] font-[600] font-poppins leading-3 py-6 '>Smart </h2>
                            <h2 className='text-[56px] sm:text-[46px] md:text-[66px] lg:text-[56px] font-[600] font-poppins '>Holding Tax</h2>
                            <h2 className='text-[56px] sm:text-[46px] md:text-[66px] lg:text-[56px] font-[600] font-poppins leading-3 py-6'>Management</h2>
                            <p className='hide-small-visible-block text-[24px] font-[400] font-poppins  text-left mt-10'>Centralized property database for tax assessments
                                Automated calculation and online payment features
                                Interactive map showing property tax statuses
                            </p>
                        </div>
                    </div>
                    <div className='w-full'>
                        <img className='w-[60%] md:w-[40%] mx-auto ' src={inheritance_gif} />
                    </div>
                </div>
                <Separator height='4vh' width='100%' />
                <div className=' px-8 md:px-0'>
                    <img className='!border-[1px] border-black  border-solid' src={holding_tax} />
                    <p className=' visible-small-hide-block text-[24px] font-[400] font-poppins  text-center lg:text-left mt-10 space-y-4'>
                        <p>Centralized property database for tax assessments</p>
                        <p>Automated calculation and online payment features</p>
                        <p>Interactive map showing property tax statuses</p>
                    </p>
                </div>



                <Separator height='4vh' />
            </section >


        </div >
    )
}

export default SoftwareService