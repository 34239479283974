import MobileSectionTextDetails from "./MobileSectionTextDetails"

const SingleContentLayout = ({ content }) => {

console.log(content);

    return <div style={{ scrollSnapAlign: 'start' }}
      className={`  relative h-[85vh] md:h-[86vh] lg:h-[79.6vh] w-full overflow-hidden text-white  ${content?.type == 'rtl' ? "flex-row-reverse " : ""}`} >
  
  
      {content?.single_image ? <div className='w-full h-full flex items-start justify-end pt-16 px-8 md:px-20' style={{
        backgroundImage: `url(${content?.single_image})`,
        backgroundRepeat: "no-repeat", backgroundSize: "cover"
      }}>
        <div className='hidden md:block  w-[27%]  bg-[#000] bg-opacity-50'>
          <h2 className={`text-3xl  w-2/3 ${content?.type == 'rtl' ? "" : " ml-auto "} font-anton`}>
            <span style={{ color: content?.color || "#84BE3F", }}>{content?.title[0]}</span>{content?.title.slice(1)}
          </h2>
          <div className={`border-b-2 border-b-main w-2/3  py-2 ${content?.type == 'rtl' ? "" : " ml-auto "}`}> </div>
          <ul className={` text-sm w-[75%]  py-2 ${content?.type == 'rtl' ? "" : " ml-auto "}`}>
            {content?.subElements?.map(li => <li>{content?.subElements.length - 1 ? '-' : ''} {li.title}</li>)}
          </ul>
        </div>
        < MobileSectionTextDetails content={content} />
      </div>
        : <></>}
      {/* Content Video */}
      {content?.bodyVideo ? <video className='w-full  object-cover' autoPlay loop muted>
        <source src={content?.bodyVideo} type="video/webm" />
  
  
      </video> : <></>}
  
    </div>
  }

  export default SingleContentLayout