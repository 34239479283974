import MaskedImage from './MaskedImage'

const MobileSectionTextDetails = ({ content }) => {

    return (<div className='md:hidden flex flex-col justify-end h-full pb-20 px-4 relative z-10'>
        <MaskedImage />

        <h2 className={`text-3xl ${content?.type == 'rtl' ? "text-left" : "text-right ml-auto "} 
        font-anton  font-[400] text-[47.28px] leading-[48.75px]`}>
            <span style={{ color: content?.color || "#84BE3F", }}>{content?.title && content?.title[0]}</span>{content?.title && content?.title.slice(1)}
        </h2>
        <div className={`border-b-2 border-b-main w-[58%]  py-2 ${content?.type == 'rtl' ? "text-left" : "text-right ml-auto "}`}> </div>

        <ul className={` font-poppins font-[100] tracking-wider text-[14px] w-full  py-2 ${content?.type == 'rtl' ? "text-left" : "text-right ml-auto "}`}>
            {content?.subElements?.map((li, index) => <>
                {index ? <div className={`${content?.type == 'rtl' ? '' : 'ml-auto'} my-1 w-[80%]  h-[2px] bg-slate-300 bg-opacity-20`}></div> : <></>}
                <li>{/* {content?.subElements.length - 1 ? '-' : ''} */} {li.title}</li>

            </>)}
        </ul>
    </div>)
}
export default MobileSectionTextDetails