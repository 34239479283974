import React from 'react'
import '../Assets/Styles/pageNotFound.css'
import { useNavigate } from 'react-router'
import Loading from '../Components/Loading'
const PageNotFound_new = () => {

  const navigate = useNavigate()
  const handleBack = e => {

    navigate(-1)
  }
  const handlehome = (e) => {
    navigate('/')
  }
  return (
    <div className='relative h-[calc(100vh-3rem)]'>
      <div className="message-box">

        <h1>404</h1>
        <p>Page not found</p>
        <div className="buttons-con">

          <div className="action-link-wrap">

            <a onClick={handleBack}
              className="link-button link-back-button"
            >
              Go Back
            </a>
            <a onClick={handlehome} href="/" className="link-button">
              Go to Home Page
            </a>
          </div>
        </div>
      </div>
      <Loading style={'404'} />
    </div>

  )
}

export default PageNotFound_new