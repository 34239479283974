import { useEffect, useState } from "react";

const ComparatorSectionLayout = ({ content }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [barPosition, setBarPosition] = useState(isMobile ? 200 : 650);
  const [isDragging, setDragging] = useState(false);
  const [currentDirection, setCurrentDirection] = useState();

  const handleStart = (e) => {
    setDragging(true);
  };

  const handleEnd = () => {
    setDragging(false);
  };

  const handleMove = (event) => {
    if (isDragging) {
      let newX;
      if (event.type === 'mousemove') {
        newX = event.clientX;
      } else if (event.type === 'touchmove') {
        newX = event.touches[0].clientX;
      }
      setBarPosition(newX);
      setCurrentDirection(barPosition > newX ? 'left' : 'right');
    }
  };

  return (
    <div
      onMouseMove={handleMove}
      onTouchMove={handleMove}
      onMouseUp={handleEnd}
      onTouchEnd={handleEnd}
      className="relative text-white select-none h-[85vh] md:h-[86vh] lg:h-[79.6vh] w-full overflow-hidden"
      style={{ scrollSnapAlign: 'start' }}
    >
      <img
        className="h-full w-full object-cover"
        style={{ clipPath: `polygon(0 0, ${barPosition}px 0, ${barPosition}px 100%, 0 100%)` }}
        src={content.titleImage}
        alt="First Image"
      />
      <div className="absolute left-0 top-0 h-full w-full overflow-hidden">
        <img
          className="h-full w-full object-cover"
          style={{ clipPath: `polygon(${barPosition}px 0, 100% 0, 100% 100%, ${barPosition}px 100%)` }}
          src={content.bodyImage}
          alt="Second Image"
        />
      </div>

      <div
        className={`absolute top-0 h-full bg-white w-[5px] shadow-md -translate-x-[${barPosition}px]`}
        style={{ left: `${barPosition}px`, '--tw-translate-x': `-${barPosition}px` }}
      ></div>
      <div
        onMouseDown={handleStart}
        onTouchStart={handleStart}
        className={`absolute top-1/2 -translate-y-1/2 ${currentDirection === 'left' ? 'border-l-main' : 'border-r-main'}
         border-2 text-black flex items-center z-30 
        justify-between px-2 w-12 h-12 bg-white rounded-full cursor-pointer`}
        style={{ left: `calc(${barPosition}px - 20px)` }}
      >
        <i className={`fa-solid fa-chevron-left ${currentDirection === 'left' ? 'text-main' : ''}`}></i>
        <i className={`fa-solid fa-chevron-right ${currentDirection === 'right' ? 'text-main' : ''}`}></i>
      </div>

      <div className="absolute  left-0 bottom-0 w-[80%] md:w-[27%] h-fit bg-[#3b3b3b70] flex flex-col md:justify-end pb-20 px-4 z-10 border-main">
        <h2 className="text-3xl w-2/3 text-end ml-auto font-anton">
          <span className="text-main">{content.title[0]}</span>{content.title.slice(1)}
        </h2>
        <div className="border-b-2 border-b-main w-2/3 ml-auto py-2"></div>
        <ul className="text-end text-sm w-[75%] ml-auto py-2">
          {content?.subElements.map((li, index) => (
            <li key={index}>{content?.subElements.length - 1 ? '-' : ''} {li.title}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default ComparatorSectionLayout;
