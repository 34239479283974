import React, { useEffect, useRef, useState } from 'react'

import Slider from '../Components/Slider'
import AboutUs from '../Components/AboutUs'
import NewsSlider from '../Components/News/index'

import Sectors from '../Components/Sectors'
import Services from '../Components/Services'
import Products from '../Components/Products'
import Team from '../Components/Team'
import Carreer from '../Components/Carreer'
import Contact from '../Components/Contact'
import BackToTop from '../Components/BackToTop'
import { useLocation } from 'react-router'
import ConfettiEffect from '../Components/ConfettiEffect'



const Home = ({ }) => {



  const url_hash = useLocation().hash
  const scrollToTarget = (targetClassName) => {
   
    const targetElement = document.querySelector(`.${targetClassName}`);

    if (targetElement) {
      targetElement.scrollTo({
        top: 0,
        behavior: 'smooth', // Add smooth scrolling effect
      });
    }
    /*       const targetDiv = e.target.innerHTML.split(" ")[e.target.innerHTML.split(" ").length - 1].toLowerCase();
          navigate("/#" + targetDiv) */
  };
  useEffect(() => {
    document.getElementById('head-title').text = 'Home | Tiller'
    url_hash && scrollToTarget(url_hash.split('#')[1])
  }, [url_hash])
  return (
    <div className='relative w-full mb-20 md:mb-0'>
      {/*  <DisplaySticker/> */}
      <Slider />
      <div className='gap-container'>
        <AboutUs />
        {/* <TestComponent /> */}

        <NewsSlider />
        <Sectors />
        <Services />
        {/* <Products /> */}
        <Team />
        {/*  <Carreer /> */}
        <Contact />
      </div>

      {/* <NewsLetter /> */}

      <BackToTop />
      <ConfettiEffect />
    </div>
  )
}

export default Home