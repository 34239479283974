import React from "react";
import banner from "../../Assets/Images/Projects/Boat-Bckground.jpg";


import focus_Group_discussion from "../../Assets/Images/Projects/Focus-Group-Discussion.jpeg";
import unloading_fising_from_boat from "../../Assets/Images/Projects/Unloding-Fish-from-Boat.jpg";
import fishing_Boat from "../../Assets/Images/Projects/Fishing-Boat.jfif";
import fish_boat_svg from "../../Assets/Images/Projects/boat.svg";

const SoNG = () => {
    const projectInfo = {
        id: 1,
        title:
            "A baseline study on the socioeconomic status of coastal fishing communities, and their local fishing practices and techniques, as well as Knowledge of the Sundarbans and SoNG Marine Protected Area",
        location: "Barguna, Pirojpur, Bagerhat and Patuakhali",
        overview:
            "A baseline study to understand the significance of artisanal fishing communities and their roles in protecting the biodiversity and ecosystem",
        feature:
            "Therefore, the conservation and sustainable use of the Sundarbans and the Swatch of No Ground (SoNG) Marine Protected Area (MPA) is of paramount importance to Bangladesh.",
        client:
            "The Deutsche Gesellschaft für International Zusammenarbeit GmbH (GIZ)",
        year: "18 June, 2023 - 10 October, 2023",
        funded_by: "GIZ",
        rendered_service: "Survey/Baseline Study",
        description_up: ` The ecological significance of the Sundarbans mangrove forest and
   its adjacent area are acquainted with its rich marine megafauna
   biodiversity. The close proximity of the Sundarbans to the Swatch
   of No Ground (SoNG), along with their shared hydrological and
   biodiversity characteristics, suggests a close interaction and
   interdependency between the two ecosystems, the forest and blue
   ecosystem. Therefore, the conservation and sustainable use of the
   Sundarbans and the Swatch of No Ground (SoNG) Marine Protected
   Area (MPA) is of paramount importance to Bangladesh.`,
        description_down: `  The study reviews marine and coastal fisheries in Bangladesh,
   conducting a reconnaissance survey, baseline questionnaire
   preparation, and digital data collation apps. It also assesses the
   socioeconomic conditions of coastal communities involved in marine
   and coastal fisheries, focusing on artisanal fishermen by
   considering their employment pattern, family number, income,
   expense, education, water and sanitation hygiene, living
   conditions, and overall livelihood.`,
        quote_1: `Five landing sites (Alipur, Mohipur, Patharghata, Parerhat, and KB
    ghat), conducting Focus Group Discussions and Key Informant
    Interviews.`,
        quote_2: `Identifying the specific roles and responsibilities of marine
   fishermen and their household members (e.g., women and youth) within
   the fisheries value chain and marine conservation efforts.`,
        quote_3: `Investigating how fishermen and their households sustain their
   livelihoods during the ban period (from 20 May to 23 July) and
   determining their expectations for survival during this time.`,
        fisheries_research_methodology_description: ` The comprehensive study on marine and coastal fisheries in
   Bangladesh encompasses various key components. Initially, a
   literature review was conducted to establish a foundational
   understanding of the subject. Subsequently, a reconnaissance
   survey was undertaken, leading to the meticulous preparation of a
   baseline questionnaire. Simultaneously, the design of digital data
   collation apps aimed to streamline information gathering. The
   study involved the strategic sampling of fishermen households from
   five fish landing sites, namely Alipur, Mohipur, Patharghata,
   Parerhat, and KB ghat. Comprehensive baseline surveys were
   conducted at these landing sites, incorporating Focus Group
   Discussions and Key Informant Interviews. Rigorous live monitoring
   of survey activities ensured data quality control and assurance
   throughout the process. The primary focus was on assessing the
   current socioeconomic conditions of coastal communities engaged in
   marine and coastal fisheries, with a specific emphasis on
   artisanal fishermen. Parameters such as employment patterns,
   family demographics, income, expenses, education, water and
   sanitation hygiene, living conditions, and overall livelihood were
   scrutinized. The study also delved into an examination of fishing
   practices, encompassing artisanal techniques and a comprehensive
   analysis of both pre-and post-harvest processing methods.`,
        fisheries_ealuation_Description: `    A critical evaluation was carried out to determine the extent of
   women and youth involvement in these fishing practices. Moreover,
   the research identified the specific roles and responsibilities of
   marine fishermen and their household members within the fisheries
   value chain and marine conservation efforts. A dedicated
   investigation into how fishermen and their households sustain their
   livelihoods during the ban period (from May 20 to July 23) provided
   valuable insights, along with an exploration of their expectations
   for survival during this time. Furthermore, the study assessed the
   existing level of knowledge and practices related to the protection
   and conservation of biodiversity in the area between the SoNG MPA
   and Sundarbans, with a particular emphasis on women and youth.
   Lastly, a needs assessment was conducted, focusing on skill
   development and livelihood options for fishermen and women in the
   community.`,
        professional_staff: [
            { name: `Dr. Md. Zakir Hossain`, designation: `Team Leader` },
            {
                name: `Md Zafar Alam Bhuiyan`,
                designation: `Short- Term Expert Assistant
    (Field and Data Coordinator)`,
            },
            {
                name: `Tamanna Salam`,
                designation: `Short- Term Expert Assistant
    (Field and Data Coordinator)`,
            },
            {
                name: `Al-Ferdous Ahmed`,
                designation: `Short- Term Expert Assistant
    (Field and Data Coordinator)`,
            },
            {
                name: `Mehedi Mudasser`,
                designation: `Short- Term Expert Assistant (Field
      and Data Coordinator)`,
            },
            {
                name: `Md. Mohaiminul Islam Rifat`,
                designation: `Short- Term Expert
    Assistant (Field and Data Coordinator)
  </p>`,
            },
        ],
    };
    const {
        location,
        client,
        rendered_service,
        year,
        funded_by,
        feature,
        overview,
        description_up,
        fisheries_ealuation_Description,
        fisheries_research_methodology_description,
        quote_1,
        quote_2,
        quote_3,
        description_down,
        professional_staff,
    } = projectInfo;
    const GridItem = ({ title, content }) => (
        <div className="lg:w-44 ">
            <b>{title}</b> <br />
            <small>{content}</small>
        </div>
    );
    const gridItems = [
        { title: "Location and Area", content: location },
        { title: "Funded By", content: funded_by },
        { title: "Client", content: client },
        { title: "Rendered Service", content: rendered_service },
        { title: "Year", content: year },
    ];

    const Section = ({ title, content }) => (
        <div className="my-5">
            <p className="font-bold text-lg md:text-2xl mb-4">{title}</p>
            <p className="text-base md:text-xl">{content}</p>
        </div>
    );

    return (
        <div className="px-[8%]">
            {/*  banner */}
            <div className=" w-full h-[35rem]">
                <img
                    src={banner}
                    alt="banner"
                    className="w-full h-full object-cover object-center"
                />
            </div>
            {/*  summary */}

            <section className="  mt-[-0.50in] lg:w-[22cm] px-[6%] relative bg-white mx-auto z-10 border border-[#84BE3F] border-t-0 shadow-2xl sm:w-[15cm]  md:w-[15cm]">
                <div className="flex justify-between py-5">
                    <small className="text-gray-500 font-Helvetica tracking-widest">
                        Project
                    </small>

                    {/* <div className="flex gap-4">
                        <small className="text-gray-500 font-Helvetica tracking-widest">
                            Share
                        </small>
                        <i class="fa-brands fa-x-twitter"></i>
                        <i class="fa-brands fa-linkedin"></i>
                        <i class="fa-brands fa-facebook"></i>
                    </div> */}
                </div>

                <strong
                    className=" 
        leading-relaxed text-[#333333] tracking-tighter   font-sans text-xl md:text-3xl md:tracking-wide"
                >
                    {projectInfo.title}
                </strong>

                <div>
                    
                        <Section title="Overview" content={overview} />
                        <Section title="Features" content={feature} />
                    

                    <div className="grid grid-cols-2 gap-5">
                        {gridItems.map((item, index) => (
                            <GridItem key={index} title={item.title} content={item.content} />
                        ))}
                    </div>
                </div>
            </section>

            <section className="mt-20">
                <p className="font-bold text-lg mb-5 text-gray-600">Project Details</p>

                <div>
                    <div>
                        <p>{description_up}</p>
                        <div className=" h-[14cm]  md:h-[20cm] w-full my-5">
                            <img
                                src={focus_Group_discussion}
                                className="h-full w-full object-cover"
                            />
                        </div>
                        <p>{description_down}</p>
                    </div>

                    <div className="  flex   flex-col md:flex-row   items-center justify-center gap-20 my-20">
                        <div className="h-36 w-36">
                            <img src={fish_boat_svg} className="h-full w-full object-cover" />
                        </div>
                        <p className="font-bold text-lg text-lime-400 w-80  tracking-wider leading-relaxed text-justify ">
                            {quote_1}
                        </p>
                    </div>

                    <div className="grid lg:grid-cols-2  md:grid-cols-1 sm:grid-cols-1 gap-5">
                        <p className="text-justify lg:w-[90%] xl:w-[80%]  2xl:w-[80%] ">
                            {fisheries_research_methodology_description}
                        </p>
                        <div className=" h-[14cm]   2xl:h-[10cm]  xl:h-[15cm] lg:w-full md:h-[20cm] sm:h-[10.16cm] lg:h-[16cm]">
                            <img
                                src={unloading_fising_from_boat}
                                className="h-full w-full object-cover"
                            />
                        </div>
                    </div>

                    <p className="font-bold text-xl text-lime-400   tracking-wider leading-relaxed text-justify my-20">
                        {quote_2}
                    </p>
                </div>

                <div>
                    <div className=" h-[14cm]  md:h-[20cm]  w-full">
                        <img src={fishing_Boat} className="h-full w-full object-cover" />
                    </div>
                    <p className="my-5">{fisheries_ealuation_Description}</p>
                </div>
            </section>

            <p className="font-bold text-xl text-lime-400   tracking-wider leading-relaxed text-justify my-20">
                {quote_3}
            </p>

            <div>
                <strong className="text-lg ">Name of senior professional staff</strong>
                <div className="flex flex-col gap-2 mt-4">
                    {professional_staff?.map(({ name, designation }) => (
                        <p>
                            <strong>{name}</strong>,{designation}
                        </p>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default SoNG;
