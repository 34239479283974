import top_cover from "../../Assets/Images/Projects/Top-Cover.jpeg";
import Digital_Marketing_Training_at_Rajshahi from "../../Assets/Images/Projects/Digital-Marketing-Training-at-Rajshahi.jpeg";
import Career_Counselling_and_motivation_session_at_Rajshahi from "../../Assets/Images/Projects/Career-Counselling-and-motivation-session-at-Rajshahi.jpeg";
import Career_Counselling_and_motivation_session_at_Sirajgonj from "../../Assets/Images/Projects/Career-Counselling-and-motivation-session-at-Sirajgonj.jpeg";
import chart from "../../Assets/Icons/youtube.svg";
import youtube from "../../Assets/Icons/youtube.svg";


export const photoObjectFormatter = (photo, caption = null, source = null) => {
  return {
    photo: photo,
    caption: caption,
    source: source
  };
};


const ProjectBasics = {
  "Location and Area": 'Khulna, Satkhira, Barisal, Rajshahi & Sirajganj Municipality',
  "Funded By": 'GIZ Humburg',
  "Client": 'The Deutsche Gesellschaft für International Zusammenarbeit GmbH (GIZ)',
  "Rendered Service": 'Capacity Building',
  "Year": '13 June, 2023 - 20 September, 2023',
}

const Project_Information = {
  title: 'Job placement and business mentoring support services for skills training graduates in five partner cities',
  cover_image: photoObjectFormatter(top_cover),
  description_image: photoObjectFormatter(Career_Counselling_and_motivation_session_at_Rajshahi, 'Career Counselling and motivation session at Sirajgonj'),
  description_up: `Migration is one of the main factors driving the global increase in urbanization, and in the process, it 
  is making cities into more diverse places. Climate change poses a significant future risk to Bangladesh’s sustainable development,
   and approximately six million people have already migrated due to this. The increasing number of internal migrants within the 
   country has the potential to disrupt social stability in the long term. Poor and vulnerable households’ development is a common
    strategy in Bangladesh to adapt to changing living conditions, and leading to vulnerability and conflicts when migrants lack
    public support and are compelled to live in underdeveloped urban slums without access to basic services and income opportunities.`,
  description_down: `In this context, job placement and business mentoring support services under the "Urban Management of 
  Internal Migration due to Climate Change'' (UMIMCC)/"Urban Management of Migration and Livelihood" (UMML) project aims to
   improve the living conditions of climate migrants in five partner cities of Bangladesh.  In partnership with the Ministry of Social
    Welfare and five local governments, the project focuses on creating resilient livelihood options, minimizing the impacts of forced
     climate displacement and migration,
   and providing income and employment opportunities for graduates in five partner cities.`,
  quote_1: `250 graduates received
  online marketing training.`,
  service_image: photoObjectFormatter(Digital_Marketing_Training_at_Rajshahi, 'Digital Marketing at Rajshahi'),
  service_description_up: `The multifaceted approach to this project involves an initial review of past interventions and the compilation 
  of a database featuring the training graduates, a valuable resource provided by the client. The selection of indicators for the baseline 
  survey is a pivotal step in crafting the baseline questionnaire, setting the foundation for comprehensive data collection. 
  The project includes the diligent execution of baseline surveys, specifically tracking graduates from Vocational Skills Training 
  and Entrepreneurship Skills Training programs while marking their residences for geographical insights. A proactive initiative 
  involves organizing training sessions on online marketing for 250 interested graduates and providing Tally accounting app training 
  to 370 Entrepreneurship Skills Training graduates across five partner cities. Ongoing monitoring of the status 
  of the 6304 training graduates, with a notable 74% being women, ensures a continuous understanding of project dynamics.`,
  quote_2: `6304 training graduates. 74% of them are women.`,
  quote_3: `370 graduates received
  entrepreneurship skills training.`,
  closure_image: photoObjectFormatter(Career_Counselling_and_motivation_session_at_Sirajgonj, 'Career Counselling and motivation session at Sirajgonj'),
  closure_description: `Job placement support is extended to vocational training graduates through mentoring sessions and job
   fairs in partner cities. Efforts are directed towards identifying potential employers and financial institutions for both
    vocational and entrepreneurship training graduates, facilitating small business endeavors. Local employers and financial institutions
     are engaged to offer job placement services and finance options for trained entrepreneurs. In each city, 
     the establishment of Private Sector Forums involving training graduates and local employers fosters collaboration
      and opportunities. A performance-based framework is developed for meticulous project monitoring, encompassing outcomes,
       indicators, baselines, and targets. The project concludes with a comprehensive endline survey, evaluating the results
        against the initially selected indicators during the baseline survey, providing a robust assessment of project achievements.`,
  professional_staff: [
    { name: `Mohammad Rizwanul Haque Khan`, designation: `PMP,Team Leader` },
  ],
}

const TVET = () => {
  return (
    <div>
      {/* Cover Image */}
      <div className="h-[12cm] w-full sm:h-[15cm]">
        <img src={Project_Information?.cover_image?.photo} className="h-full w-full object-cover" />
      </div>
      <div class="flex items-center justify-center my-5  bg-black opacity-80 sm:!bg-white
         text-white sm:text-black  -mt-[4rem] sm:mt-2 p-5  sm:px-[8%] ">
        <p class="text-xl font-semibold text-justify sm:font-bold  sm:text-3xl  ">
          {Project_Information?.title}
        </p>
      </div>
      <div className="px-[8%]">
        <div className=" relative">
          <div className="">
            <divider className="hidden md:block absolute bg-main w-3 h-full left-1/2 top-0 -translate-x-1/2 "> </divider>
            <divider className=" md:hidden absolute bg-main h-3 w-full left-0 top-1/2 translate-y-1/2 "> </divider>
          </div>
          <div className="grid grid-cols-3 md:grid-cols-2 ">
            {Object.entries(ProjectBasics).map((basic_item, index) => (
              <div className='py-4 md:py-0  md:odd:ml-auto md:odd:mr-4 md:even:ml-4 '
              >
                <div className=" w-28 sm:w-52 my-4 ">
                  <b>{basic_item[0]}</b> <br />
                  <small >{basic_item[1]}</small>
                </div>
              </div>)
            )}
          </div>

        </div>
        <div className="mt-16 leading-loose ">
          <p className="my-2 ">
            {Project_Information.description_up}
          </p>
          <div className="h-[12cm] w-full my-2 sm:h-[15cm]">
            <img
              src={Project_Information?.description_image.photo}
              className="h-full w-full object-cover"
            />
          </div>
          <i>{Project_Information?.description_image.caption}</i>
          <p>
            {Project_Information?.description_down}
          </p>
          <div className="flex flex-col justify-center items-center my-20">
            <div className="h-44 w-44">
              <img src={youtube} className="h-full w-full object-cover" />
            </div>
            <p className="font-bold text-4xl text-lime-400 text-center  sm:tracking-tighter leading-normal lg:tracking-wider lg:w-[35%]  ">
              {Project_Information.quote_1}
            </p>
          </div>
          <p className="font-bold text-xl ">Service Description</p>
          <p className="my-5">
            {Project_Information?.service_description_up}
          </p>
          <div>
            <div className="h-[12cm] w-full sm:h-[15cm]">
              <img
                src={Project_Information.service_image.photo}
                className="h-full w-full object-cover"
              />
            </div>
            <i>{Project_Information?.service_image?.caption}</i>
          </div>
          <p className="my-5  font-bold text-4xl text-justify text-lime-400
           sm:tracking-tighter leading-normal   lg:tracking-wider ">{Project_Information?.quote_2} </p>
          <div>
            <div className="h-[12cm] w-full sm:h-[15cm]">
              <img
                src={Project_Information.closure_image?.photo}
                className="h-full w-full object-cover"
              />
            </div>
            <i>{Project_Information.closure_image?.caption}</i>
          </div>
          <div className="flex flex-col justify-center items-center">
            <div className="h-44 w-44">
              <img src={chart} className="h-full w-full object-cover" />
            </div>
            {/* <p className="font-bold text-4xl text-lime-400  lg:tracking-wider sm:tracking-tighter leading-normal text-justify "> <span className="ml-[5rem]"> 370 graduates received </span> <br />
              <span className="ml-[-1rem] sm:ml-0"> entrepreneurship skills training.</span></p> */}
            <p className="font-bold text-4xl text-lime-400  lg:tracking-wider sm:tracking-tighter leading-normal  w-[42%] text-center"> {Project_Information?.quote_3}</p>
          </div>
          <p className="my-5 ">
            {Project_Information.closure_description}
          </p>
          {/*  <iframe src="http://118.179.197.118:4054" width={'500px'} height={'500px'} sandbox='allow-scripts allow-modal m-0 p-0' ></iframe> */}
          {Project_Information?.professional_staff.length ?
            (<div>
              <strong className="text-lg ">
                Name of senior professional staff
              </strong>
              {Project_Information?.professional_staff?.map(item => (<div className="flex flex-col gap-2 mt-4">
                <p>
                  <strong>Mohammad Rizwanul Haque Khan</strong>,PMP,Team Leader
                </p>
              </div>))}
            </div>) : <></>}
        </div>
      </div>
    </div>
  );
};
export default TVET;
