import React, { useState } from 'react'

const SocialShare = ({handleHover, index, }) => {
    const [shareURl, setShareURL] = useState('tiller.com.bd')
    const shareOnFacebook = () => {
        const url = `https://www.facebook.com/sharer/sharer.php?u=${shareURl}`;
        window.open(url, '_blank');
    };

    const shareOnLinkedIn = () => {
        const url = `https://www.linkedin.com/sharing/share-offsite/?url=${shareURl}`;
        window.open(url, '_blank');
    };

    const shareOnTwitter = () => {
        const url = `https://twitter.com/intent/tweet?url=${shareURl}`;
        window.open(url, '_blank');
    };

    const hover = (action) => {
        if (action) {
          handleHover(index)
        }
        else {
          handleHover(null)
        }
      }
    return (
        <div 
        onMouseEnter={(e) => { hover(true) }}
        onMouseLeave={(e) => { hover(false) }}
        className="absolute right-2 top-2 flex flex-col gap-4 px-2 py-1 bg-opacity-30 hover:bg-opacity-50 animate-slideIn">
           {/*  <small className="text-gray-500 font-Helvetica tracking-widest">
                Share
            </small> */}
            <div onClick={shareOnTwitter}>
                <i class="fa-brands fa-x-twitter hover:scale-105 cursor-pointer border-2 shadow-md  bg-gray-200  border-transparent hover:border-white rounded-full p-1"></i>
            </div>
            <div onClick={shareOnLinkedIn}>
                <i class="fa-brands fa-linkedin hover:scale-105 cursor-pointer border-2 shadow-md  bg-gray-200 border-transparent hover:border-white rounded-full p-1"></i>
            </div>
            <div onClick={shareOnFacebook}>
                <i class="fa-brands fa-facebook hover:scale-105 cursor-pointer border-2 shadow-md bg-gray-200 border-transparent hover:border-white rounded-full p-1"></i>
            </div>

        </div>
    )
}

export default SocialShare