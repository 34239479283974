import React from 'react';
import physical_feature_Rangpur_Sylhet from '../../Assets/Images/Projects/Physical_Feature_Rangpur_Sylhet.jpg'
import Sylhet_Common_Drone_Image from '../../Assets/Images/Projects/Sylhet_Common_Drone_Image.JPG';
import Zilla_Parishad_Rangpur from '../../Assets/Images/Projects/Zilla_Parishad_Rangpur.jpg';
import Ghaghot from '../../Assets/Images/Projects/Ghaghot.jpeg';
import Shaheed_Minar from '../../Assets/Images/Projects/Shaheed_Minar.jpg';
import { photoObjectFormatter } from './TVET';

const RangpurSylhetPhysicalFeatureSurvey = () => {
  const projectInfo = {
    location: 'Rangpur and Sylhet City Corporation',
    title: `Preparation of Risk Sensitive Database for Core Area of Rangpur and Sylhet District Town
    (Physical Feature Survey, Other Survey)`,
    client: "Urban Development Directorate (UDD",
    project_Value: '5.01 Cr / $456,903.70',
    rendered_service: 'Development/Strategic Planning',
    description: ` This project aims to establish a comprehensive and dynamic database that prioritizes risk sensitivity within the core areas of Rangpur and Sylhet districts. This initiative recognizes the increasing vulnerability of urban centers specially the city corporation area to various risks, including natural disasters, climate change, and other potential hazards. The project will employ advanced data collection methods, including geospatial technology, remote sensing, and on-site surveys to ensure the accuracy and reliability of the information gathered. The resulting database will serve as a valuable resource for urban planning, emergency response, and risk mitigation strategies.`,
    list_up: [
      { content: `Conducting the pre- requisite activities of drone fly e.g. UAV flight permission, no- drone zone demarcation, UAV flight path set and flight block prepare, CGP marking and UAV flight planning.` }, { content: `High-resolution images have been prepared by using Drone Technology and conducting the GCP survey for referencing the captured images by drone.` }, {
        content: `Establishment of BM pillar for getting accurate and consistent coordinate systems for project area, Base Map Preparation by using Photogrammetric Method, DEM Preparation for topographic representation and hydrological modelling and App develop in digital platform to monitor the physical feature data collection activities.`
      }
    ],

    list_down: [
      { content: `Rapid Visual Screening (RVS) has been applied to identify the exposure of building during natural disaster by evaluating pounding possibility, ground set, short column, & soft story.` }, {
        content: `Developing and integrating monitoring dashboard to perceive the progress of survey activities;
      Generated few thematic maps which helped to in data visualization, spatial analysis, monitoring and assessment and helped in decision making.`}
    ],
    shahid_minar_image: photoObjectFormatter(Shaheed_Minar, 'Central Shahid Minar,Sylhet'),
    ghatgot_park_image: photoObjectFormatter(Ghaghot, 'Ghaghot Park,Rangpur'),
    zilla_parishad_image: photoObjectFormatter(Zilla_Parishad_Rangpur, 'Zilla Parishad,Rangpur'),
    sylhet_common_drone_image: photoObjectFormatter(Sylhet_Common_Drone_Image, 'Keane Bridge,Sylhet'),
    quote_1: `Primary Data, collection such as Physical Feature, Topographic, Land use, Transportation, Hydrological, and Impact of Climate Change and Disaster Study etc.`,
    quote_2: `Secondary Data Collection (i.e., Risk Sensitive Data, Draining Data, Utility Line, Census Data etc.) map Preparation.`,

    professional_staff: [

      {
        name: `Al-Ferdous Ahmed`,
        designation: `Junior Urban Planner`,
      },
      {
        name: `Mehedi Mudasser`,
        designation: `Junior Urban Planner`,
      },
      {
        name: `Tanvir Ahmed`,
        designation: `Survey Expert`,
      },
      {
        name: `Tamzidul Islam`,
        designation: `GIS Expert`,
      },
      {
        name: `Nasrin Sultana`,
        designation: `Photogrammetric Expert`,
      },
      {
        name: `Md. Mohaiminul Islam Rifat`,
        designation: `Jr. GIS Expert`,
      },
      {
        name: `Anuva Tabassum`,
        designation: `Junior Architect`,
      },
      {
        name: `Rakibul Hasan`,
        designation: `AutoCAD Operator-1`,
      },
      {
        name: `Md. Emon Ali`,
        designation: `AutoCAD Operator-2`,
      },
      {
        name: `Shanta Islam`,
        designation: `AutoCAD Operator-3`,
      },
    ],




  };
  const { title, location, client, project_Value,
    professional_staff, sylhet_common_drone_image,
    shahid_minar_image,
    ghatgot_park_image,
    zilla_parishad_image,
    rendered_service, description, list_down, list_up, quote_1, quote_2 } = projectInfo;
  const gridItems = [
    { title: "Location and Area", content: location },
    { title: "Project Value", content: project_Value },
    { title: "Client", content: client },
    { title: "Rendered Service", content: rendered_service },
  ];
  const GridItem = ({ items }) => {
    return (
      <div className="flex  justify-around items-center gap-5">
        {items.map((item, index) => (
          <div key={index} className="text-center ">
            <b>{item.title}</b> <br />
            <small>{item.content}</small>
          </div>
        ))}
      </div>
    )
  }

  return (
    <div className=''>
      <div className='relative '>
        <div className='h-[38.75rem]'>
          <img src={physical_feature_Rangpur_Sylhet} alt='physical_feature_Rangpur_Sylhet ' className='w-full h-full bg-cover sm:object-cover' />
        </div>
        <div className='px-[4.6762rem] py-[1.25rem] font-bold   bg-gray-800 text-white absolute bottom-0 h-[8.875rem] w-full  
         opacity-90 leading-[1.2] tracking-wide 2xl:texssst-[2.8219rem]  xl:texssst-[2.5219rem]  lg:text-[1.80rem]   md:text-[1.52rem]  '>
          <p className=' text-justify'> {title}</p>
        </div>
      </div>

      <div className="bg-[#e0e0d8] p-10 opacity-80 ">
        <GridItem items={gridItems} />
      </div>
      <div className='px-[8%] '>
        <div className='my-[1.625rem] w-[22.875rem]'>
          <b className='font-bold text-[#333333] text-[1.75rem]'>Project Activities</b>
        </div>
        <p className='leading-loose tracking-wide text-justify'>
          {description}
        </p>

        <div className='my-[1.625rem] '>
          <div className='h-[25rem]'>
            <img src={sylhet_common_drone_image?.photo} alt='sylhet_common_drone_image' className='w-full h-full bg-cover sm:object-cover' />
          </div>
          <i>{sylhet_common_drone_image?.caption}</i>
        </div>

        <div>
          <ul className='list-disc leading-loose tracking-wide text-justify'>
            {
              list_up.map(({ content }, index) => (
                <li key={index}>{content}</li>
              ))
            }


          </ul>
        </div>


        <div className='my-[1.625rem] '>
          <div className='h-[25rem]'>
            <img src={zilla_parishad_image?.photo} alt='Zilla_Parishad_Rangpur' className='w-full h-full bg-cover sm:object-cover' />
          </div>
          <i>{zilla_parishad_image?.caption}</i>
        </div>


        <div>
          <p className='tracking-wider text-4xl leading-[2.625rem] text-justify text-[#84BE3F]'>

            <span className='font-extrabold mr-1'>{quote_1.substring(0, 12)}</span>
            {quote_1.substring(12)}

          </p>
        </div>
        <div className='my-[2.625rem] '>
          <div className='h-[25rem]'>
            <img src={ghatgot_park_image?.photo} alt='Ghaghot' className='w-full h-full bg-cover sm:object-cover' />
          </div>
          <i>{ghatgot_park_image?.caption}</i>
        </div>
        <div>
          <p className='tracking-wider text-4xl leading-[2.625rem] text-justify text-[#84BE3F]'>
            <strong className='font-extrabold '>{quote_2.substring(0, 14)}</strong>  {quote_2.substring(14)}</p>
        </div>

        <div className='my-[2.625rem] '>
          <div className='h-[25rem]'>
            <img src={shahid_minar_image?.photo} alt='Shaheed_Minar' className='w-full h-full bg-cover sm:object-cover' />
          </div>
          <i>{shahid_minar_image?.caption}</i>
        </div>
        <div>
          <ul className='list-disc leading-loose tracking-wide text-justify'>

            {
              list_down.map(({ content }, index) => (
                <li key={index}>{content}</li>
              ))
            }



          </ul>
        </div>
        <div className='my-10'>
          <strong className="text-lg ">Name of senior professional staff</strong>
          <div className="flex flex-col gap-2 mt-4">
            {professional_staff?.map(({ name, designation }) => (
              <p>
                <strong className='mr-1'>{name},</strong>{designation}
              </p>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RangpurSylhetPhysicalFeatureSurvey;
