import React, { useEffect, useState } from 'react';
import { header_navs } from '../Utils/utils';
import Drawer from './Drawer';
import { useHref, useLocation, useNavigate } from 'react-router';
import tiller_logo_black from '../Assets/Images/tiller-logo-black.png';
import tiller_logo_white from '../Assets/Images/tiller-logo-white.png';
import '../Assets/Styles/header.css'


const Submenu = ({ elements }) => {
  return (<div className=' absolute -left-2 top-full  hidden  group-hover:block 
  '>

    <div className='h-6 bg-transparent w-full '></div>
    <ul className='space-y-2 bg-second flex p-2 border-second rounded-md shadow-lg z-50  flex-col items-center justify-center px-4'>
      {elements.map((item, index) => {

        return (<li className='capitalize'><a key={index} href={item.url} className='text-sm text-[#f2f2f2] border-b-transparent hover:border-b-main border-b-[1.5px] '>

          {item.name}
        </a></li>)
      })}
    </ul>
  </div>
  );
};

export const scrolldirection = {
  up: false,
  down: false
}

const handleScroll = (e) => {
  const element = document.getElementsByClassName('header')[0]
  const element2 = document.getElementsByClassName('breadcrumbs-container')[0]
  if (e?.deltaY > 0 /* && window.scrollY > 200 */) {
    if (!scrolldirection.down) {
      element?.classList?.add('moveout')
      element?.classList?.remove('moveon')
      element2?.classList?.add('moveout')
      element2?.classList?.remove('moveon')
      scrolldirection.down = true
      scrolldirection.up = false
    }
  }
  else if (e?.deltaY < 0) {
    if (!scrolldirection.up) {
      element?.classList?.add('moveon')
      element?.classList?.remove('moveout')
      element2?.classList?.add('moveon')
      element2?.classList?.remove('moveout')
      scrolldirection.up = true
      scrolldirection.down = false
    }

  }
}

const Header = () => {

  const location = useLocation()
  useEffect(() => {
    if (location.pathname.split('/').length > 2 && location.pathname.split('/')[1] == 'services') {
    } else {
      window.addEventListener('wheel', handleScroll)
    }

  }, [])

  useEffect(() => {

    document.getElementsByClassName('header')[0]?.classList.add('moveon')
    document.getElementsByClassName('breadcrumbs-container')[0]?.classList.add('moveon')
    document.getElementsByClassName('header')[0]?.classList.remove('moveout')
    document.getElementsByClassName('breadcrumbs-container')[0]?.classList.remove('moveout')
    scrolldirection.up = false
    scrolldirection.down = false
  }, [location])
  // State to manage the drawer's open/closed state
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  // Get the current pathname using the useLocation hook
  const pathname = useLocation().pathname;

  // Function to toggle the drawer
  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  // Navigate function from the useNavigate hook
  const navigate = useNavigate();

  return (
    <div id="main-header" key={"header"} className='header z-40 sticky top-0 left-0 grid grid-cols-12 h-[5rem] w-full px-10 lg:px-16 bg-second'>
      {/* Left side of the header */}
      <div className='col-span-full text-start lg:col-span-3 my-auto flex items-center justify-start gap-4'>
        {/* Hamburger icon for the drawer */}
        <svg fill="none" stroke="#f2f2f2" viewBox='0 0 24 24' strokeWidth={1.5} xmlns="http://www.w3.org/2000/svg"
          onClick={toggleDrawer}
          aria-hidden="true" className='lg:hidden h-9 w-9 cursor-pointer scale-y-[160%]'>
          <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
        </svg>
        {/* Logo and title */}
        <div className='cursor-pointer' onClick={() => { navigate('/') }}>
          <div className='flex items-center justify-start gap-3 '>
            <img src={tiller_logo_white} className='w-14 h-13 ' />
            <div>
              <p className='text-main font-semibold tracking-widest text-3xl leading-[.9]'
              style={{ fontFamily: "Nova Flat Book" }}>Tiller</p>
              <p className='tracking-wider italic text-[10px] text-[#f2f2f2] leading-[.9]'>easing spatial solution</p>
            </div>
          </div>
        </div>
      </div>

      {/* Right side of the header */}
      <div className='hidden lg:grid col-start-6 xl:col-start-7 my-auto col-span-full grid-cols-5 text-center text-[#f2f2f2] '>
        {header_navs.map((nav_item, index) => {
          return (
            <a
              key={index}
              href={nav_item?.handler ? "/#contact-us-div" : `${nav_item.url}`}
              className={`mx-auto px-4 relative group text-center group cursor-pointer`} >
              <p className='text-sm uppercase'>{nav_item.name}</p>
              <span className={`border-b-2 absolute bottom-0 left-0 w-full ${nav_item.url == pathname ? " border-b-main " : "border-b-transparent"} transition-transform transition-border 
       duration-[3s] group-hover:border-main`} />
            </a>
          )
        })}
      </div>

      {/* Drawer component */}
      {isDrawerOpen && <Drawer location={pathname} isOpen={isDrawerOpen} setIsOpen={setIsDrawerOpen} />}
    </div>
  );
}


export default Header