import React, { useState } from 'react'
import { social_navs } from '../Utils/utils'
import Map from './AddressMap'


const reciever ='+8801324175700'
const Contact = () => {
    const [formData, setFormData] = useState({ name: "", email: "", message: "" })
    const handleChange = e => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }
    const handleSubmit = (e, type = null) => {
        e.preventDefault()
      
        window.location.href=type? `https://wa.me/${reciever}?text=${formData.message}`:
        `mailto:info@tiller.com.bd?subject=Message from ${formData.name || "Annonymous"}&body=${formData.message}`
      
    }

    return (
        <div className='contact-us-div' id='contact-us-div'>
            <h2 className='text-5xl py-20 uppercase font-bold text-main'>Contact Us</h2>
            <div className='lg:flex gap-10 space-y-4 lg:space-y-0   '>
                <div className='w-full border-main border-2 rounded-xl' >
                    <h2 className='bg-main rounded-t-lg  text-[#f2f2f2] font-semibold text-2xl px-12 py-4'>{'Let\'s get connected'}</h2>

                    <div className='flex gap-4 items-center px-12 mt-8'>
                        <svg fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" className='w-5 h-5'>
                            <path clipRule="evenodd" fillRule="evenodd" d="M7 2a2 2 0 00-2 2v12a2 2 0 002 2h6a2 2 0 002-2V4a2 2 0 00-2-2H7zm3 14a1 1 0 100-2 1 1 0 000 2z" />
                        </svg>
                        <p className='py-2'>+880 1324-175700</p>
                    </div>
                    <div className='flex gap-4 items-center px-12'>
                        <svg fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" className='w-5 h-5'>
                            <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                            <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                        </svg>
                        <p className='py-2'>info@tiller.com.bd</p>
                    </div>

                    <div className='flex gap-4 items-center px-12 mb-8'>
                        <svg fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" className='w-5 h-5'>
                            <path clipRule="evenodd" fillRule="evenodd" d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z" />
                        </svg>
                        <p className='py-2'>Level-10 & 11,House-01, Road-03 ,Block-A, Section- 6, Mirpur, Dhaka.</p>
                    </div>

                    <ul className='grid grid-cols-12 px-12'>
                        {social_navs.map(nav_item => {
                            return (<li className='border-[1px] w-fit p-1 border-second scale-50 text-black'>
                                
                                <a href={nav_item.url} className=''>{nav_item.icon}</a>
                            </li>)
                        })}

                    </ul>

                </div>
                {/* MESSEGE TO TILLER */}

                <div className='w-full border-main border-2 rounded-xl' >
                    <h2 className='bg-main rounded-t-lg text-[#f2f2f2] font-semibold text-2xl px-12 py-4'>{'Ask Us Anything'}</h2>
                    <form onSubmit={handleSubmit} className='space-y-3 px-14 my-8'>
                        <input placeholder='Name' onChange={handleChange} style={{ 'box-shadow': ' rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px' }}
                            value={formData.name} name='name' className='px-6 outline-none  border-black border-b-[1.5px]  block w-full' />
                        <input placeholder='Email address' onChange={handleChange} style={{ 'box-shadow': ' rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px' }}
                            value={formData.email} name='email' className='px-6 outline-none  border-black border-b-[1.5px]  block w-full' />
                        <textarea placeholder='write to Tiller...' onChange={handleChange} style={{ 'box-shadow': ' rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px' }}
                            value={formData.message} name='message' className='px-6 outline-none  border-black border-b-[1.5px]  block w-full max-h-52 min-h-[4rem]' />
                        <div className='flex justify-center gap-4 text-center'>
                            <div className='flex items-center justify-center gap-4 bg-main hover:bg-opacity-80 active:bg-opacity-100  hover:shadow-lg active:scale-95 px-6 w-full mx-auto text-[#f2f2f2] font-semibold 
                        py-1 tracking-wider  rounded-md uppercase'>
                                <i className="fa-regular fa-envelope fa-xl  float-left"></i>
                                <button type='submit' onClick={handleSubmit} >Email</button>
                            </div>
                            <div className='flex items-center justify-center gap-4 bg-main hover:bg-opacity-80 active:bg-opacity-100  hover:shadow-lg active:scale-95 px-6 w-full mx-auto text-[#f2f2f2] font-semibold 
                        py-1 tracking-wider  rounded-md uppercase'>
                                <i className="fa-brands fa-whatsapp fa-xl  float-left"></i>
                                <button type='submit' onClick={(e) => handleSubmit(e, 'w-app')}>Whatsapp</button>
                            </div>

                        </div>

                    </form>


                </div>
            </div>
            <div className=' pb-8'>
                <Map />
            </div>

        </div>
    )
}

export default Contact