import Divider from "./Divider"

const MaskedImage = ({ type, src, dividerType = 'cover',extra_classes }) => {

    return <div className='absolute top-0 h-full  w-full right-0 bottom-0 -z-10'
        style={{
            backgroundImage: `url(${src})`, backgroundRepeat: "no-repeat", backgroundSize: "cover",
            /* backgroundAttachment: dividerType != 'cover' ? "fixed" : '' */
        }}>
        <div className='absolute top-0 left-0 right-0 bottom-0 bg-gradient-to-t from-[#000] via-[#080808b4] to-transparent'></div>
        {dividerType == 'cover' ? <Divider extra_classes={extra_classes} /> : <></>}

    </div>
}

export default MaskedImage