import React from 'react'
import Contacts from '../Components/Contact'

const Contact = () => {
    return (
        <div className='px-4'>
            <Contacts />
        </div>
    )
}

export default Contact