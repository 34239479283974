import ComparatorSectionLayout from "../Service_Layouts/ComparatorSectionLayout";
import BasicSectionLayout from "./BasicSection";
import SingleContentLayout from "./SingleItemSection";

const SectionFrame = ({ content ,cover}) => {

    let LayoutFrame = null
    switch (content?.content_type) {
        case "image-comparator":
            LayoutFrame = <ComparatorSectionLayout content={content} />
            break;
        case "single-photo":

            LayoutFrame = <SingleContentLayout content={content} />
            break;
        default:
            LayoutFrame = <BasicSectionLayout content={content} />
    }
    return(<div 
     className={`section w-full h-[85vh] md:h-[86vh] lg:h-[79.6vh]  `}>
        <div style={{filter:'blur(2px)',  backgroundImage: `url(${cover})`, backgroundPosition:'bottom', backgroundRepeat: "no-repeat", backgroundSize: "cover",}} 
        className=" z-[100] absolute bottom-0 left-0 right-0 h-[15vh] md:h-[14vh]  lg:h-[20.4vh]  w-full"></div>
        {LayoutFrame}
    </div>) 
}

export default SectionFrame
