import React from 'react';


const Pagination = ({ currentIndex, data, actionHandler, handleHover }) => {
  const hover = (action, index) => {
    if (action) {
      handleHover(index)
    }
    else {
      handleHover(null)
    }
  }

  return (
    <div className="flex mt-4  rounded-l-full rounded-r-full text-white items-center justify-center bg-slate-300 mx-auto w-fit px-2">
      {data?.map((_, index) => (
        <button
          onMouseEnter={(e) => { hover(true, index) }}
          onMouseLeave={(e) => { hover(false, index) }}
          onClick={(e) => { e.preventDefault(); actionHandler(index) }}
          key={index}
          className={`mx-2 w-4 h-4 rounded-full my-2 ${currentIndex === index ? 'bg-yellow-500' : 'bg-white '}`} />
      ))}
    </div>
  );
};

export default Pagination;