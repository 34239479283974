import React, { useEffect, useState } from 'react';
import Confetti from 'react-confetti';
import { useCookies } from 'react-cookie';
import confetti_1 from '../Assets/Images/Misc/confetti_modal_1.png'
import confetti_2 from '../Assets/Images/Misc/confetti_modal_2.png'
import confetti_3 from '../Assets/Images/Misc/bg-icon.png'

const ConfettiEffect = () => {
    const [cookies, setCookie, removeCookie] = useCookies();
    const [confettiVisible, setConfettiVisible] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false)

    useEffect(() => {
        if (!cookies.confetti) {
            // Set the state to show confetti
            setConfettiVisible(true);
            // Set the cookie to remember that the confetti has been shown
            //    /*  setTimeout(() => {
                        setCookie('confetti', true, { path: '/' });
            // }, 100); */
        }
    }, [cookies.confetti]);

    useEffect(() => {

        if (confettiVisible /* && cookies.confetti */) {
            setIsModalOpen(true)
             setTimeout(() => {
                 setConfettiVisible(false)
                 setIsModalOpen(false)
             }, 10000);
        }
    }, [confettiVisible])

    // Listen for window/tab close event to remove the cookie
    /*     useEffect(() => {
            const handleWindowClose = (e) => {
                console.log(cookies);
                if (cookies.confetti) {
                    removeCookie('confetti', { path: '/' });
                }
            };
    
            window.addEventListener('beforeunload', handleWindowClose);
    
    
        }, []); */
    const handleClickOuter = () => {
        setTimeout(() => {
            setConfettiVisible(false)
            setIsModalOpen(false)
        }, 250);

        // Add your logic here for what you want to happen when clicking on the outer part of the modal
    };

    const handleClickInner = (e) => {
        e.stopPropagation(); // Prevent the click event from bubbling up to the outer component

        // Add your logic here for what you want to happen when clicking on the inner part of the modal
    };
    return (
        <div>
            {isModalOpen && <div className='bg-[#ffffff6b] fixed inset-0 h-full  z-50' onClick={handleClickOuter}>
                {confettiVisible && <Confetti />}
                <div className='bg-[#e6edf5] w-[80%] md:w-[55%] h-[55vh] md:h-[60vh] rounded-lg shadow-lg shadow-slate-600 mx-auto my-44 select-none'>
                    <div className='relative w-full h-full bg-opacity-10 z-10 ' onClick={handleClickInner}>
                        <button onClick={(e) => { e.stopPropagation(); e.preventDefault(); setIsModalOpen(false); }}
                            className='font-semibold z-[70] text-red-600 text-opacity-50 hover:text-opacity-100 float-right mr-1 px-1 hover:scale-105'>X</button>
                        <div className='absolute top-6 left-0 opacity-20 h-full w-full right-0 '>
                            <div className='h-1/2 rotate-180' style={{ backgroundPosition: 'center', backgroundRepeat: 'repeat-x', backgroundImage: `url(${confetti_3})` }}></div>
                            <div className='h-1/2 ' style={{ backgroundPosition: 'center', backgroundRepeat: 'repeat-x', backgroundImage: `url(${confetti_3})` }}></div>
                        </div>
                        <img src={confetti_1} className='absolute rotate-180 left-0 bottom-0 h-[35%] md:h-[40%] z-0' />
                        <img src={confetti_1} className='absolute right-0 top-6 h-[35%] md:h-[40%] z-0' />
                        <div className=' w-[70%] text-center absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2
                         text-[20px] md:text-[40px] font-[600] text-main !font-poppins '>
                            <h2 className=' md:flex justify-center w-full font-[400]'>
                                <span className=''>
                                    <img className='mx-auto h-14 w-14 mb-4 md:mb-0' src='logo192.png' />
                                </span><p className='w-full'>Tiller is celebrating <span className='block md:inline'> 15 years of</span></p> </h2>
                            <h2>Innovation</h2>
                            <h2>Technology</h2>
                            <h2>Sustainability</h2>
                            <img src={confetti_2} className='w-1/2 md:w-1/4 mx-auto mt-6 md:mt-0' />
                        </div>


                    </div>

                </div>
            </div>}
        </div>
    );
}

export default ConfettiEffect;
