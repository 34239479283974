import React from 'react'

const Tabs = ({ selectedTab, setSelectedTab }) => {

    return (
        <div className='top-20 w-full py-2 mb-2'>
            <ul className='mx-auto w-fit flex gap-5 '>
                <li onClick={() => setSelectedTab('News')} className={`font-semibold px-2 
                 ${selectedTab == 'News' ? "border-b-black " : ""} border-b-[1px] cursor-pointer`}>News</li>

                <li onClick={() => setSelectedTab('Project')} className={`font-semibold px-2 
                 ${selectedTab == 'Project' ? "border-b-black " : ""} border-b-[1px] cursor-pointer`}>Project</li>

                <li onClick={() => setSelectedTab('Carreer')} className={`font-semibold px-2 
                 ${selectedTab == 'Carreer' ? "border-b-black " : ""} border-b-[1px] cursor-pointer`}>Carreer</li>

                <li onClick={() => setSelectedTab('Service')} className={`font-semibold px-2 
                 ${selectedTab == 'Service' ? "border-b-black " : ""} border-b-[1px] cursor-pointer`}>Service</li>

                <li onClick={() => setSelectedTab('Contact Info')} className={`font-semibold px-2 
                 ${selectedTab == 'Contact Info' ? "border-b-black " : ""} border-b-[1px] cursor-pointer`}>Contact Info</li>

            </ul>

        </div>
    )
}

export default Tabs