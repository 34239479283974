import React from 'react';
import bg_sandboard from '../../Assets/Images/Projects/BG_Sanboard.png';
import front_screen from '../../Assets/Images/Projects/front_screen.png';
import basin from '../../Assets/Images/Projects/basin.png';
import washroom from '../../Assets/Images/Projects/washroom.jpg';
import meeting from '../../Assets/Images/Projects/meeting.jpg';
import dashboard from '../../Assets/Images/Projects/dashboad.svg';
import green_copy from '../../Assets/Images/Projects/green_copy.png';
import { photoObjectFormatter } from './TVET';



const SanboardScoping = () => {

  const projectInfo = {
    location: 'Bangladesh',
    title: 'Scoping Study of The National Sanitation Dashboard',
    client: "Department of Public Health and Engineering",
    budget: '0.20 Cr / $182.87',
    funded_by: 'Bill and Melinda Gates Foundation',
    middle_description_up: `The National Sanitation Dashboard in Bangladesh has emerged as a vital tool for managing fecal sludge
        and waste following the success in eradicating open defecation. The project, supported by the Bill &
         Melinda Gates Foundation and initiated by the Department of Public Health Engineering (DPHE), aims to
        provide integrated solutions to the challenges of waste and fecal sludge management and effectively tackle the multifaceted issues in Bangladesh.`,
    rendered_service: 'Software Development',
    right_description_up: `Bangladesh has made significant progress in eliminating open defecation, a key step in improving public health and sanitation. However, the country faces a new challenge: managing fecal sludge from pit latrines and septic tanks. Addressing this challenge is a top priority for sustainable waste and fecal sludge management.`,
    middle_description_down: `The Department of Public Health Engineering (DPHE) is initiating the "Feasibility for Implementing Solid Waste and Fecal Sludge Management System" project, supported by the Bill & Melinda Gates Foundation, to develop integrated solutions to address waste and fecal sludge management challenges.`,
    left_description: `The National Sanitation Dashboard is a data-driven initiative that provides valuable insights into urban and rural sanitation, enabling decision-makers and stakeholders to inform strategic actions and policies aimed at improving sanitation practices through comprehensive information gathered through rigorous efforts.`
    ,
    right_description_down: `This study involved a desk review of policy documents on Safely Managed On-Site Sanitation, City Wide Inclusive Sanitation, SDG-6 Goals, Solid Waste Management, and Liquid Waste Management, as well as interviews with officials from various agencies to gather insights and perspectives.`,

    front_screen_image: photoObjectFormatter(front_screen, ''),
    basin_image: photoObjectFormatter(basin, ''),
    washroom_image: photoObjectFormatter(washroom, ''),
    meeting_image: photoObjectFormatter(meeting, ''),
    dashboard_image: photoObjectFormatter(dashboard, ''),
    green_copy_image: photoObjectFormatter(green_copy, ''),
    bg_sandboard_image: photoObjectFormatter(bg_sandboard, ''),

  };
  const { title, location, client, funded_by, rendered_service, middle_description_up, right_description_up,
    middle_description_down, left_description,
    right_description_down, meeting_image, dashboard_image, washroom_image, basin_image,
    front_screen_image, green_copy_image, bg_sandboard_image } = projectInfo;
  const gridItems = [
    { title: "Location and Area", content: location },
    { title: "Funded By", content: funded_by },
    { title: "Client", content: client },
    { title: "Rendered Service", content: rendered_service },

  ];
  const GridItem = ({ items }) => {
    return (
      <div className='relative'>
        <divider className='absolute h-[100%] left-1/2 translate-x-[45%]  border-2 border-lime-500'></divider>
        <div className=" hidden lg:block    absolute left-[40%] translate-x-8 -translate-y-2.5  border-t-4 border-lime-500 mb-1 mt-2 w-[5%]"></div>
        <div className="   hidden lg:block  absolute right-[40%] transform  translate-y-36  border-t-4 border-lime-500 my-1 w-[5%]"></div>

        <div className="grid grid-cols-2 gap-5">
          {items.map((item, index) => (
            <div key={index} className="p-2 odd:ml-auto odd:text-end   border-lime-500 ">
              <b>{item.title}</b> <br />
              <small>{item.content}</small>
            </div>
          ))}
        </div>
      </div>
    )
  }
  return (
    <div className=' text-sm md:text-[15px]'>

      <div className='h-[30%] sm:h-[40%]'>
        <img src={bg_sandboard_image?.photo} alt='bg_sandboard' className='w-full h-full object-contain' />
      </div>
      <div className='px-[8%]'>
        <div className='flex flex-col justify-center items-center'>
          <p className='font-bold  text-3xl my-5 text-gray-700  text-center  md:w-1/2'>
            {title}
          </p>

          <p className='tracking-wide leading-loose  text-justify '>
            {middle_description_up}</p>
        </div>

        <div className='mt-8'>
          <GridItem items={gridItems} />
        </div>

        <div className='flex  flex-col lg:flex-row   justify-center items-center gap-5 my-10  sm:my-16'>
          <div className='h-[17%]  sm:h-[20%]  xl:w-[40%]'>
            <img src={green_copy_image?.photo} alt='bg_sandboard' className='w-full h-full object-contain' />
          </div>
          <p className=' lg:w-[23%] 2xl:w-[18%]  tracking-wide text-justify'>{right_description_up}</p>
          <p>

          </p>
        </div>


        <div>


          <div className='relative'>
            <div className='opacity-0 md:opacity-100'>

              <img src={bg_sandboard_image?.photo} alt='bg_sandboard' className='w-full h-full object-cover' />

            </div>
            <div className='absolute top-0 left-1/2 transform -translate-x-1/2  w-[60%]'>
              <img src={front_screen_image?.photo} alt='bg_sandboard' className='w-full h-full object-cover' />
            </div>
          </div>

          <p className='mt-[15%]  text-justify md:text-center    '>
            {middle_description_down}
          </p>
        </div>





        <div className=' mb-2'>
          <div className='flex  items-center xl:gap-5  lg:gap-8 md:gap-5  md:my-16'>
            <p className='xl:basis-[16%] lg:basis-[25%] md:basis-[32%] text-justify leading-normal'>{left_description}</p>
            <div className='h-[20rem] w-[20rem] flex-1'>
              <img src={dashboard_image?.photo} alt='bg_sandboard' className='w-full h-full object-cover' />
            </div>
          </div>

          <div className='grid grid-cols-1 md:grid-cols-2 gap-5 my-0 '>
            <div className='flex'>
              {[meeting_image, basin_image, washroom_image].map((image, index) => (
                <div key={index} className='h-[100%] w-[100%] '>
                  <img src={image?.photo} alt={`image_${index}`} className='w-full h-full object-cover' />
                </div>
              ))}
            </div>
            <p className='text-justify my-auto'>{right_description_down}</p>
          </div>

        </div>
      </div>
    </div>
  );
};

export default SanboardScoping;