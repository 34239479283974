import React, { useState, useEffect } from 'react';
// Import your CSS file for styling
import scrolldirection from './Header.js'
function BackToTop() {
    const [showButton, setShowButton] = useState(false);

    // A variable to store the timeout ID
    let scrollTimeout;

    // Function to handle the scroll event
    const handleScroll = () => {
        // Show the button
        setShowButton(true);

        // Clear the previous timeout (if any)
        clearTimeout(scrollTimeout);

        // Set a new timeout to hide the button after 3 seconds
        scrollTimeout = setTimeout(() => {
            setShowButton(false);
        }, 1250);
    };

    // Add a scroll event listener to show/hide the button
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const scrollToTop = () => {

        window.scrollTo({
            top: 0,
            behavior: 'smooth', // Add smooth scrolling effect
        });
        document.getElementsByClassName('header')[0]?.classList.add('moveon')
        document.getElementsByClassName('breadcrumbs-container')[0]?.classList.add('moveon')
        document.getElementsByClassName('header')[0]?.classList.remove('moveout')
        document.getElementsByClassName('breadcrumbs-container')[0]?.classList.remove('moveout')
        scrolldirection.up = true
      
    };

    return (
        <div className="App">
            
            {showButton && (
                <button className="back-to-top-button fixed bottom-20 right-10  rounded-full  " onClick={scrollToTop}>
                    <svg fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"
                        className='h-10 w-10 p-2 border-main border-opacity-50 bg-main bg-opacity-20 border-2 ease-in-out rounded-full animate-pulse duration-1000'>
                        <path clipRule="evenodd" fillRule="evenodd" d="M5.293 7.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 5.414V17a1 1 0 11-2 0V5.414L6.707 7.707a1 1 0 01-1.414 0z" />
                    </svg>
                    <p className='text-xs font-thin -tracking-widest'>Back to Top</p>
                </button>
            )}
        </div>
    );
}

export default BackToTop;
