import React, { useState } from 'react';
import { drawer_navs, social_navs } from '../Utils/utils';
import { useHref, useLocation } from 'react-router';
import { Link } from 'react-router-dom';

const Drawer = ({ isOpen, setIsOpen, location }) => {
    //   const [isOpen, setIsOpen] = useState(state);

    const toggleDrawer = () => {
        setTimeout(() => {
            setIsOpen(!isOpen);
        }, 100);
    };




    return (
        <div className={`fixed bottom-0 top-20 left-0  w-64 ease-in-out duration-100 overflow-hidden h-screen 
     bg-white shadow-lg transform transition-transform ${isOpen ? 'translate-x-0' : '-translate-x-full'}  lg:hidden`}>
            <div className="   text-smmd:text-xl py-10 px-8 mr-8">
                <ul className="space-y-4 ">
                    {drawer_navs.map((item, index) => {
                        return (<li className={`hover:bg-gray-200 bg-second bg-opacity-20  px-4 py-1 font-semibold  
                        ${location?.split('/')[1]?.toLowerCase() == item.name?.toLowerCase() ||
                                (location?.split('/')[1]?.toLowerCase() == "" && item.name?.toLowerCase() == 'home') ? 'border-b-2 border-main' : 'border-transparent'} `}>
                            <Link className='capitalize' to={item?.url}>{item?.name}</Link>
                        </li>)
                    })}
                </ul>
                <button
                    className="mt-4 bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600 w-full"
                    onClick={toggleDrawer}>Close</button>

                <div className='w-full py-4 text-center pt-10'>
                    <ul className='grid grid-cols-4 gap-x-8 w-full'>
                        {social_navs.map(nav_item => {
                            return (<li className='border-[1px] w-fit p-1 border-second scale-50'>
                                <a href={nav_item.url}>{nav_item.icon}</a>
                            </li>)
                        })}

                    </ul>
                    <p>Subscribe to Tiller</p>
                    <hr />
                    <p>Legal & Policies</p>
                </div>
            </div>
            <svg fill="#ef4444" viewBox="0 0 20 20" className='absolute top-3 right-3 h-10 w-10 cursor-pointer 
            hover:scale-110 hover:drop-shadow-lg active:scale-95 ' onClick={toggleDrawer}
                xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                <path clipRule="evenodd" fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 
                0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" />
            </svg>
        </div>
    );
};

export default Drawer;
