import React from 'react';
import Study_Area from '../../Assets/Images/Projects/Study_Area.jpg';
import Drone_Image from '../../Assets/Images/Projects/Drone_Image.jpg';
import BM_PIllar_Location from '../../Assets/Images/Projects/BM_PIllar_Location.jpg';
import map_picture from '../../Assets/Images/Projects/map_picture.png';
import prepartion_of_surevey from '../../Assets/Images/Projects/Preparation_of_Survey.jpeg'
import { photoObjectFormatter } from './TVET';

const projectInfo = {
    location: 'Hajiganj & Shahrasti Upazila, Chadpur District',
    title: 'Preparation of Comprehensive Development Plan for Nine Upazilas- Package 01 (Satellite Image Processing and Geo-referencing, Physical Feature, Landuse Topographic Surveys and Preparation of Planning)',
    client: "UDD",

    budget: '2.78 Cr / $240,291.38',
    rendered_service: 'Development/Strategic Planning',
    start_date: '23 June, 2022',
    completion_date: '24 June, 2023',
    description_up: `The government of Bangladesh has taken steps to ensure sustainable development and advancement in the unplanned growth of urban areas by establishing a green, clean, and resilient path. In light of this fact, the Bangladeshi government has undertaken efforts to reorganize the current governance system. In this context, Bangladesh Govt. has focused specifically on the Upazila Parishad in this regard because it is the most underdeveloped administrative unit and cannot carry out planned rural-urban development.`,
    description_down: `Economic linkages between rural and urban areas are vital, as urban centers typically hold a considerable share of agricultural markets and supply various inputs to the agricultural sector. Additionally, essential social, health, educational, and other services provided to rural areas stem from urban centers. Hence, controlling land use transformation in both regions is crucial. Considering this fact, the Bangladeshi government has been taken the Preparation of Comprehensive Development Plan for Nine Upazila project. This initiative aims to create an integrated plan that will ensure proper land use transformation while preventing unauthorized and unplanned development in both rural and urban areas. This project has been preparing a comprehensive plan in considering the social, economic, environmental, and natural consequences of the unplanned growth for project area which will assist decision maker in developing climate sensitive, gender responsive, sustainable and inclusive city.`,
    list_up: [{ content: `Primary data collection such as Physical Feature, Topographic, Land use, Transportation, Hydrological, and Impact of Climate Change and    Disaster Study etc. and secondary Data Collection (i.e., Risk Sensitive Data, Drainage Data, Utility Line, etc.) map Preparation` },
    {
        content: `Developing and integrating monitoring dashboard to perceive the progress of data collection activities`
    },
    {
        content: ` Integrating low-lying areas into the development process and proposing a new strategy for protecting agricultural land.`
    },

    ],
    list_down: [
        { content: ` Identifying the trend, pattern and concentration of inter-intra-regional connectivity, eco-sensitive area and future land use change to regulate future urban growth` },
        { content: `Proposing new plans and polices for mitigation of different types of hazards, minimizing the adverse impacts of climate change and recommend possible adaptation strategies for the region` },
        { content: `Development of policy and alternative strategies for plan preparation considering urban built environment as well as their adaptation capabilities.` },

    ],
    quote_1: `Reviewing the national plans, policies, guidelines (i.e., Delta Plan, Perspective Plan 2041, 6th -8th Five Year Plans, and etc.) as well as international commitments (SDGs, Sendai Framework, etc.) to ensure that this comprehensive plan is in line with national goals and policies.`,
    quote_2: `Establishment of BM pillar for getting accurate and consistent coordinate systems for project area, Base Map Preparation by using Photogrammetric Method, DEM Preparation for topographic representation and hydrological modeling and App develop in digital platform to monitor the physical feature data collection activities.`,
    professional_staff: [

        {
            name: `Al-Ferdous Ahmed`,
            designation: `Junior Urban Planner`,
        },
        {
            name: `G.M. Towhidul Islam`,
            designation: `Junior Urban Planner`,
        },

        {
            name: `Tamzidul Islam`,
            designation: `GIS and RS Expert`,
        },
        {
            name: `Md. Yeasin Mostafi`,
            designation: `Survey Expert`,
        },
        {
            name: `Md. Mustafigur Rahman`,
            designation: `Photogrammetric Expert`,
        },
        {
            name: `Md. Mohaiminul Islam Rifat`,
            designation: `Jr. GIS Expert`,
        },
        {
            name: `Md. Zakaria Salim`,
            designation: `GIS and RS Technician`,
        },
        {
            name: `Md. Merazul Islam`,
            designation: `GIS and RS Technician`,
        },
        {
            name: `Shahjalal Tusha`,
            designation: `GIS and RS Technician`,
        },
    ],
    study_area_image: photoObjectFormatter(Study_Area),
    drone_image: photoObjectFormatter(Drone_Image, ''),
    bm_pollar_location_image: photoObjectFormatter(BM_PIllar_Location),
    map_picture_image: photoObjectFormatter(map_picture),
    prepartion_of_surevey_image: photoObjectFormatter(prepartion_of_surevey)


};

const ComprehensivePlanNineUpazila = () => {

    const { title, location, client, budget, rendered_service, start_date, completion_date, professional_staff, description_up, description_down, list_up, list_down, quote_1, quote_2, bm_pollar_location_image,
        study_area_image
        ,
        drone_image,
        map_picture_image,
        prepartion_of_surevey_image

    } = projectInfo;

    const gridItems = [
        { title: "Location and Area", content: location },
        { title: "Client", content: client },
        { title: "Budget", content: budget },
        { title: "Rendered Service", content: rendered_service },

    ];
    const GridItem = ({ items }) => {
        return (
            <div className="flex flex-col justify-center items-center gap-5">
                {items.map((item, index) => (
                    <div key={index} className="text-center ">
                        <b>{item.title}</b> <br />
                        <small>{item.content}</small>
                    </div>
                ))}
            </div>
        )
    }
    return (
        <div>
            <div className='relative'>
                <div className='h-[38.75rem]'>
                    <img src={drone_image?.photo} alt='Drone_Image' className='w-full h-full object-cover sm:object-cover ' />
                </div>
                <div className='flex absolute bottom-2 right-2 opacity-90 gap-5 m-5 '>
                    <p className='bg-white rounded-full px-6 py-2 '>Development</p>
                    <p className='bg-white rounded-full px-6 py-2 '>strategic planning</p>
                </div>
            </div>





            <div className='px-[8%]'>
                <div className="md:flex justify-between gap-4 my-5 relative ">
                    <div className="border-2 border-lime-500 p-4 md:w-[66%] sm:w-full h-[40rem]  md:h-[30rem] lg:h-[26rem] sm:h-[20rem]
                                    mb-4 md:mb-0">
                        <p className=" tracking-tighter  md:tracking-wider font-bold text-2xl leading-[2rem] md:leading-[3rem] text-justify">{title}</p>
                        <div className="md:absolute bottom-10  text-lg">
                            <p className='mb-2 mt-14 md:mt-0'><strong>Start date (month/year): </strong>{start_date}</p>
                            <p><strong>Completion date (month/year): </strong>{completion_date}</p>
                        </div>
                    </div>

                    <div className="border-2 border-lime-500 md:w-[25%] sm:w-full h-[22rem]  md:h-[30rem] lg:h-[26rem] md:p-5 ">
                        <GridItem items={gridItems} />
                    </div>
                </div>


                <p className='leading-loose tracking-wide text-justify'>
                    {description_up}
                </p>
                <div className='h-[38.75rem] my-[1.625rem]'>
                    <img src={study_area_image?.photo} alt='Study_Area' className='w-full h-full object-cover sm:object-cover' />
                </div>
                <p className='leading-loose tracking-wide text-justify'>
                    {description_down}
                </p>
                <div className='my-[1.625rem]'>
                    <p className='tracking-tight text-2xl leading-[2rem] text-justify text-[#84BE3F]'>
                        {quote_1}</p>
                </div>
                <div className='h-[38.75rem]'>
                    <img src={prepartion_of_surevey_image?.photo} alt='prepartion_of_surevey' className='w-full h-full object-cover sm:object-cover' />
                </div>
                <div className='my-[1.625rem]'>
                    <p className='tracking-tight text-2xl leading-[2rem] text-justify text-[#84BE3F]'>
                        {quote_2}</p>
                </div>

                <div className='h-[38.75rem]'>
                    <img src={bm_pollar_location_image?.photo} alt='BM_PIllar_Location' className='w-full h-full object-cover sm:object-cover ' />
                </div>
                <div className='my-[1.625rem]'>
                    <ul className='list-disc leading-loose tracking-wide text-justify'>
                        {
                            list_up.map(({ content }, index) => (
                                <li key={index}>{content}</li>
                            ))
                        }


                    </ul>
                </div>
                <div className='h-[38.75rem] my-[1.625rem]'>
                    <img src={map_picture_image?.photo} alt='map_picture' className='w-full h-full object-cover sm:object-cover' />
                </div>
                <div>
                    <ul className='list-disc leading-loose tracking-wide text-justify'>
                        {
                            list_down.map(({ content }, index) => (
                                <li key={index}>{content}</li>
                            ))
                        }


                    </ul>
                </div>

                <div className='my-10'>
                    <strong className="text-lg ">Name of senior professional staff</strong>
                    <div className="flex flex-col gap-2 mt-4">
                        {professional_staff?.map(({ name, designation }) => (
                            <p>
                                <strong className='mr-1'>{name},</strong>{designation}
                            </p>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ComprehensivePlanNineUpazila;