import React, { useEffect, useRef, useState } from 'react'
import survey from '../Assets/Images/Slider/Drone Final.mp4'
import planning from '../Assets/Images/Slider/Urban Planning and Design.mp4'
import software from '../Assets/Images/Slider/softwaredevlop.mp4'
import citispace_1 from '../Assets/Images/Slider/Re-imaginingCitizens Space_1.jpg'
import citispace_2 from '../Assets/Images/Slider/Re-imaginingCitizens Space_2.jpg'
import lidar_video from '../Assets/Images/Slider/LiDAR App Dev.mp4'
import web_gis_app from '../Assets/Images/Slider/Location Intelligence.mp4'
import capacity from '../Assets/Images/Slider/Capacity Building.jpg'
import modeling from '../Assets/Images/Slider/ModelingandSimulation.mp4'
import feasibility from '../Assets/Images/Slider/feasibility.jpg'


export const sliding_section = [
    {
        content_type: 'video',
        title: "Urban Planning and Design", order: 1,
        desc: "Tiller pioneers climate-resilient, inclusive, and sustainable urban master plans, adapting to local needs, fostering economic and social vitality in the ever-evolving urban design and planning landscape.",
        video: planning,
    }, {
        content_type: 'video',
        title: "Software and Application Development", order: 2,
        desc: "Tiller excels in creating user-friendly, scalable software solutions, seamlessly integrating technology across diverse sectors, from conceptualization to deployment, driving efficiency and success in the digital realm.",
        video: software,
    }, {
        content_type: 'video',
        title: "Modelling and Simulation", order: 3,
        desc: "Tiller utilizes AI for highly accurate transport flow analysis, blending predictive analytics with transport planning expertise, employing water and climate modeling for resource and environmental solutions.",
        video: modeling,
    }, {
        content_type: 'image',
        title: "Feasibility and Detailed Design", order: 4,
        desc: "Passionate about design, Tiller conducts feasibility studies and detailed designs, utilizing hand drawing, illustration, and digital tools to innovate and reimagine places that meet precise development requirements.",
        photo: feasibility,
    }, /* {
        content_type: 'image',
        title: "Re-imagining Citizens Space", order: 5,
        desc: "DUmmy",
        photo: citispace_2,
    }, {
        content_type: 'image',
        title: "Re-imagining Citizens Space", order: 5,
        desc: "DUmmy",
        photo: citispace_1,
    }, */
    , {

        content_type: 'video',
        title: "Web GIS Application Development", order: 6,
        desc: "Tiller offers unique capabilities in GIS applications, empowering clients with deeper insights through web mapping, visual representations, spatial analysis, and real-time data collaboration applications.",
        video: web_gis_app,
    }, {

        content_type: 'video',
        title: "LiDAR Application Development", order: 7,
        // desc: "Tiller offers unique capabilities in GIS applications, empowering clients with deeper insights through web mapping, visual representations, spatial analysis, and real-time data collaboration applications.",
        video: lidar_video,
    }, {
        content_type: 'video',
        title: `UAV Survey, GIS Mapping & Remote Sensing`, order: 8,
        desc: "Tiller's signature service involves UAV-based surveying, introducing LiDAR technology in Bangladesh, utilizing fixed-wing JOUAV professional mapping UAV, and distinguishing itself through unparalleled technical expertise in aerial surveying.",
        video: survey,
    }, {
        content_type: 'image',
        title: "Capacity Building", order: 9,
        desc: "Collaborating with government institutions and universities in Bangladesh, Tiller enhances stakeholder capabilities through practical, hands-on GIS knowledge, specifically targeting local government officials involved in land use clearance certificate issuance.",
        photo: capacity,
    },
]

const Paginate = ({ handleChange, item_index, visible, handleHover }) => {
    const uniqueTitles = new Set();
    const hover = (action, index) => {      
        if (action) {
            handleHover(index)
        }
        else{
            handleHover(null)
        }
    }
    return (
        <div
            className='absolute flex gap-4 bottom-8 left-1/2 -translate-x-1/2 items-center justify-center bg-second bg-opacity-50 w-full px-[5%] py-4'>
            {sliding_section
                .sort((a, b) => a.order - b.order)
                .map((item, index) => {
                    if (!uniqueTitles.has(item.title)) {
                        uniqueTitles.add(item.title);

                        return (
                            <div key={index}>
                                <p
                                    onMouseEnter={(e) => { hover(true, index) }}
                                    onMouseLeave={(e) => { hover(false, index) }}
                                    onClick={(e) => {
                                        if (item_index !== index)
                                            handleChange(index);
                                    }}
                                    className={`hidden md:block text-xs opacity-50 font-bold cursor-pointer ${item_index !== index && 'hover:text-[#f2f2f2]'} hover:opacity-100 ${item_index === index ? "text-main !opacity-100" : "text-[#f2f2f2] "}`}
                                >
                                    {item.title}
                                </p>
                                <p
                                    onClick={(e) => {
                                        if (item_index !== index)
                                            handleChange(index);
                                    }}
                                    className={`md:hidden text-5xl font-bold cursor-pointer hover:text-second ${item_index === index ? "text-main" : "text-[#f2f2f2] "}`}
                                >
                                    .
                                </p>
                            </div>
                        );
                    } else {
                        return null; // Skip rendering if title is not unique
                    }
                })}
        </div>
    );
}



const Slider = () => {
    const [item_index, set_item_index] = useState(0)
    const [imageLoaded, setImageLoaded] = useState(false);
    const [videoLoaded, setVideoLoaded] = useState(false);
    const [hovered, setHovered] = useState(null); // State to track hover


    const handleImageLoad = () => {
        setTimeout(() => {
            setImageLoaded(true);
        }, 100);

    };
    const handleVideoLoad = () => {
        setTimeout(() => {
            setVideoLoaded(true);
        }, 100);

    };


    const handleImageError = () => {
        console.log('Error loading image!');
        setImageLoaded(true); // You might want to handle errors differently
    };





    const handleChange = (target) => {

        set_item_index(target);
        setImageLoaded(false); // Reset imageLoaded state when changing images
        setVideoLoaded(false)
    };

    const handle_prev_image = e => {
        if (item_index != 0)
            set_item_index(item_index - 1)
        else
            set_item_index(sliding_section.length - 2)

        setImageLoaded(false)
        setVideoLoaded(false)
    }

    const handle_next_image = e => {

        if (item_index != sliding_section.length - 2) {
            set_item_index(item_index + 1)

        }
        else
            set_item_index(0)
        setImageLoaded(false)
        setVideoLoaded(false)
    }

    const touchStartX = useRef(null);

    const handleTouchStart = (e) => {
        touchStartX.current = e.touches[0].clientX;
    };

    const handleTouchEnd = (e) => {
        if (touchStartX.current === null) {
            return;
        }

        const touchEndX = e.changedTouches[0].clientX;
        const deltaX = touchEndX - touchStartX.current;

        // Adjust this threshold as needed
        const swipeThreshold = 50;

        if (deltaX > swipeThreshold) {
            handle_prev_image();
        } else if (deltaX < -swipeThreshold) {
            handle_next_image();
        }

        touchStartX.current = null;
    };

    const handleMouseDown = (e) => {
        touchStartX.current = e.clientX;
        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
    };

    const handleMouseMove = (e) => {
        if (touchStartX.current === null) {
            return;
        }

        const mouseX = e.clientX;
        const deltaX = mouseX - touchStartX.current;

        // Adjust this threshold as needed
        const dragThreshold = 50;

        if (deltaX > dragThreshold) {
            handle_prev_image();
            touchStartX.current = null;
        } else if (deltaX < -dragThreshold) {
            handle_next_image();
            touchStartX.current = null;
        }
    };

    const handleMouseUp = () => {
        touchStartX.current = null;
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);
    };

    useEffect(() => {
        let timer;

        if (hovered == null || hovered!=item_index) {
            timer = setTimeout(() => {
                let new_item_index = 0;
                if (item_index < sliding_section.length - 2) {
                    new_item_index = item_index + 1;
                }
                set_item_index(new_item_index);
                setImageLoaded(false);
                setVideoLoaded(false);
            }, 5000);
        }

        return () => {
            clearTimeout(timer);
        };
    }, [item_index, hovered])


    return (
        <div className='relative w-full h-[calc(100vh-75px)]'
            onTouchStart={handleTouchStart}
            onMouseDown={handleMouseDown}
            onTouchEnd={handleTouchEnd} >

            <div className='h-full w-full relative overflow-hidden'>
                {!imageLoaded && (
                    <div className="spinner-overlay">
                        <div className="spinner"></div>
                    </div>
                )}
                {sliding_section[item_index]?.content_type === 'image' ? <img
                    className={`h-full w-full object-cover ${imageLoaded ? 'loaded' : ''}`}
                    loading='lazy'
                    style={{ animation: 'zoom-in-zoom-out 20s ease infinite' }}
                    onLoad={handleImageLoad}
                    onError={handleImageError}
                    src={sliding_section[item_index]?.photo}
                /> : (
                    <video
                        className={`h-full w-full object-cover ${imageLoaded ? 'loaded' : ''}`}
                        autoPlay
                        muted
                        loop
                        onLoadStart={handleVideoLoad}

                        src={sliding_section[item_index]?.video}
                        type="video/mp4"
                    >

                    </video>
                )}

                {(sliding_section[item_index]?.content_type == 'image') ? imageLoaded && (
                    <div className='absolute z-10 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-center
                     text-[#f2f2f2] px-4 bg-second bg-opacity-40 py-4 w-fit rounded-md '>
                        <h2 className='text-5xl font-bold tracking-wide w3-animate-right'>{sliding_section[item_index]?.title}</h2>

                    </div >
                ) : (<div className='absolute z-10 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-center
                text-[#f2f2f2] px-4 bg-second bg-opacity-40 py-4 w-fit rounded-md '>
                    <h2 className='text-5xl font-bold tracking-wide '>{sliding_section[item_index]?.title}</h2>
                </div >)}
            </div >
            <Paginate handleChange={handleChange} item_index={item_index} visible handleHover={setHovered} />


            <div className='absolute top-1/2 -translate-y-1/2  w-full left-0 flex justify-between px-4 z-10'>
                <div onClick={handle_prev_image} className="relative group cursor-pointer transform transition-transform duration-300 ease-in-out hover:scale-125  hover:translate-x-2">
                    <svg fill="none" stroke="#fff"
                        className='w-10 h-10  cursor-pointer'
                        strokeWidth={1.5} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />

                        {/* Replace with your SVG code here */}
                        <circle className='hidden' cx="50" cy="50" r="40" fill="blue" />
                    </svg>
                    <div
                        className="absolute w-16 h-16 border-2 border-main rounded-full bg-[#f2f2f2] bg-opacity-40 opacity-0 group-hover:opacity-100 transition-opacity duration-300 ease-in-out"
                        style={{
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                        }}
                    >
                    </div>
                </div>
                <div onClick={handle_next_image} className="relative group cursor-pointer transform transition-transform duration-300 ease-in-out hover:scale-125  hover:-translate-x-2">
                    <svg fill="none" stroke="#fff" className='w-10 h-10'
                        strokeWidth={1.5} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />


                        {/* Replace with your SVG code here */}
                        <circle className='hidden' cx="50" cy="50" r="40" fill="blue" />
                    </svg>
                    <div
                        className="absolute w-16 h-16 border-2 border-main bg-[#f2f2f2] bg-opacity-40 rounded-full opacity-0 group-hover:opacity-100 transition-opacity duration-300 ease-in-out"
                        style={{
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                        }}
                    ></div>
                </div>

            </div>
        </div >
    )
}

export default Slider