import React from 'react'
import { useHref, useLocation, useNavigate, useParams } from 'react-router'

const BreadCrumb = ({ }) => {
  const location = `${useLocation()?.pathname}`.split("/")

  const navigate = useNavigate()

  const handleNav = (e) => {

    navigate(`/${e.target.innerHTML?.split('/')[1]?.trim()}`)
  }

  return (
    <div id='bread-crumbs' className=' breadcrumbs-container hidden sm:block sticky top-20  z-30 px-16 py-2 italic capitalize bg-[#888787]  text-[#f2f2f2]'><a className='hover:underline' href='/'>Home</a> {location.map((item, index) => {
      return item && item.length ?
        <span onClick={location.length - 1 != index ? handleNav : () => { }}
          className='text-[#f2f2f2] bg-opacity-20 hover:underline   cursor-pointer'>/{item?.split("%20")?.join(" ")} </span> : ""
    })} </div>
  )
}

export default BreadCrumb