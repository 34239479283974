import projects_cover from '../Assets/Images/Projects/projects-cover.jpg'
import dummy_placeholder from '../Assets/Images/dummy_placeholder.png'
import cda_thumb from '../Assets/Images/Projects/cda-banner.png'
import cda_cover from '../Assets/Images/Projects/port.png'
import cda_desc from '../Assets/Images/Projects/cda1.jpg'
import cda_outcome from '../Assets/Images/Projects/cda-outcome.png'

import crisc_thumb from '../Assets/Images/Projects/3.Crisc.png'
import crisc_cover from '../Assets/Images/Projects/crisc-cover.jpg'
import crisc_desc from '../Assets/Images/Projects/crisc-desc.jpg'
import crisc_outcome from '../Assets/Images/Projects/crisc-outcome.jpg'

import pkcp_thumb from '../Assets/Images/Projects/4.PKCP.png'
import pkcp_cover from '../Assets/Images/Projects/pkcp_cover.jpg'
import pkcp_desc from '../Assets/Images/Projects/pkcp-desc.jpg'
import pkcp_outcome from '../Assets/Images/Projects/pkcp-outcome.png'
import pkcp_bottom from '../Assets/Images/Projects/pkcp-bottom.jpg'

import land_use_thumb from '../Assets/Images/Projects/5.Land-use.png'
import land_use_cover from '../Assets/Images/Projects/land-use-cover.jpg'
import land_use_desc from '../Assets/Images/Projects/land-use-desc.jpg'
import land_use_outcome from '../Assets/Images/Projects/land-use-outcome.jpg'

import mudp_thumb from '../Assets/Images/Projects/mudp-thumb.png'
import mudp_desc from '../Assets/Images/Projects/mudp-desc.jpg'
import mudp_image_1 from '../Assets/Images/Projects/mudp_image_1.jpg'
import mudp_image_2 from '../Assets/Images/Projects/mudp_image2.jpg'
import mudp_cover from '../Assets/Images/Projects/mudp-cover.jpg'


import earth_thumb from '../Assets/Images/Projects/earth-thumb.png'
import earth_desc from '../Assets/Images/Projects/earth-desc.jpg'
import earth_image_1 from '../Assets/Images/Projects/earth-image-1.jpg'
import earth_outcome from '../Assets/Images/Projects/earth-outcome.jpg'
import earth_cover from '../Assets/Images/Projects/earth-cover.jpg'


import plis_thumb from '../Assets/Images/Projects/plis-thumb.jpg'
import plis_cover from '../Assets/Images/Projects/plis-cover-2.png'
import plis_desc from '../Assets/Images/Projects/plis-desc.png'
import plis_meth from '../Assets/Images/Projects/plis-meth.png'
import plis_outcome from '../Assets/Images/Projects/plis-outcome.png'
import plis_image_2 from '../Assets/Images/Projects/plis-image-2.jpg'

import nsd_thumb from '../Assets/Images/Projects/nsd-thumb.jpg'
import nsd_cover from '../Assets/Images/Projects/nsd-cover.jpg'
import nsd_desc from '../Assets/Images/Projects/nsd-desc.png'
import nsd_outcome from '../Assets/Images/Projects/nsd-outcome.png'
import nsd_image_1 from '../Assets/Images/Projects/nsd-image-1.png'
import nsd_image_2 from '../Assets/Images/Projects/nsd-image-2.jpg'

import feasibility_1_thumb from '../Assets/Images/Projects/feasibility-1-thumb.png'
import feasibility_2_thumb from '../Assets/Images/Projects/feasibility-2-thumb.png'
import feasibility_2_cover from '../Assets/Images/Projects/feasibility-2-cover.jpg'
import feasibility_2_desc from '../Assets/Images/Projects/feasibility-2-desc.jpg'
import feasibility_2_outcome from '../Assets/Images/Projects/feasibility-2-outcome.jpg'

import payra_port_thumb from '../Assets/Images/Projects/payra-port-thumb.png'
import payra_port_cover from '../Assets/Images/Projects/payra-port-cover.jpg'
import payra_port_image_1 from '../Assets/Images/Projects/payra-port-image-1.jpg'
import payra_port_image_2 from '../Assets/Images/Projects/payra-port-image-2.jpg'
import payra_port_desc from '../Assets/Images/Projects/payra-port-desc.jpg'
import payra_port_outcome from '../Assets/Images/Projects/payra-port-outcome.jpg'


import aerial_mapping_1_thumb from '../Assets/Images/Projects/aerial-mapping-1-thumb.png'

import aerial_mapping_2_thumb from '../Assets/Images/Projects/aerial-mapping-2-thumb.png'
import aerial_mapping_2_cover from '../Assets/Images/Projects/aerial-mapping-2-cover.jpg'
import aerial_mapping_2_desc from '../Assets/Images/Projects/aerial-mapping-2-desc.jpg'
import aerial_mapping_2_image_1 from '../Assets/Images/Projects/aerial-mapping-2-image-1.png'
import aerial_mapping_2_image_2 from '../Assets/Images/Projects/aerial-mapping-2-image-2.jpg'


import aerial_mapping_3_thumb from '../Assets/Images/Projects/aerial-mapping-3-thumb.png'
import aerial_mapping_3_desc from '../Assets/Images/Projects/aerial-mapping-3-desc.jpg'
import aerial_mapping_3_cover from '../Assets/Images/Projects/aerial-mapping-3-cover.jpg'
import aerial_mapping_3_image_1 from '../Assets/Images/Projects/aerial-mapping-3-image-1.jpg'


import aerial_mapping_4_thumb from '../Assets/Images/Projects/11.aerial-sundarganj.png'
import aerial_mapping_4_cover from '../Assets/Images/Projects/aerial-mapping-4-cover.jpg'
import aerial_mapping_4_desc from '../Assets/Images/Projects/aerial-mapping-4-desc.jpg'
import aerial_mapping_4_image_1 from '../Assets/Images/Projects/aerial-mapping-4-image-1.jpg'



import topo_1_thumb from '../Assets/Images/Projects/topo-1-thumb.png'
import topo_1_cover from '../Assets/Images/Projects/topo-1-cover.jpg'
import topo_1_desc from '../Assets/Images/Projects/topo-1-desc.jpg'
import topo_1_image_1 from '../Assets/Images/Projects/topo-1-image-1.jpg'
import topo_1_image_2 from '../Assets/Images/Projects/topo-1-image-2.jpg'
import topo_1_outcome from '../Assets/Images/Projects/topo-1-outcome.jpg'

import rl_1 from '../Assets/Images/Projects/rl-1-thumb.png'
import rl_1_cover from '../Assets/Images/Projects/rl-1-cover.jpg'
import rl_1_image_1 from '../Assets/Images/Projects/rl-1-image-1.jpg'
import rl_1_desc from '../Assets/Images/Projects/rl-1-desc.jpg'


import socio_1_thumb from '../Assets/Images/Projects/socio-1-thumb.png'
import socio_1_cover from '../Assets/Images/Projects/socio-1-cover.jpg'
import socio_1_desc from '../Assets/Images/Projects/socio-1-desc.jpg'
import socio_1_meth from '../Assets/Images/Projects/socio-1-meth.jpg'
import socio_1_outcome from '../Assets/Images/Projects/socio-1-outcome.jpg'

import socio_2_thumb from '../Assets/Images/Projects/socio-2-thumb.png'
import socio_2_cover from '../Assets/Images/Projects/socio-2-cover.jpg'
import socio_2_desc from '../Assets/Images/Projects/socio-2-desc.jpg'
import socio_2_meth from '../Assets/Images/Projects/socio-2-meth.jpg'
import socio_2_outcome from '../Assets/Images/Projects/socio-2-outcome.jpg'

import nsd_thumb_2 from '../Assets/Images/Projects/scoping-sanboard-thumb.jpg'
import rangpur_sylhet_physical_thumb from '../Assets/Images/Projects/RangpurSylhet-physical-thumb.jpg.jpg'
import rangpur_sylhet_transport_thumb from '../Assets/Images/Projects/RangpurSylhet-transport-thumb.jpg'
import nine_up_thumb from '../Assets/Images/Projects/9up-thumb.jpg'
import song_thumb from '../Assets/Images/Projects/SoNG-thumb.jpg'
import tvet_thumb from '../Assets/Images/Projects/TVET-thumb.jpg'
import TVET from '../Components/ProjectLayouts/TVET'
import SanboardScoping from '../Components/ProjectLayouts/SanboardScoping'
import SoNG from '../Components/ProjectLayouts/SONG'
import RSPFS2 from '../Components/ProjectLayouts/RangpurSylhetTransportSurvey'
import RangpurSylhetPhysicalFeatureSurvey from '../Components/ProjectLayouts/RangpurSylhetPhysicalFeatureSurvey'
import ComprehensivePlanNineUpazila from '../Components/ProjectLayouts/ComprehensivePlanNineUpazila'



const dummy_placeholder_text = `Lorem ipsum dolor sit amet, 
consectetur adipiscing elit, sed do eiusmod tempor incididunt 
ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis
nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
 consequat. Duis aute irure dolor in reprehenderit in 
 voluptate velit esse cillum dolore eu fugiat nulla pariatur.
  Excepteur sint occaecat cupidatat non proident, sunt in
   culpa qui officia deserunt mollit anim id est laborum`



const photoObjectFormatter = (photo, caption = null, source = null) => {

    return {
        photo: photo,
        caption: caption,
        source: source
    }
}



export const sectors = [
    {
        id: 1, name: 'Development Plan, Climate Change & DRR', projects: [
            {
                project_id: 1,
                order: 1,
                short_name: 'Chattogram Metropolitan Master Plan',
                location: 'Chattogram',
                thumb_image: cda_thumb,
            }, {
                project_id: 2,
                order: 2,
                short_name: 'Climate Resilient Inclusive Smart Cities (CRISC)',
                location: 'Sirajganj & Satkhira',
                thumb_image: crisc_thumb,
            }
            , {
                project_id: 3,
                order: 3,
                short_name: 'Payra-Kuakata Comprehensive Plan',
                location: 'Patuakhali & Barguna',
                thumb_image: pkcp_thumb,
            }
            , {
                project_id: 4,
                order: 4,
                short_name: 'Land Use Change Detection and Preparation of Revised Urban Area Plan for Mymensingh City Corporation',
                location: 'Mymensingh',
                thumb_image: land_use_thumb,
            },
            {
                project_id: 5, order: 5,
                short_name: 'Mirsharai Upazilla Development Plan(MUDP)',
                location: 'Mirsharai, Chittagong',
                thumb_image: mudp_thumb
            },
            {
                project_id: 6, order: 6,
                short_name: 'Earthquake Response Plan for Mymensingh City Corporation',
                location: 'Mymensingh',
                thumb_image: earth_thumb
            },
            {
                project_id: 23, order: 7,
                short_name: `A Pilot Project on Preparation of Comprehensive Development Plan for Nine Upazilas
                Package 01 (Satellite Image Processing and Geo-rand Geo-referencing, Physical Feature, Landuse Topographic Surveys)`,
                location: '',
                thumb_image: nine_up_thumb,
                /* status: 'dev', */

            }
        ]
    },
    {
        id: 2, name: 'Software Development', projects: [
            {
                project_id: 7,
                order: 1,
                short_name: 'Planning information System and Climate Check Method',
                location: 'Bangladesh',
                thumb_image: plis_thumb,
            }, {
                project_id: 8,
                order: 2,
                short_name: 'National Sanitation Dashboard',
                location: 'Bangladesh',
                thumb_image: nsd_thumb,
            },
            {
                project_id: 24,
                order: 3,
                short_name: `Scoping Study of
                The National Sanitation Dashboard`,
                location: 'Bangladesh',
                thumb_image: nsd_thumb_2,
                /*  status:'dev', */

            },

        ]
    },
    {
        id: 3, name: 'Feasibility Study & Infrastructure Design', projects: [{
            project_id: 9,
            order: 1,
            short_name: 'Feasibility for Implementing IT & GIS-Based Smart Solid Waste & Faecal Sludge Management Systems',
            location: '53 District Paurashavas  & 8 City Corporations.',
            thumb_image: feasibility_1_thumb,
        }, {
            project_id: 10,
            order: 2,
            short_name: 'Technical Design of Integrated Waste Treatment Plants, Faridpur & Satkhira Paurashava',
            location: 'Faridpur & Shatkhira Municipality ',
            thumb_image: feasibility_2_thumb,
        }, {
            project_id: 21,
            order: 3,
            short_name: `Preparation of Risk
            Sensitive Database For Core Area of Rangpur and Sylhet District Town
            (Transportation Surveys for Roadways and Waterways)`,
            location: 'Rangpur and Sylhet ',
            thumb_image: rangpur_sylhet_transport_thumb,


        }, {
            project_id: 24,
            order: 4,
            short_name: `Scoping Study of
            The National Sanitation Dashboard`,
            location: 'Bangladesh',
            thumb_image: nsd_thumb_2,
            /*  status:'dev', */

        },
        ]
    },
    {
        id: 4, name: 'Aerial Survey & GIS Mapping',
        projects: [{
            project_id: 11,
            order: 1,
            short_name: 'Aerial Survey & GIS Mapping for Payra Port',
            location: 'Payra Port',
            thumb_image: payra_port_thumb,
        }, {
            project_id: 12,
            order: 2,
            short_name: 'Aerial Mapping for Seven Municipalities.',
            location: 'Barguna & Patuakhali',
            thumb_image: aerial_mapping_1_thumb,
        }, {
            project_id: 13,
            order: 3,
            short_name: 'Aerial Survey of Padma and Jamuna River Embankment',
            location: 'Padma & Jamuna River',
            thumb_image: aerial_mapping_2_thumb,
        }, {
            project_id: 14,
            order: 4,
            short_name: 'Aerial Survey of 10 MW Solar Power Plant',
            location: 'Sundarganj , Gaibandha',
            thumb_image: aerial_mapping_3_thumb,
        }, {
            project_id: 15,
            order: 5,
            short_name: 'Topographic Survey of water supply network at Mirersharai Economic Zone',
            location: 'Mirersharai',
            thumb_image: topo_1_thumb,
        }, {
            project_id: 16,
            order: 6,
            short_name: 'RL Survey of Installed Observation well at 383 unions in 97 Upazilla of 33 District',
            location: '97 Upazilla of 33 District',
            thumb_image: rl_1,
        },
        {
            project_id: 22,
            order: 7,
            short_name: `Preparation of Risk Sensitive Database for Core Area of Rangpur and Sylhet District Town
            (Physical Feature Survey Other Survey)`,
            location: 'Rangpur and Sylhet',
            thumb_image: rangpur_sylhet_physical_thumb,
            /*  status: 'dev', */

        }]
    },
    {
        id: 5, name: 'Socio-Economic Investigation',
        projects: [{
            project_id: 17,
            order: 1,
            short_name: 'Socio-Economic and other Related Survey for Payra-Kuakata Comprehensive Plan - Package 02 & Package 03',
            location: 'Payra-Kuakata',
            thumb_image: socio_1_thumb,
        }, {
            project_id: 18,
            order: 2,
            short_name: 'Socio-Economic and other Survey for Mirsharai Upazila',
            location: 'Mirsharai Upazila',
            thumb_image: socio_2_thumb,
        }, {
            project_id: 20,
            order: 3,
            short_name: `Baseline study on the socioeconic status of coastal fishing, communities, and their local 
            fishing practices and techniques, as wall as knowledge of the sundarbans and SoNG marine protected area`,
            location: '',
            thumb_image: song_thumb,


        },
        {
            project_id: 19,
            order: 2,
            short_name: `Job placement and business mentoring support services for skills training graduates in five partner cities`,
            location: 'Khulna, Satkhira, Sirajganj, Rajshahi, Barisal',
            thumb_image: tvet_thumb,

        },]
    },

]

export const projects = [

    {
        id: 1,
        title: 'Preparation of Chattogram Metropolitan Master Plan (Period: 2020-2041)',
        location: 'Chattogram',
        area: 'Chattogram 1152 SQ. KM.',
        client: "CDA",
        funded_by: 'GoB',
        rendered_service: 'Development/ Strategic Planning',
        content: [
            {
                name: 'Project Description',
                type: 'right-text',
                data: [`Chattogram Structure Plan (1995-2015) and the Chattogram Urban Development Plan 
                (1995-2008) were prepared in 1995 under the Project “Preparation of Structure Plan (SP), Master Plan and Detailed Area Plan
                (DAP) – Metropolitan Development Plan Preparation and Management in Chattogram” As the validity of Structure Plan (1995-2015)
                will come to an end by 2019, CDA needs a new Structure Plan (2020-2041) to control the development of Chattogram City as well 
                as the review and updating of other components of that Master Plan. For this, CDA has started the project named “Preparation of 
                Chattogram Metropolitan Master Plan (2020-2041)”. The project is proposed to be fully funded by GoB.`],

                order: 1,
                photo_item: [photoObjectFormatter(cda_desc, "", ''),]
            },
            {
                name: 'Key points of Methodology',
                type: 'left-multi-text',
                text_type: 'bullet-points',
                order: 2,
                photo_item: [photoObjectFormatter(cda_outcome, "", '')],
                data: [{
                    name: 'Key points of Methodology',
                    content: ['Base map preparation though UAV images', 'Household survey, KII, FGD, PRA, Transport Survey',
                        'Risk & Vulnerability Studies', 'Collection Secondary Information'],
                }, {
                    name: 'Key points of Outcome',
                    content: [
                        'CDA will be enriched enough having tolls for development proposals as well as existing database to forecast future needs of Chattogram Statistical Metropolitan Area.',
                        'The reordering of priorities for environmental improvement and more equitable targeting of beneficiaries for new development schemes will directly improve public health and the general living conditions for the urban poor currently occupying un-serviced slums and squatter settlements.',
                        'The governmental or non-governmental organizations functioning in Chattogram will be the indirect beneficiaries through the development policy guidelines depicted in the master plan for them.',
                    ],
                }]
            },
        ],

        related_projects: [2, 3, 4],
        cover_image: cda_cover,
        thumb_image: cda_thumb,
        desc_image: cda_desc,
        outcome_image: cda_outcome

    },
    {
        id: 2,
        location: 'Sirajganj & Satkhira',
        title: 'Climate Resilient Inclusive Smart Cities (CRISC)',
        area: '60 sqkm',
        client: "GIZ",
        funded_by: 'GIZ Humburg',
        rendered_service: 'Development/ Strategic Planning',
        content: [
            {
                name: 'Project Description',
                type: 'right-text',

                order: 1,
                data: [`By 2050, the urban population is expected to double to approximately 112 million people. Climate change is likely to be a
                significant factor driving migration to cities. Bangladesh is expected to experience a 1.6°C temperature rise and an 8% increase in precipitation
                 by 2050, resulting in the loss of more than 10% of the country’s territory and internal migration of 10 to 30 million people. The consequences
                  have a negative impact on people’s livelihoods, particularly the poorest and those living in squatter camps. However, in many cases, such
                   as in our pilot cities Sirajgonj and Satkhira, decision-makers in city administrations are only marginally aware of the risks of climate
                    change in urban areas. Furthermore, local governments in Bangladesh are underfunded and have limited capacity, with many urban areas 
                    playing only a minor role in the active planning process.`,
                    `As a result, the climate resilient inclusive smart cities project (CRISC) assists local and national decision-makers who 
               are unable to plan for urban development and investments in a climate-sensitive manner while also taking into account the needs 
               of vulnerable populations. As a result, the main issue is adaptation, which refers to the process of adjusting to current or expected
                text_type:'bullet-points',climate and its effects in
                 order to mitigate or avoid, harm or exploit advantageous opportunities.`],
                photo_item: [photoObjectFormatter(crisc_desc,)]
            },
            {
                name: 'Key points of Methodology',
                type: 'left-multi-text',
                text_type: 'bullet-points',
                order: 2,
                data: [
                    {
                        name: 'Key points of Methodology',
                        content: ['Base map preparation though UAV images', 'Household survey, KII, FGD, PRA',
                            'Secondary climatic data collection', 'Assessing economics of climate change'],
                    },
                    {
                        name: 'Key points of Outcome',
                        content: [
                            'The consideration of climate risks has been piloted in the urban development planning',
                            'Urban development investments are planned in four cities that area climate-resilient, gender-sensitive and inclusive manner',
                            'Dialogue progress and knowledge exchange for the promotion of climate resilience in cities are improved'],
                    }
                ],
                photo_item: [photoObjectFormatter(crisc_outcome)]

            }
        ],

        related_projects: [1, 3, 4],
        cover_image: crisc_cover,



    },
    {
        id: 3,
        title: 'Preparation of Payra-Kuakata Comprehensive Plan Focusing on Eco-Tourism (PKCP)- Package 02 & Package 03',
        location: 'Patuakhali & Barguna',
        area: '2000 sqkm',
        client: "UDD",
        funded_by: 'GOB',
        rendered_service: 'Development/ Strategic Planning',
        content: [
            {
                name: 'Project Description',
                type: 'right-text',
                data: [`Bangladesh government has planned to develop third deep seaport at Kalapara upazila 
                that could play an instrumental role in Bangladesh’s economy and it’s also likely to generate enormous private
                 investments. In addition, the proposed deep seaport in Kalapara would attract many new secondary and tertiary
                  activities, that would have beneficial influence as well as negative effect. This massive development could
                   disrupt the region’s land use pattern, leading to haphazard urbanization.`,
                    `That’s why UDD has undertaken PKCP project to prepare a comprehensive regional development plan considering 
                    the Kuakata sea beach region as a part of entire Kalapara, Galachipa, Rangabali, Amtali, Taltali, Patharghata,
                     and Barguna Sadar Upazilas. The Package-02 covers Amtali and Taltali Upazila under Barguna District and Kalapara 
                     Upazila under Patuakhali District. This development plan package is essential to predict and guide such probable
                      changes in existing land use patterns and to accommodate such a massive establishment and its related changes. 
                    The proposed initiative would also address the challenges of climate change and identify suitable adaptive solutions.`],

                order: 1,
                photo_item: [photoObjectFormatter(pkcp_desc)],
            }, {
                name: 'Key points of Methodology',
                type: 'left-multi-text',
                text_type: 'bullet-points',
                order: 2,
                data: [{
                    name: 'Key points of Methodology',
                    content: ['Base map preparation though satellite images and UAV images', 'Photogrammetry technique is used for capturing 3D features and DTM',
                        'Apps were used to capture attribute and landuse information', 'A digital dashboard was developed for monitoring and quality control'],
                }, {
                    name: 'Key points of Outcome',
                    content: [
                        'Geodatabase of Physical feature, landuse and DEM was prepared',
                        'Spatial distribution of different services and facilities of the area.',
                        'Relationship of built-up area concentration and population density was identified.',
                        ' Deprived area, overserved area was identified for different government services.',
                        'Climate change hotspots/ cold spot identification.',
                        'Identification of vulnerable zone, exposed area and their losses for natural disasters.',
                        'Guiding future development by policy and strategies.',
                    ]
                }],
                photo_item: [photoObjectFormatter(pkcp_outcome)]
            }, {

                type: 'single-photo',
                text_type: 'bullet-points',
                order: 3,
                photo_item: [photoObjectFormatter(pkcp_bottom)],
            }
        ],
        related_projects: [1, 2],
        cover_image: pkcp_cover,
        thumb_image: pkcp_thumb,



    },

    {
        id: 4,
        title: `Land Use Change Detection and Prepare Urban Area Plan through Geographic Information System and Remote
         Sensing after Declaring the City Corporation Boundary and Executing MSDP in Mymensingh`,
        location: 'Mymensingh',
        area: '23000 acres',
        client: "UDD",
        funded_by: 'GOB',
        rendered_service: 'Development/ Strategic Planning',
        content: [
            {
                name: 'Project Description',
                type: 'right-text',
                data: [`Bangladesh government designated Mymensingh as the country’s twelfth City Corporation on April 2,
                2018, with a land size of 91.35 square kilometers and a population of 4,18,058. With the districts of Jamalpur, Sherpur,
                Netrakona, and Mymensingh, Mymensingh became the country’s eighth division in September 2015. However, before getting
                these two administrative upgradations Mymensingh was considered as a place of planning interventions by Urban 
                Development Directorate and a strategic development planning project had taken in 2011 named as Mymensingh 
                Strategic Development Plan (MSDP). The project covered area within MSDP which might have potential for development
                within the next 20 years (2011-2031)`,
                    `This project has been considered as the paradigm shift in development planning in Bangladesh because of integrating
                    disaster risk reduction issues in land use planning. However, the main goal of any development plan is to
                    bring about desired changes by putting planning proposals into action in the designated area. Considering 
                    the purpose of MSDP, it is pertinent to evaluate the desire change in land use because five years executing 
                    time have passed since the final plan was completed. In addition, this research is very much helpful to
                    detect various changes occurring in different classes of land use such as increase in urban built-up area 
                                        or decrease in agricultural land and so on.`],

                order: 1,
                photo_item: [photoObjectFormatter(land_use_desc)],
            },
            {
                name: 'Key points of Methodology',
                type: 'left-multi-text',
                data: [{
                    name: 'Key points of Methodology', content: ['Base map preparation through satellite images and UAV images',
                        'Photogrammetry technique is used for capturing 3D features and DTM',
                        'Apps were used to capture attribute and landuse information',
                        'A digital dashboard was developed for monitoring and quality control',
                        'Household Survey, KII, FGD were conducted',
                        'Traffic volume survey, Geological survey was conducted']
                },
                {
                    name: 'Key points of Outcome', content: [
                        'Landuse change detection and future forecast',
                        'Traffic demand forecast and simulation model',
                        'Land Supply and Demand for urban and community scale',
                        'Pattern of Infrastructure distribution',
                    ],
                }
                ],
                text_type: 'bullet-points',
                order: 2,
                photo_item: [photoObjectFormatter(land_use_outcome)],
            },

        ],

        related_projects: [2, 3, 1],
        cover_image: land_use_cover,
        desc_image: land_use_desc,
        outcome_image: land_use_outcome,
    },
    {
        id: 5,
        title: `Preparation of Development Plan for Mirsharai Upazilla,
         Chittagong District: Risk Sensitive Land Use Plan
          (MUDP)-Package 01`,
        location: 'Mirsharai, Chittagong',
        area: '509 sqkm',
        client: "UDD",
        funded_by: 'GOB',
        rendered_service: 'Development/ Strategic Planning',
        content: [
            {
                name: 'Project Description',
                type: 'right-text',
                data: [`Mirsharai sea beach, hilly area, Mohamaya Chara Lake and Khaiya Chara region has the greater potential for
                 tourism development as there are abundant resources 
                 to attract tourists. Bangladesh can earn money in local
                  and also in foreign exchange by opening a tourist resort 
                  at Mirsharai. The place would become an excellent holiday
                   resort and a tourist center if properly developed. Rowing facility could be arranged easily; 
                   fishing and hunting facilities were already there.
                    But, Mirsharai was developing in an unplanned and
                     haphazard manner very rapidly due to the ample
                      opportunity for tourism development, which acted
                       as pull factor for private sector developers. Moreover,
                        the proposed Special Economic Zone would generate many
                         industries related new activities including huge vehicular
                          traffic such as air, rail, road and water. This phenomenon would have both 
                          positive and negative impact on the existing land use pattern of the region.
                           Hence, this project has been under taken to guide such probable
                            changes on the existing land use pattern and to protect the region
                             from depletion of its natural resources and character and tourism
                              development as well.`],

                order: 1,
                photo_item: [photoObjectFormatter(mudp_desc)],
            },
            {
                name: 'Key points of Methodology',
                type: 'text-horizontal',
                data: [{
                    name: 'Key points of Methodology',
                    content: ['Base map preparation though satellite images and UAV images',
                        'Photogrammetry technique is used for capturing 3D features and DTM',
                        'Apps were used to capture attribute and landuse information',
                        'A digital dashboard was developed for monitoring and quality control']
                },
                {
                    name: 'Key points of Outcome',
                    content: [
                        'Geodatabase of Physical feature, landuse and DEM was prepared',
                        'Spatial distribution of different services and facilities of the area',
                        'Relationship of built-up area concentration and population density was identified',
                        'Deprived area, overserved area was identified for different government services',
                    ],
                }
                ],
                text_type: 'bullet-points',
                order: 3,
                // photo_item: [photoObjectFormatter(mudp_image_1],
            },
            {
                text_type: 'bullet-points', type: 'only-photo',
                order: 2,
                photo_item: [photoObjectFormatter(mudp_image_1), photoObjectFormatter(mudp_image_2)],
            }

        ],

        related_projects: [2, 3, 1],
        cover_image: mudp_cover,
    },
    {
        id: 6,
        title: `
        Prepare Ward and City Level GIS Map and Earthquake Response Plan for Mymensingh Municipality under Urban Resilience Bangladesh Project.`,
        location: 'Mymensingh',
        area: '5000 acres',
        client: "NGO Forum, Mymensingh Municipality ​",
        funded_by: 'Oxfam BD',
        rendered_service: 'Risk Sensitive Plan',
        content: [
            {
                name: 'Project Description',
                type: 'right-text',
                data: [`Mymensingh City Corporation is highly vulnerable to earthquake because of its close proximity to Madhupur Fault and Dauki Fault. In the generalized tectonic map of Bangladesh, Mymensingh is also located in the high-risk zone. In these circumstances, Earthquake Response Plan preparation for Mymensingh Municipality was undertaken as part of the Urban Resilience Bangladesh Project to enable improved earthquake response. The project’s main goal was to create an Earthquake Response Plan that included community engagement, rescue plan preparation, and list of potential shelters for displaced people during earthquake hazard. Besides, ward and city level GIS maps were created to assist in strategic planning, resource utilization management and paurashava level disaster management planning.`],

                order: 1,
                photo_item: [photoObjectFormatter(earth_desc)],
            },
            {
                type: 'only-photo',
                photo_item: [photoObjectFormatter(earth_image_1)],
                text_type: 'bullet-points',
                order: 2,
            },
            {
                name: 'Key points of Methodology',
                type: 'left-multi-text',
                data: [{
                    name: 'Key points of Methodology',
                    content: ['Base map collected from UDD and verified and updated by filed work',
                        'Data collected from secondary sources',
                        'PRA, FGD was carried out']
                },
                {
                    name: 'Key points of Outcome',
                    content: [
                        'Ward level response map',
                        'City level reponse map',
                        'Response plan preparation'
                    ],
                }
                ],
                photo_item: [photoObjectFormatter(earth_outcome)],
                text_type: 'bullet-points',
                order: 3,
                // photo_item: [photoObjectFormatter(mudp_image_1],
            },


        ],

        related_projects: [2, 3, 1],
        cover_image: earth_cover,
    },
    {
        id: 7,
        title: `Adaptation to Climate Change into the National and Local Development Planning II`,
        location: 'Bangladesh',

        client: "Bangladesh Planning Commission​",
        funded_by: 'GIZ Humburg',
        rendered_service: 'Design and Develop Web application',
        content: [
            {
                name: 'Project Description',
                type: 'left-text',
                data: [`Bangladesh, in the delta of the Padma, Jamuna-Brahmaputra, and Meghna rivers, is the eighth-most populous country in the world and one of the most densely populated. Bangladesh is developing despite its large population and climate change vulnerability (157 of 182 in the ND GAIN Vulnerability Index). However, if climate science forecasts are correct, Bangladesh could lose more than one-tenth of its territory and up to 10-30 million people if the Bay of Bengal sea level rises 30 cm to 45 cm by 2050.`],

                order: 1,
                photo_item: [photoObjectFormatter(plis_desc)],
            },
            {
                type: 'text-only',
                data: ['Bangladesh, in the delta of the Padma, Jamuna-Brahmaputra, and Meghna rivers, is the eighth-most populous country in the world and one of the most densely populated. Bangladesh is developing despite its large population and climate change vulnerability (157 of 182 in the ND GAIN Vulnerability Index). However, if climate science forecasts are correct, Bangladesh could lose more than one-tenth of its territory and up to 10-30 million people if the Bay of Bengal sea level rises 30 cm to 45 cm by 2050. Resilient and comprehensive planning must be heavily invested in to sustain inclusive growth and poverty eradication. To overcome these issues and encourage the efficient allocation of scarce investment resources to risky sectors and locations, national and local measures are needed. Systematize climate adaption measures. Public investments seldom consider climate resilience. The Government of Bangladesh and Germany prioritize “Climate Change Adaptation in Urban Areas (Regional Concentration)” through the ACCNLDP II initiative. The project activities, executing agency, and supporting ministries complement other development cooperation initiatives in the focus region. It will work with other German government-supported projects like “Improved Coordination of International Climate Finance” (ICICF), “National Adaptation Plan and Nationally Determined Contribution Support” (NAP-NDC), and “Climate Resilient and Inclusive Smart Cities” (CRISC) to produce results.'],
                text_type: 'bullet-points',
                order: 2,
            },
            {
                name: 'Key points of Methodology',
                type: 'left-text',
                data: ['Prepare and implement methodology to identify in detail the process/data/technological gaps in the existing system.',
                    'Base map preparation through collecting spatial data from Government agencies.',
                    'Create an operational system, customize/develop, implement the systems, and develop capacity of the users across the Planning Commission as per the requirement specification.']
                , photo_item: [photoObjectFormatter(plis_meth)],
                text_type: 'bullet-points',
                order: 3,

            }, {
                name: 'Key Points of Outcome',
                type: 'left-text',
                data: ['Climate risk information are available for the national planning processes of the Bangladesh Planning Commission.',
                    'The preconditions for the examination of climate risks in the context of project appraisal by Planning Commission are improved.',
                    'Capacities of considering climate risks in planning and appraisal processes are strengthened.']
                , photo_item: [photoObjectFormatter(plis_outcome)],
                text_type: 'bullet-points',
                order: 4,

            },
            {

                type: 'only-photo',
                photo_item: [photoObjectFormatter(plis_image_2)],
                text_type: 'bullet-points',
                order: 5,

            },


        ],
        system_frame: <iframe src="https://tillerbd.com" 
            className='w-full border-[#f2f2f2]  h-[90vh] max-h-screen  px-4 pt-4 lg:p-20 bg-inherit bg-second'
            name="plis-iframe"
            frameborder="1" marginheight="0px" marginwidth="0px"
            allowfullscreen>
        </iframe>,
        related_projects: [2, 3, 1],
        cover_image: plis_cover,
    }, {
        id: 8,
        title: `National Sanitation Dashboard`,
        location: 'Bangladesh',
        area: 'Bangladesh',
        client: "a2i, DPHE, ITN-BUET, LGD, DNCC, WASA​",
        funded_by: 'BMGF',
        rendered_service: 'Public Data System, Dashboard Design and Development',
        content: [
            {
                name: 'Project Description',
                type: 'right-text',
                data: [`Bangladesh has made remarkable progress in eliminating open defecation. This achievement has spawned ‘second-generation’ sanitation challenges. Rapid urbanization in Bangladesh is degrading the urban environment, public health, and putting additional strain on the country’s already inadequate basic urban service infrastructure. Urban waste management services including fecal sludge management and solid waste management are deficient in Bangladesh, producing environmental degradation and public health risks.`,
                    `To address these growing concerns, particularly for the cities and large towns where the problem is acute, the Government of Bangladesh has taken up a Technical Project Proposal (TPP) named, “Feasibility for Implementation of Solid Waste and Fecal Sludge Management System in 53 district Paurashavas and 8 City Corporations”. The integration of FSM with SWM into one package will improve system planning, implementation, operation, and maintenance. Furthermore, the study’s findings will serve as a foundation for the government and development partners to develop investment projects and long-term plans for operating and maintaining the systems.`],

                order: 1,
                photo_item: [photoObjectFormatter(nsd_desc)],
            },
            {
                type: 'only-photo',
                photo_item: [photoObjectFormatter(nsd_image_1)],
                text_type: 'bullet-points',
                order: 2,
            },
            {
                name: 'Key points of Methodology',
                type: 'left-multi-text',
                data: [{
                    name: 'Key points of Methodology',
                    content: [
                        'Sample Size Delineation & Questionnaire Design',
                        'Apps were used to capture household survey and city profile',
                        'Household Survey, KII, FGD were conducted',
                        'Real - time survey monitoring and five stage cross checking method and data validation'
                    ]
                }, {
                    name: 'Key Points of Outcome',
                    content: ['Web-GIS empowered Dashboard',
                        'Shit flow diagram and waste lifecycle diagram',
                        'Situation analysis of solid waste and fecal sludge',
                        'Forecasting future solid waste and fecal sludge generation',
                        'Institutional capacity building guideline and framework',
                        'Design of sewerage network and treatment plant',
                        'Preparation of Investment plan',
                        'Final feasibility study report']
                },
                ],
                text_type: 'bullet-points',
                order: 3,
                photo_item: [photoObjectFormatter(nsd_outcome)]

            },
            {

                type: 'only-photo',
                photo_item: [photoObjectFormatter(nsd_image_2)],
                text_type: 'bullet-points',
                order: 4,

            },


        ],

        related_projects: [2, 3, 1],
        cover_image: nsd_cover,
    },
    {
        id: 9,
        title: `Feasibility for Implementing IT & GIS-Based Smart Solid Waste & Faecal Sludge Management Systems In 53 District Level Paurashavas & 8 City Corporations`,
        location: '53 District Paurashavas  & 8 City Corporations.',
        area: '61 Urban Areas',
        client: "DPHE​",
        funded_by: 'BMGF',
        rendered_service: 'Feasibility Analysis',
        content: [
            {
                name: 'Project Description',
                type: 'right-text',
                data: [`Bangladesh has made remarkable progress in eliminating open defecation. This achievement has spawned ‘second-generation’ sanitation challenges. Rapid urbanization in Bangladesh is degrading the urban environment, public health, and putting additional strain on the country’s already inadequate basic urban service infrastructure. Urban waste management services including fecal sludge management and solid waste management are deficient in Bangladesh, producing environmental degradation and public health risks.`,
                    `To address these growing concerns, particularly for the cities and large towns where the problem is acute, the Government of Bangladesh has taken up a Technical Project Proposal (TPP) named, “Feasibility for Implementation of Solid Waste and Fecal Sludge Management System in 53 district Paurashavas and 8 City Corporations”. The integration of FSM with SWM into one package will improve system planning, implementation, operation, and maintenance. Furthermore, the study’s findings will serve as a foundation for the government and development partners to develop investment projects and long-term plans for operating and maintaining the systems.`],

                order: 1,
                photo_item: [photoObjectFormatter(nsd_desc)],
            },
            {
                type: 'only-photo',
                photo_item: [photoObjectFormatter(nsd_image_1)],
                text_type: 'bullet-points',
                order: 2,
            },
            {
                name: 'Key points of Methodology',
                type: 'left-multi-text',
                data: [{
                    name: 'Key points of Methodology',
                    content: [
                        'Sample Size Delineation & Questionnaire Design',
                        'Apps were used to capture household survey and city profile',
                        'Household Survey, KII, FGD were conducted',
                        'Real - time survey monitoring and five stage cross checking method and data validation'
                    ]
                }, {
                    name: 'Key Points of Outcome',
                    content: ['Web-GIS empowered Dashboard',
                        'Shit flow diagram and waste lifecycle diagram',
                        'Situation analysis of solid waste and fecal sludge',
                        'Forecasting future solid waste and fecal sludge generation',
                        'Institutional capacity building guideline and framework',
                        'Design of sewerage network and treatment plant',
                        'Preparation of Investment plan',
                        'Final feasibility study report']
                },
                ],
                text_type: 'bullet-points',
                order: 3,
                photo_item: [photoObjectFormatter(nsd_outcome)]

            },
            {

                type: 'only-photo',
                photo_item: [photoObjectFormatter(nsd_image_2)],
                text_type: 'bullet-points',
                order: 4,

            },


        ],

        related_projects: [2, 3, 1],
        cover_image: nsd_cover,
    },
    {
        id: 10,
        title: `Technical Design of Integrated Waste Treatment Plants, Faridpur & Satkhira Paurashava`,
        location: 'Faridpur & Shatkhira Municipality',
        area: null,
        client: "Practical Action Bangladesh",
        funded_by: 'GOB',
        rendered_service: 'Technical/ Infrastructure Design',
        content: [
            {
                name: 'Project Description',
                type: 'right-text',
                data: [`In Bangladesh, most households have latrines, but many are unhygienic, and most of the fecal sludge end up untreated in drains, waterways, canals, and low-lying lands. This poses a huge environmental risk, resulting in waterborne disease outbreaks and bacterial infections in humans and animals. Local authorities such as the municipalities are mandated for addressing solid waste management of the towns, which they hardly perform with their limited resources such as manpower, equipment and finance. Cities are therefore encouraged to adopt the government’s policy of Integrated Solid Waste Management and 3Rs (Reduce, Reuse and Recycle) Strategy. This approach emphasizes waste prevention, reduction, and recycling, rather than treating and disposing of ever-increasing trash. That’s why Practical Action has always been encouraged in developing resource recovery system from the waste management works. Besides, PA has been working with LGIs and city people to improve an integrated system through capacity building, awareness raising, and system transformation. The main purpose of the project is to support Faridpur and Satkhira municipalities for effective land use planning, technical design of physical facilities for recycling and resource recovery from multiple wastes including organic solid waste, fecal sludge, polythene, medical and electronic waste.`],

                order: 1,
                photo_item: [photoObjectFormatter(feasibility_2_desc)],
            },

            {
                name: 'Key points of Methodology',
                type: 'left-multi-text',
                data: [{
                    name: 'Key points of Methodology',
                    content: [
                        'Sample Size Delineation & Questionnaire Design',
                        'Household survey, KII, FGD to quantify collectable fecal sludge and solid waste']
                }, {
                    name: 'Key Points of Outcome',
                    content: ['Population forecast to serve the treatment plant for 20 years',
                        'Technical Design and Drawing, land use plan of Integrated Treatment Plants (Co-composting)',
                        'Cost estimation and SOP for CRTC',
                        'Feasibility study report']
                },
                ],
                text_type: 'bullet-points',
                order: 2,
                photo_item: [photoObjectFormatter(feasibility_2_outcome)]

            },



        ],

        related_projects: [2, 3, 1],
        cover_image: nsd_cover,
    },
    {
        id: 11,
        title: 'Preparation of GIS Map for Payra Port Authority',
        location: 'Payra Port',
        area: '7000 acres',
        client: "Payra Port authority",
        funded_by: 'GOB',
        rendered_service: 'GIS / Aerial Mapping',
        content: [
            {
                name: 'Project Description',
                type: 'right-text',
                data: [`Payra sea port development is a high-priority infrastructure megaproject of Bangladesh with the potential to expedite the country’s economic growth. This infrastructure development project includes land acquisition and resettlement of impacted households with adequate compensation. For this project, conducting a local level survey proved challenging and time intensive. That’s why Aerial mapping of the project area was required to improve the accuracy of this acquisition and compensation procedure. Furthermore, the updated geodatabase with calculation will serve as a foundation for the resettlement plan of Payra port area.`],

                order: 1,
                photo_item: [photoObjectFormatter(payra_port_desc)],
            },
            {

                type: 'only-photo',
                text_type: 'bullet-points',
                order: 2,
                photo_item: [photoObjectFormatter(payra_port_image_1), photoObjectFormatter(payra_port_image_2)]
            },
            {
                name: 'Key points of Methodology',
                type: 'left-multi-text',

                data: [{
                    name: 'Key points of Methodology',
                    content: [
                        'Flight and GCP plan preparation',
                        'Image capture and GCP measurement',
                        'Areal triangulation',
                        'Stereo model preparation']
                }, {
                    name: 'Key Points of Outcome',
                    content: ['3D feature, DTM, and break line digitization',
                        'Orthophoto creation',
                        'DEM preparation',
                        'Surface model preparation']
                },
                ],
                text_type: 'bullet-points',
                order: 3,
                photo_item: [photoObjectFormatter(payra_port_outcome)]

            },



        ],

        related_projects: [2, 3, 1],
        cover_image: payra_port_cover,
    },
    {
        id: 12,
        title: 'High Resolution UAV Image Based Physical Feature Map and DEM Preparation for Payra Kuakata Comprehensive Plan ',
        location: 'Barguna & Patuakhali',
        area: '15000 acres',
        client: "UDD",
        funded_by: 'GOB',
        rendered_service: 'GIS / Aerial Mapping',
        content: [
            {
                name: 'Project Description',
                type: 'right-text',
                data: [`UDD has undertaken PKCP project to prepare a comprehensive regional development plan considering the Kuakata sea beach region as a part of entire Kalapara, Galachipa, Rangabali, Amtali, Taltali, Patharghata, and Barguna Sadar Upazilas. This project’s main goal was to create high-resolution image-based physical feature maps for six municipalities. A common method of extracting data is from satellite images through the use of image processing software, but this is a difficult and time-consuming process. Making planning decisions is extremely difficult without an accurate and detailed dataset such as a physical feature or digital elevation model (DEM). UAV images taken at low altitude will aid in the extraction of detailed data in a very short period of time and with high precision, which will help to overcome this problem`],

                order: 1,
                photo_item: [photoObjectFormatter(aerial_mapping_2_desc)],
            },
            {
                text_type: 'bullet-points',
                order: 2,
                type: 'only-photo',
                photo_item: [photoObjectFormatter(aerial_mapping_2_image_2)]
            },
            {
                name: 'Key points of Methodology',
                type: 'text-horizontal',
                data: [{
                    name: 'Key points of Methodology',
                    content: [
                        'Flight and GCP plan preparation',
                        'Image capture and GCP measurement',
                        'Areal triangulation',
                        'Stereo model preparation']
                }, {
                    name: 'Key Points of Outcome',
                    content: ['3D feature, DTM, and break line digitization',
                        'Orthophoto creation',
                        'DEM preparation',
                        'Surface model preparation']
                },
                ],
                text_type: 'bullet-points',
                order: 3,
                photo_item: [photoObjectFormatter(aerial_mapping_2_image_1)]


            },



        ],

        related_projects: [2, 3, 1],
        cover_image: aerial_mapping_2_cover,
    },
    {
        id: 13,
        title: 'UAV Based Topographic Survey of 43 KM Embankment of Padma and Jamuna River',
        location: 'Padma and Jamuna Embankment',
        area: '43 Km',
        client: "NHC, BWDB",
        funded_by: 'ADB',
        rendered_service: 'GIS / Aerial Mapping',
        content: [
            {
                name: 'Project Description',
                type: 'right-text',
                data: [`Embankment\’s monitoring is an integral part of the disaster risk reduction program for Bangladesh. In the absence of rigorous inquiry and appropriate scientific methodologies, embankment monitoring may become more expensive and time-consuming activities. Earth work estimating in any location has gotten easier with the advent of satellite technology and GIS. As a result, it has been planned to use Unmanned Aerial Vehicle (UAV) to inspect and monitor the existing embankments of the Padma and Jamuna river in this project.`],

                order: 1,
                photo_item: [photoObjectFormatter(aerial_mapping_3_desc)],
            },
            {
                text_type: 'bullet-points',
                order: 2,
                type: 'only-photo',
                photo_item: [photoObjectFormatter(aerial_mapping_3_image_1)]
            },
            {
                name: 'Key points of Methodology',
                type: 'text-horizontal',
                data: [{
                    name: 'Key points of Methodology',
                    content: [
                        'Flight and GCP plan preparation',
                        'Image capture and GCP measurement',
                        'Areal triangulation',
                        'Stereo model preparation']
                }, {
                    name: 'Key Points of Outcome',
                    content: ['3D feature, DTM, and break line digitization',
                        'Orthophoto creation',
                        'DEM preparation',
                        'Surface model preparation']
                },
                ],
                text_type: 'bullet-points',
                order: 3,



            },



        ],

        related_projects: [2, 3, 1],
        cover_image: aerial_mapping_3_cover,
    },
    {
        id: 14,
        title: `UAV Based Topographic Survey at Sundarganj Upazilla, Gaibandha District for 10 MW Solar Power Plant Project`,
        location: 'Sundarganj, Gaibandha',
        area: '300 acres ',
        client: "Beximco",
        funded_by: 'Beximco',
        rendered_service: 'GIS / Aerial Mapping',
        content: [
            {
                name: 'Project Description',
                type: 'right-text',
                data: [`The decision to build a 10 MW solar power plant in Sundarganj upazilla, Gaibandha, could result in multifaceted socioeconomic developments in Sundraganj and the larger Gaibandha region. This infrastructure development project comprised land acquisition and proper compensation for displaced households. As a result, aerial mapping of the project area was necessary to improve the accuracy of this acquisition and compensation operation.`],

                order: 1,
                photo_item: [photoObjectFormatter(aerial_mapping_4_desc)],
            },
            {
                text_type: 'bullet-points',
                order: 2,
                type: 'only-photo',
                photo_item: [photoObjectFormatter(aerial_mapping_4_image_1)]
            },
            {
                name: 'Key points of Methodology',
                type: 'text-horizontal',
                data: [{
                    name: 'Key points of Methodology',
                    content: [
                        'Flight and GCP plan preparation',
                        'Image capture and GCP measurement',
                        'Areal triangulation',
                        'Stereo model preparation']
                }, {
                    name: 'Key Points of Outcome',
                    content: ['3D feature, DTM, and break line digitization',
                        'Orthophoto creation',
                        'DEM preparation',
                        'Surface model preparation']
                },
                ],
                text_type: 'bullet-points',
                order: 3,



            },



        ],

        related_projects: [2, 3, 1],
        cover_image: aerial_mapping_4_cover,
    },
    {
        id: 15,
        title: `Topographic Survey including Reduced Level (RL), Cross-section and profiling in Mirersharai Economic Zone under Bangladesh Economic Zone Development Project`,
        location: 'Mirersharai Economic Zone',
        area: '300 acres ',
        client: "DPHE, BEZA",
        funded_by: 'GOB',
        rendered_service: 'Survey / Investigation',
        content: [
            {
                name: 'Project Description',
                type: 'right-text',
                data: [`Mirersharai Economic Zone is the largest economic zone in Bangladesh with the goal of promoting rapid economic growth through increased and diverse industry, employment, production, and export. Topographic profiling of this largest economic zone was undertaken by Department of Public Health Engineering (DPHE). This project integrated several phases of topographic survey including reduced levelling, cross section & profiling of existing road and water supply network, water flow and elevation of the ground level.`],

                order: 1,
                photo_item: [photoObjectFormatter(topo_1_desc)],
            },
            {
                text_type: 'bullet-points',
                order: 2,
                type: 'only-photo',
                photo_item: [photoObjectFormatter(topo_1_image_2), photoObjectFormatter(topo_1_image_1)]
            },
            {
                name: 'Key points of Methodology',
                type: 'left-multi-text',
                data: [{
                    name: 'Key points of Methodology',
                    content: [
                        'GCP plan preparation',
                        'Collection of GCP by RTK',
                        'Spot level were taken at 30m interval of the site and sections by Total station']
                }, {
                    name: 'Key Points of Outcome',
                    content: ['3D feature, DTM, and break line digitization',
                        'Spot level',
                        'Cross sections',
                        'Profiling of water supply network']
                },
                ],
                text_type: 'bullet-points',
                order: 3,
                photo_item: [photoObjectFormatter(topo_1_outcome)]



            },



        ],

        related_projects: [2, 3, 1],
        cover_image: topo_1_cover,
    },
    {
        id: 16,
        title: `Conduction of RL Survey Installed over the Observation well located at 383 unions in 97 Upazilla of 33 District under Bangladesh Rural Water Supply and Sanitation Project (BRWSSP)`,
        location: null,
        area: '383 Unions in Bangladesh',
        client: "DPHE",
        funded_by: 'Water Development Board',
        rendered_service: 'Survey / Investigation',
        content: [
            {
                name: 'Project Description',
                type: 'right-text',
                data: [`Underground water level, ground height, and their proximity to water sources are all essential determinants of safe water quality parameters. DPHE launched the BRWSSP project to ensure consistent access to arsenic-free, safe water in selected rural areas of Bangladesh. For this assignment, observation wells were observed in 383 unions in 97 Upazilla in 33 Districts to determine the relative position and spatial relationship of these water point locations with respect to water table depth, ground height, and mean sea level.`],

                order: 1,
                photo_item: [photoObjectFormatter(rl_1_desc)],
            },
            {
                text_type: 'bullet-points',
                order: 2,
                type: 'only-photo',
                photo_item: [photoObjectFormatter(rl_1_image_1)]
            },
            {
                name: 'Key points of Methodology',
                type: 'text-horizontal',
                data: [{
                    name: 'Key points of Methodology',
                    content: [
                        'GCP plan preparation',
                        'Collection of GCP by RTK',
                        'Interpolation']
                }, {
                    name: 'Key Points of Outcome',
                    content: ['Spatial relationship with water quality parameter',
                        'GIS map',
                        'Up-down of water table of Bangladesh',
                        'Relationship of observation well water table depth with respect to ground level and mean sea level']
                },
                ],
                text_type: 'bullet-points',
                order: 3,



            },



        ],

        related_projects: [2, 3, 1],
        cover_image: rl_1_cover,
    },
    {
        id: 17,
        title: `Socio-Economic and other Related Survey under “Preparation of Payra-Kuakata Comprehensive Plan Focusing on Eco-Tourism (PKCP)”- Package 02 & Package 03`,
        location: 'Amtali, Taltali & Kalapara Upazila​',
        area: null,
        client: "UDD",
        funded_by: 'GOB',
        rendered_service: 'Survey / Investigation',
        content: [
            {
                name: 'Project Description',
                type: 'right-text',
                data: [`Bangladesh government has planned to develop third deep seaport at Kalapara upazila that could play an instrumental role in Bangladesh’s economy and it’s also likely to generate enormous private investments. In addition, the proposed deep seaport in Kalapara would attract many new secondary and tertiary activities, that would have beneficial influence as well as negative effect. This massive development could disrupt the region’s land use pattern, leading to haphazard urbanization`,
                    `That’s why UDD has undertaken PKCP project to prepare a comprehensive regional development plan considering the Kuakata sea beach region as a part of entire Kalapara, Galachipa, Rangabali, Amtali, Taltali, Patharghata, and Barguna Sadar Upazilas. The Package-02 covers Amtali and Taltali Upazila under Barguna District and Kalapara Upazila under Patuakhali District. This development plan package is essential to predict and guide such probable changes in existing land use patterns and to accommodate such a massive establishment and its related changes. The proposed initiative would also address the challenges of climate change and identify suitable adaptive solutions. Development should be for the benefit of the local community, and the community should be able to cope with the new development.`],

                order: 1,
                photo_item: [photoObjectFormatter(socio_1_desc)],
            },
            {
                name: 'Key Points of Methodology',
                text_type: 'bullet-points',
                order: 2,
                type: 'left-text',
                data: [`Identification of key areas, indicators to be studied`,
                    `Questionnaire Design and Apps preparation`,
                    `Sample Size and spatial distribution delineation`,
                    `Household Survey, KII, FGD were conducted`,],
                photo_item: [photoObjectFormatter(socio_1_meth)]
            },
            {
                name: 'Key Points of Outcome',
                text_type: 'bullet-points',
                order: 3,
                type: 'left-text',
                data: [`Identification the pattern, relationship and concentration of demography, income expenditure, livelihood, housing, health, education and culture`,
                    `Livelihood and asset dependency exposure to climate change hotspots/ cold spot`,
                    `Economy, industry, growth center study`,
                    `The quantification of problems as well as their location`,
                    `Identification of vulnerable zone, exposed area and their losses for natural disasters`,
                    `Guiding future development by policy and strategies.`,],
                photo_item: [photoObjectFormatter(socio_1_outcome)]
            },



        ],

        related_projects: [2, 3, 1],
        cover_image: socio_1_cover,
    },
    {
        id: 18,
        title: `Socio-Economic and other Survey under “Preparation of Development Plan for Mirsharai Upazila, Chittagong District: Risk Sensitive Landuse Plan (MUDP)”-Package-02`,
        location: 'Mirsharai, Chittagong​​',
        area: null,
        client: "UDD",
        funded_by: 'GOB',
        rendered_service: 'Survey / Investigation',
        content: [
            {
                name: 'Project Description',
                type: 'right-text',
                data: [`Mirsharai sea beach, hilly area, Mohamaya Chara Lake and Khaiya Chara region has the greater potential for tourism development as there are abundant resources to attract tourists. Bangladesh can generate revenue both domestically and internationally by establishing a tourism resort in Mirsharai. The spot, if properly developed would become an excellent holiday resort and tourist center. Rowing facility could be arranged easily; fishing and hunting facilities were already there. But, Mirsharai was developing in an unplanned and haphazard manner very rapidly due to the ample opportunity for tourism development, which is acting as pull factor for private sector developers. Moreover, the proposed Special Economic Zone would generate many industries related new activities including huge vehicular traffic such as air, rail, road and water. This phenomenon would have both positive and negative impact on the socioeconomic condition of the region. The proposed planning package was undertaken to guide such probable changes in the socio-economic condition and to protect the region from depletion of its natural resources and character and tourism development as well.`],

                order: 1,
                photo_item: [photoObjectFormatter(socio_2_desc)],
            },
            {
                name: 'Key Points of Methodology',
                text_type: 'bullet-points',
                order: 2,
                type: 'left-text',
                data: [`Identification of key areas, indicators to be studied`,
                    `Questionnaire Design and Apps preparation`,
                    `Sample Size and spatial distribution delineation`,
                    `Household Survey, KII, FGD were conducted`,],
                photo_item: [photoObjectFormatter(socio_2_meth)]
            },
            {
                name: 'Key Points of Outcome',
                text_type: 'bullet-points',
                order: 3,
                type: 'left-text',
                data: [`Identification the pattern, relationship and concentration of demography, income expenditure, livelihood, housing, health, education and culture`,
                    `Livelihood and asset dependency exposure to climate change hotspots/ cold spot`,
                    `Economy, industry, growth center study`,
                    `The quantification of problems as well as their location`,
                    `Identification of vulnerable zone, exposed area and their losses for natural disasters`,
                    `Policy and strategies serve as a guide for future development.`,],
                photo_item: [photoObjectFormatter(socio_2_outcome)]
            },



        ],

        related_projects: [2, 3, 1],
        cover_image: socio_2_cover,
    },
    , {
        id: 20,
        title: `Baseline study on the socioeconic status of coastal fishing, communities, and their local 
        fishing practices and techniques, as wall as knowledge of the sundarbans and SoNG marine protected area`,
        display_type_component: <SoNG />,
        /* area: null,
        client: "",
        funded_by: '',
        rendered_service: '',
        content: [
            {
                name: 'Project Description',
                type: 'right-text',
                data: [dummy_placeholder_text, dummy_placeholder_text, dummy_placeholder_text],

                order: 1,
                photo_item: [photoObjectFormatter(dummy_placeholder)],
            }, {
                name: 'Key points of Methodology',
                type: 'left-multi-text',
                data: [{
                    name: 'Key points of Methodology',
                    content: [
                        ...dummy_placeholder_text.split(/[,.]/)
                    ]
                }, {
                    name: 'Key Points of Outcome',
                    content: [...dummy_placeholder_text.split(/[,.]/)]
                },
                ],
                text_type: 'bullet-points',
                order: 2,
                photo_item: [photoObjectFormatter(dummy_placeholder)]

            },
        ], */
        related_projects: [2, 3, 1],
        cover_image: dummy_placeholder,
    }, {
        id: 21,
        title: `Preparation of Risk
        Sensitive Database For Core Area of Rangpur and Sylhet District Town
        (Transportation Surveys for Roadways and Waterways)`,
        display_type_component: <RSPFS2 />,
        /*   area: null,
         client: "",
         funded_by: '',
         rendered_service: '',
         content: [
             {
                 name: 'Project Description',
                 type: 'right-text',
                 data: [dummy_placeholder_text, dummy_placeholder_text, dummy_placeholder_text],
 
                 order: 1,
                 photo_item: [photoObjectFormatter(dummy_placeholder)],
             }, {
                 name: 'Key points of Methodology',
                 type: 'left-multi-text',
                 data: [{
                     name: 'Key points of Methodology',
                     content: [
                         ...dummy_placeholder_text.split(/[,.]/)
                     ]
                 }, {
                     name: 'Key Points of Outcome',
                     content: [...dummy_placeholder_text.split(/[,.]/)]
                 },
                 ],
                 text_type: 'bullet-points',
                 order: 2,
                 photo_item: [photoObjectFormatter(dummy_placeholder)]
 
             },
         ], */
        related_projects: [2, 3, 1],
        cover_image: dummy_placeholder,
    }, {
        id: 22,
        title: `Preparation of Risk Sensitive Database for Core Area of Rangpur and Sylhet District Town
        (Physical Feature Survey Other Survey)`,

        display_type_component: <RangpurSylhetPhysicalFeatureSurvey />,
        related_projects: [2, 3, 1],
        cover_image: dummy_placeholder,
    }, {
        id: 23,
        title: `A Pilot Project on Preparation of Comprehensive Development Plan for Nine Upazilas
        Package 01 (Satellite Image Processing and Geo-rand Geo-referencing, Physical Feature, Landuse Topographic Surveys)`,
        display_type_component: <ComprehensivePlanNineUpazila />,
        related_projects: [2, 3, 1],
        cover_image: dummy_placeholder,
    },
    {
        id: 24,
        title: `Scoping Study of
        The National Sanitation Dashboard`,
        display_type_component: <SanboardScoping />
        /*area: null,
         client: "",
        funded_by: '',
        rendered_service: '',
        content: [
            {
                name: 'Project Description',
                type: 'right-text',
                data: [dummy_placeholder_text, dummy_placeholder_text, dummy_placeholder_text],

                order: 1,
                photo_item: [photoObjectFormatter(dummy_placeholder)],
            }, {
                name: 'Key points of Methodology',
                type: 'left-multi-text',
                data: [{
                    name: 'Key points of Methodology',
                    content: [
                        ...dummy_placeholder_text.split(/[,.]/)
                    ]
                }, {
                    name: 'Key Points of Outcome',
                    content: [...dummy_placeholder_text.split(/[,.]/)]
                },
                ],
                text_type: 'bullet-points',
                order: 2,
                photo_item: [photoObjectFormatter(dummy_placeholder)]

            },
        ],
        related_projects: [2, 3, 1],
        cover_image: dummy_placeholder, */
    },

    {
        id: 19,
        title: 'Job placement and business mentoring support services for skills training graduates in five partner cities',
        display_type_component: <TVET />

    },
]