import React, { useState } from 'react';
import '../Assets/Styles/logoContest.css';
import showToast from '../Utils/Toast';
import "react-toastify/dist/ReactToastify.css";
import axios from 'axios';
import hourglass from '../Assets/Images/time-hourglass.gif'

const CONTEST_EXPIRE_DATE = new Date('02/15/2024').toLocaleDateString()
const current_date = new Date().toLocaleDateString()
const InputField = ({ onChange, placeholder, id, value, required = false, type = 'text', fullWidth = false, accept }) => {

    return <div className={`relative flex-grow pr-2 ${fullWidth ? 'w-full' : ''}`}>
        <input
            id={id}
            value={value}
            onChange={onChange}
            type={type}
            required={required}
            placeholder={placeholder}
            accept={accept}
            className="text-black placeholder-gray-600 w-full px-4 py-2.5 mt-2 text-base transition
             duration-500 ease-in-out transform border-transparent rounded-lg bg-gray-200 focus:border-blueGray-500
              focus:bg-white dark:focus:bg-gray-800 dark:focus:text-gray-200 focus:outline-none focus:shadow-outline focus:ring-2 
              ring-offset-current ring-offset-2 ring-gray-400"
        />
        {!value && required && <p className='absolute top-2 right-4 text-red-600'>*</p>}
    </div>;
};
const SucessModal = ({onClose}) => (<div className='bg-[#f5fbe3] text-3xl font-semibold font-anton text-center rounded-lg'>

    <img  className='rounded-lg' src={hourglass} />
    <button className='rounded-full  border-[1px] px-6 py-1 text-sm mb-1 hover:border-main hover:text-main  ' onClick={onClose}>Close</button>
    <h2>Oops...</h2>
    <p className='pb-4'> Contest Time is Over</p>

</div>)
const LogoContestModal = ({ isOpen, onClose, }) => {

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [institution, setInstitution] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [file, setFile] = useState(null);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [isUploading, setIsUploading] = useState(false);
    const [formSuccess, setFormSuccess] = useState(false);
    const [formError, setFormError] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isFlipped, setIsFlipped] = useState(false);

    const handleFirstNameChange = (e) => setFirstName(e.target.value);
    const handleLastNameChange = (e) => setLastName(e.target.value);
    const handleInstitutionChange = (e) => setInstitution(e.target.value);
    const handleEmailChange = (e) => setEmail(e.target.value);
    const handlePhoneNumberChange = (e) => setPhoneNumber(e.target.value);
    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];

        // Check if file is selected
        if (!selectedFile) {
            // Reset file state if no file is selected
            setFile(null);
            return;
        }

        // Check file size
        if (selectedFile.size > 100 * 1024 * 1024) { // 100 MB in bytes
            // File size exceeds the limit
            alert('File size exceeds the limit of 100 MB');
            // Reset file state
            setFile(null);
        } else {
            // Set file state if within size limit
            setFile(selectedFile);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Check if any field is empty
        if (!firstName || !lastName || !email || !phoneNumber || !file) {
            alert('Please fill out all fields');
            return;
        }

        const url = process.env.REACT_APP_CONTEST_BACKEND;

        // Create form data
        const formData = new FormData();
        formData.append('full_name', `${firstName} ${lastName}`);
        formData.append('submitted_at', new Date().toLocaleDateString());
        formData.append('institute', institution);
        formData.append('email', email);
        formData.append('phone_no', phoneNumber);
        formData.append('file', file);

        try {
            // Set upload progress to 0 and show progress bar
            setUploadProgress(0);
            setIsUploading(true);

            // Send POST request with form data
            const response = await axios.post(url, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                onUploadProgress: (progressEvent) => {
                    // Calculate upload progress percentage with 2 decimal points
                    const progress = Math.round((progressEvent.loaded / progressEvent.total) * 10000) / 100;
                    setUploadProgress(progress);
                },
            });

            // Perform success action (show toast, close modal, etc.)
            showToast({
                message: 'Design submitted successfully',
                position: 'bottom-right',
            });
            setIsSuccess(true);
            setTimeout(() => {
                setIsFlipped(true);
            }, 1000);
            // setTimeout(() => {
            //     onClose()
            // }, 1500);
            // setFormSuccess(true);
            // // Clear all fields
            // setFirstName('');
            // setLastName('');
            // setInstitution('');
            // setEmail('');
            // setPhoneNumber('');
            // setFile(null);
        } catch (error) {
            console.error('Error:', error);
            // Perform error action (show toast, error message, etc.)
            showToast({ type: 'error', message: 'Error submitting form', position: 'bottom-right' });
            setFormError(true);
        } finally {
            // Hide progress bar and reset progress
            setIsUploading(false);
            setUploadProgress(0);
        }
    };


    const handelClose = (e) => {

        onClose()

        setFormSuccess(true);
        setIsSuccess(false)
        setFirstName('');
        setLastName('');
        setInstitution('');
        setEmail('');
        setPhoneNumber('');
        setFile(null);
    }

    return (
        <div className={`modal mt-8 md:mt-0 px-[3%] lg:px-[35%] ${isOpen ? 'open' : ''} rounded-lg`}>
            {current_date < CONTEST_EXPIRE_DATE ? <div className="relative modal-content min-h-[50vh] !px-[6%] ">
                <button className='absolute top-2 text-xl right-4 text-red-500' onClick={handelClose}>X</button>
                <h2 className='text-3xl font-semibold pb-4 lg:text-center'>Company Logo Design Contest</h2>
                {/* <p className='text-xs py-2'>As a top IT-enabled consultancy, we employ innovative technology for sustainable solutions. Our specialized services cover Urban and Rural Development, Software Development, Water and Sanitation, Smart Cities, Transportation, Environment, Climate Change, Energy, Power, and Capacity Building.</p> */}
                {!isSuccess ? <form onSubmit={handleSubmit} className='space-y-2'>
                    <div className='grid grid-cols-1 md:grid-cols-2 w-full'>
                        <InputField type="text" id="firstName" placeholder="First Name" value={firstName} onChange={handleFirstNameChange} required />
                        <InputField type="text" id="lastName" placeholder="Last Name" value={lastName} onChange={handleLastNameChange} required />
                        <InputField type="text" id="institution" placeholder="Institution" value={institution} onChange={handleInstitutionChange} />
                        <InputField type="email" id="email" placeholder="Email" value={email} onChange={handleEmailChange} required />
                        <InputField type="tel" id="phoneNumber" placeholder="Phone Number" value={phoneNumber} onChange={handlePhoneNumberChange} required />
                        <InputField type="file" id="file" onChange={handleFileChange} required accept=".zip" maxSize={100 * 1024 * 1024} />
                    </div>
                    <p className='py-2 text-sm tracking-tight text-justify'><strong>N.B. </strong>Please upload all the files here. Compress the files, including logos in <strong>four different color backgrounds</strong> (both the original file and PNG format), along with explanations and mockups, into a <strong>single ZIP format</strong>. Ensure that the file size does not exceed <strong>100MB.</strong></p>
                    <button
                        type="submit"
                        disabled={isUploading}
                        className={`${isUploading && "cursor-not-allowed"} text-xl hover:tracking-wider w-full pr-2 mx-auto py-2 px-6 text-center text-white rounded-md bg-main relative overflow-hidden`}
                        style={{ border: "8px solid transparent" }} // Initial border width
                    >Submit
                    </button>
                    {isUploading && (<div><progress value={uploadProgress} max="100" className="w-full mt-2 max-h-[4px] " style={{ color: '#ff0000' }}></progress>
                        <p>Uploading: {uploadProgress?.toFixed(2)}%</p>
                    </div>)}
                    {/* {formSuccess && <div className="text-green-600">Success!</div>}
                    {formError && <div className="text-red-600">Error!</div>} */}
                </form> :
                    (
                        <div className="success-message">
                            <h3 className="text-xl font-semibold text-green-600">Success!</h3>
                            <p className="text-sm">Thank you for your submission.</p>
                            <p className="text-sm">A confirmation mail is sent to your email, please check your <i>spam</i> if not found!</p>
                            <br />
                            <p className="text-sm">  For any query, please communicate at <strong>contest.tiller@gmail.com</strong> or <strong>+8801783076798</strong></p>
                        </div>
                    )}
            </div> : <SucessModal  onClose={onClose}/>}

        </div>
    );
};

export default LogoContestModal;
