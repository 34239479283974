import React, { useEffect, useRef, useState } from 'react';
import Pagination from './Pagination';
import NewsCard from './NewsCard';
import { Link } from 'react-router-dom';
import { fetchNewsList } from '../../Utils/NewsHelper';
import '../../Assets/Styles/serviceitem.css';
import { news_content_list } from '../../Utils/dummy_data';

const Horizonscroll = () => {
    const scrollRef = useRef(null);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isDragging, setIsDragging] = useState(false);
    const [startX, setStartX] = useState(0);
    const [startScrollLeft, setStartScrollLeft] = useState(0);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [inView, setInView] = useState(false);
    const [hovered, setHovered] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = news_content_list//await fetchNewsList(setData);
                setData(result);
            } catch (error) {
                console.error(error);
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);
    
    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => setInView(entry.isIntersecting),
            {
                rootMargin: '0px',
                threshold: 0.5,
            }
        );

        if (scrollRef.current) {
            observer.observe(scrollRef.current);
        }

        return () => {
            if (scrollRef.current) {
                observer.unobserve(scrollRef.current);
            }
        };
    }, []);

    // useEffect(() => {
    //     const handleScroll = () => {
    //         const itemWidth = scrollRef.current.scrollWidth / data?.length;
    //         const newIndex = Math.round(scrollRef.current.scrollLeft / itemWidth);
    //         setCurrentIndex(newIndex);
    //     };

    //     const throttleHandleScroll = () => {
    //         window.requestAnimationFrame(handleScroll);
    //     };

    //     if (scrollRef.current) {
    //         scrollRef.current.addEventListener('scroll', throttleHandleScroll);
    //     }

    //     return () => {
    //         if (scrollRef.current) {
    //             scrollRef.current.removeEventListener('scroll', throttleHandleScroll);
    //         }
    //     };
    // }, [data?.length]);
    
    useEffect(() => {
        let timer;
        if (data?.length && scrollToItem && currentIndex !==hovered ) {

            timer = setTimeout(() => {

                scrollToItem(currentIndex < data?.length - 1 ? currentIndex + 1 : 0)
            }, 5000);



        }
        return () => {
            clearTimeout(timer);
        };
    }, [data, currentIndex,hovered]);

    const handleDragStart = (e) => {
        e.preventDefault();
        setIsDragging(true);
        const startX = 'touches' in e ? e.touches[0].pageX : e.pageX;
        setStartX(startX - scrollRef.current.offsetLeft);
        setStartScrollLeft(scrollRef.current.scrollLeft);
    };

    const handleDragMove = (e) => {
        if (!isDragging) return;
        const x = 'touches' in e ? e.touches[0].pageX : e.pageX;
        const distance = x - scrollRef.current.offsetLeft - startX;
        scrollRef.current.scrollLeft = startScrollLeft - distance;
    };

    const handleDragEnd = () => {
        setIsDragging(false);
        const itemWidth = scrollRef.current.scrollWidth / data?.length;
        const newIndex = Math.round(scrollRef.current.scrollLeft / itemWidth);
        setCurrentIndex(newIndex);
    };

    const scrollToItem = (index) => {
        const itemWidth = scrollRef.current.scrollWidth / data?.length;
        const scrollLeft = index * itemWidth;
        scrollRef.current.scrollTo({
            left: scrollLeft,
            behavior: 'smooth',
        });
        setCurrentIndex(index);
    };

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error loading data</div>;

    return (
        <div className='w-full'>
            {data?.length > 0 && (
                <>

                    <h2 className='text-5xl py-20 uppercase font-bold text-main'>
                        <Link to='/'>News and Updates</Link>
                    </h2>

                    <div className='space-y-2'>
                        <div
                            ref={scrollRef}
                            className='relative flex w-full section-container my-auto overflow-x-scroll gap-[2.5rem]'
                            style={{ scrollSnapType: 'x mandatory', overflowY: 'hidden', borderRadius: '10px' }}
                            onTouchStart={handleDragStart}
                            onTouchMove={handleDragMove}
                            onTouchEnd={handleDragEnd}
                            onMouseDown={handleDragStart}
                            onMouseMove={handleDragMove}
                            onMouseUp={handleDragEnd}
                            onMouseLeave={handleDragEnd}
                        >
                            {data.map((item, index) => (
                                <NewsCard
                                    key={index}
                                    item={item}
                                    handleHover={setHovered}
                                    index={index}
                                    currentIndex={currentIndex}
                                />
                            ))}
                        </div>

                        <Pagination handleHover={setHovered} actionHandler={scrollToItem} currentIndex={currentIndex} data={data} />
                    </div>
                </>
            )}
        </div>
    );
};

export default Horizonscroll;
