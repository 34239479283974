import React, { useEffect } from 'react'
import top_photo from '../Assets/Images/about.jpg'
import ceo from '../Assets/Images/ceo.png'
import roadmap from '../Assets/Images/roadmap.png'
import bg_pattern from '../Assets/Images/bg-pattern.jpg'
import bg_video from '../Assets/Videos/uav.mp4'
import Services from '../Components/Services'

import bg_image from '../Assets/Images/about-us-bg.jpg'

const Aboutus = () => {
    useEffect(() => {
        const header_ele = document.getElementsByClassName('header')
        const breadcrumb_ele = document.getElementsByClassName('breadcrumbs-container')
        header_ele[0]?.classList?.remove('header')
        breadcrumb_ele[0]?.classList?.remove('breadcrumbs-container')
    }, [])
    return (
        <div className='-z-10  lg:h-[83.5vh] w-full relative px-[10%]'
            style={{
                backgroundImage: `url(${bg_image})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat'
            }}>

            <div className='z-10 flex flex-col-reverse lg:flex-row  h-full'>
                <div className='ml-auto z-20 w-full h-full'>
                    <div className='w-full lg:bg-[#0b0a0c] h-full lg:w-1/2 py-16 space-y-10'>
                        <div className=' ml-[15%] lg:px-10'>
                            <div className='w-10 h-8 bg-[#f2f2f2] float-left'> </div>
                            <h2 className=' bg-[#3b3a3a]  font-bold uppercase text-[#f2f2f2a6] text-center'>
                                <span className='text-2xl'>V</span>ision</h2>
                            <p className='text-[#f2f2f2a6] text-xs py-2 text-center'>
                                TECHONOLOGY · INNOVATION · SUSTAINABILITY
                            </p>
                            <p className='text-justify p-4 text-[#f2f2f2a6] text-xs'>We strive to make the world a more sustainable place by
                                offering leadership in planning and design, as well as geomatics, infrastructure, and utility
                                network solutions that surpass our clients' expectations.</p>

                        </div>

                        <div className='ml-[15%] lg:px-10 '>
                            <div className='w-10 h-8 bg-[#f2f2f2] float-left'> </div>
                            <h2 className=' bg-[#3b3a3a]  font-bold uppercase text-[#f2f2f2a6] text-center'>
                                <span className='text-2xl'>M</span>ision</h2>

                            <p className='text-start p-4 text-[#f2f2f2a6] text-xs'>Through our extensive surveying
                                experiences and using the latest most efficient equipment, we provide our customers with reliable
                                deliverables on time, every time. Each member of Tiller is committed
                                to exceeding our customers’ expectations. We pledge to our customers:</p>
                            <ul className='text-start px-4 text-[#f2f2f2a6] text-xs space-y-2'>
                                <li className=''><i className="fa-solid fa-circle-chevron-right" /> To be approachable and listen when dealing with client's requirement</li>
                                <li><i className="fa-solid fa-circle-chevron-right" /> To deal with all enquiries promptly</li>
                                <li><i className="fa-solid fa-circle-chevron-right" /> To use the latest technology that save time and money</li>
                                <li><i className="fa-solid fa-circle-chevron-right" /> To acquaint the client with regular project updates and interim drawings if required</li>

                            </ul>


                        </div>
                    </div>
                </div>
                <div className=' z-20 w-full  bg-[#000dd] self-center text-[#f2f2f2bb]'>
                    <p className='uppercase font-bold text-end text-sm'>
                        <span className='text-xl'>I</span>ntroducing</p>
                    <p className='capitalize font-bold text-5xl text-end py-2'>TILLER</p>

                    <p className='text-sm text-end '>Welcome to <span className='font-semibold text-lg'>Tiller</span>, where our journey began in 2009 with a mission to easing spatial solutions. As a leading IT-enabled consultancy firm, we leverage the world's most advanced geo-spatial technologies to empower clients across various sectors. Our specialized services address critical issues related to space, infrastructure, and land use, covering Urban Development, Transport, WASH (Water, Sanitation, and Hygiene), Hydrology, Geology, Information Technology, Disaster & Climate Change, Agriculture & Rural Development, Environment & Natural Resources, and Energy and Power.
                        <br /><br />
                        <p className='hidden lg:block'> At Tiller, we pride ourselves on being pioneers in introducing advanced drone technology for spatial planning, activity monitoring, and investigation in Bangladesh. Our expertise extends to master planning and urban design consultancy, where we lead the way in providing innovative solutions.
                            Our talented team consists of Planners, Urban Designers, Geographers, Analysts, and IT Experts, all dedicated to enhancing living environments in a green, resilient, and smart manner. We are particularly proud of our optimistic Research and Development (R&D) cluster, committed to exploring new avenues for advanced development in technology and knowledge. With a focus on multidimensional evidence, Tiller strives to provide clients with expertise and value.
                        </p>
                      </p>

                </div>
            </div>

            <div className='z-0 absolute top-0 bottom-0 left-0 right-0 bg-gradient-to-br from-[#000000cd] to-[#000] '></div>
        </div>
    )
}

export default Aboutus