import React, { useEffect, useState } from 'react'
import { core_competencies } from '../Utils/dummy_data'

const Sectors = () => {
    const [selectedSector, setSelectedSector] = useState(null)
    const handleSectorSelection = (e, i) => {

        const ele = document.getElementById(e.target.id || e.target.parentElement.id)
        if (ele.id !== selectedSector) {
            if (selectedSector) {
                const prev_ele = document.getElementById(selectedSector)
                prev_ele.classList.remove('selectedSector')
            }
            ele.classList.add('selectedSector')
            setSelectedSector(ele.id)
        }
    }
  


    return (
        <div className='sectors-container'>
            <h2 className='text-5xl py-20 uppercase font-bold text-main'><a /* href='/compentencies' */>Sectors</a></h2>
            <div className={`grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-16 gap-y-8 relative   `}>
                {core_competencies.map((item, index) => {
                    return <div id={`sectors_${index}`} className='relative hover:scale-105 cursor-pointer hover:shadow-lg h-36  flex flex-col-reverse group w-full '
                    
                    style={{ backgroundImage: `url(${item?.photo})`, backgroundPosition: 'center', backgroundSize: 'cover', }}>
                 {/*    <div className='absolute hidden transform transition-transform duration-[1.5s]
   ease-in-out  group-hover:block left-1/2 top-1/2 z-10 -translate-x-1/2 -translate-y-1/2 bg-second text-[#f2f2f2] bg-opacity-80 shadow-lg 
                    px-4 border-[1px] border-black rounded-full cursor-pointer hover:border-main hover:text-main'>View Details</div> */}
                    <div className='px-2 pb-1 text-[#f2f2f2] bg-[#121212] bg-opacity-70 font-semibold  cursor-pointer'>
                        <p className='
   ' >{item.name}</p>
                    </div>

                </div>
                    
                    /*  <div id={`sectors_${index}`} className='relative  top-0 left-0 h-36  flex flex-col-reverse group w-full '
                        onClick={(e) => { handleSectorSelection(e, item) }}
                        style={{ backgroundImage: `url(${item?.photo})`, backgroundPosition: 'center', backgroundSize: 'cover', transition: 'all 1s ease-in-out' }}>
                        <div className='absolute hidden transform transition-transform duration-[1.5s]
       ease-in-out  group-hover:block left-1/2 top-1/2 z-10 -translate-x-1/2 -translate-y-1/2 bg-second text-[#f2f2f2] bg-opacity-80 shadow-lg 
                        px-4 border-[1px] border-black rounded-full cursor-pointer hover:border-main hover:text-main'>View Details</div>
                        <div className='px-2 pb-1 text-[#f2f2f2] bg-[#121212] bg-opacity-70 font-semibold  cursor-pointer'>
                            <p className='transform transition-transform duration-[.5s]
       ease-in-out   group-hover:translate-x-20' >{item.name}</p>
                        </div>

                    </div> */
                })}
            </div>
            {/* <button className=' my-4 float-right  py-2 bg-main text-[#f2f2f2] px-6 rounded-full'><a href='/compentencies'>Learn More</a></button> */}
        </div>
    )
}

export default Sectors