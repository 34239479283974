import React from 'react'
import service_bg from '../Assets/Images/services_new/service-bg.png'
import { servicesArray } from '../Utils/service_util'
import { useNavigate } from 'react-router'
import BreadCrumb from '../Components/BreadCrumb'
import { Link } from 'react-router-dom'

const ServiceCard = ({ props }) => {

    const { small_photo, title, handler, order } = props

    return (<Link to={`/services/${title}`} className='relative h-[25vh] w-full bg-third group'>
        <img src={small_photo} className='object-cover w-full h-full' />
        <div className='absolute bottom-0 left-0 bg-third  h-[30%]  w-full flex items-center justify-between px-4  
        bg-opacity-70 group-hover:bg-opacity-90
        text-[#fff] font-[400] font-sans text-[22.4px] leading-[20.61px] '
            style={{ boxShadow: 'rgb(0,0,0)  0px 2px 2px 0px, rgb(0,0,0) 0px 2px 4px 0px' }}>
            <h4 className='text-[16px] lg:text-[18px] '>{title}</h4>
            <i className="fa-solid fa-arrow-right px-2 py-2 rounded-full bg-main bg-opacity-50 
            group-hover:cursor-pointer group-hover:bg-opacity-70 active:scale-95">
            </i>
        </div>

    </Link>)
}


const Services = () => {
    const navigate = useNavigate()
    const itemClickHandler = (e, item, order) => {
        e.stopPropagation()
        e.preventDefault()
        if (item.order == 1 || item.order == 3 || item.order == 5 || item.order == 6 || item.order == 8 || item.order == 7) {
            navigate(item.title);
        }
        else {

        }
    }

    return (
        <div className='bg-third'>
            <BreadCrumb />
            <div className='relative w-full h-[20vh]' style={{ backgroundImage: `url(${service_bg})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>

                <div className='absolute top-0 left-0 right-0 bottom-0 bg-second bg-opacity-40'>

                </div>
                <h4 className='absolute top-[calc(100%-60px)] left-[15%] text-3xl font-semibold'>Services</h4>
            </div>
            <div className='py-8 px-[15%] grid  lg:grid-cols-2 gap-x-6 gap-y-10 cursor-pointer pb-28'>
                {servicesArray.sort((a, b) => a.order - b.order).map((item, index) => {
                    const props = { ...item, handler: (e) => itemClickHandler(e, item, index) }
                    return <ServiceCard props={props} />
                })}


            </div>

        </div>
    )
}

export default Services