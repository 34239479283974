
import './App.css';
import { AppContext } from './Utils/Context';
import { BrowserRouter } from 'react-router-dom';
import Router from './Utils/Router';
import { ToastContainer } from 'react-toastify';
import  './Assets/Styles/style.css'
const App = () => {

  const data = {}

  return (
    <AppContext.Provider value={data}>
      <BrowserRouter>
        <div className='App '>
          <Router primary={false} />
          <ToastContainer />
        </div>
      </BrowserRouter>
    </AppContext.Provider>
  );
}

export default App;
