import React from 'react'

const Map = ({ }) => (
    <div className="border-2 mt-4 rounded-md">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3650.2783494930086!2d90.36526427597354!3d23.808699086534485!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755c1191bf7bf87%3A0x6b5866cdfb61749b!2sTiller!5e0!3m2!1sen!2sbd!4v1701671973480!5m2!1sen!2sbd" width="600" height="450"
            className='w-full h-[32vh] border-0 rounded-md'
            allowfullscreen="" loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"></iframe>
    </div>
)

export default Map