import React from 'react'
import aboutus1 from '../Assets/Images/about-us.png'

import aboutus2 from '../Assets/Images/about-us-2.png'

const AboutUs = () => {

    return (
        <div className='w3-animate-bottom '>
            <h2 className='uppercase text-main text-5xl font-bold py-8'><a href='/about'>About Us</a></h2>
            <div className='grid grid-cols-1 md:grid-cols-5 gap-x-4'>
                <p className='col-span-full text-justify text-lg my-auto leading-6 tracking-tight'>Welcome to Tiller, a multifaceted Planning &amp; Design and Software Development consultation firm
                    with a mission of easing spatial solution since 2009. We leverage cutting-edge technology in Urban
                    Development, Transport, Disaster &amp; Climate Change. Pioneering advanced drone tech, we&#39;re
                    dedicated to creating smart, resilient environments for a sustainable future. Tiller brings together
                    independent expertise and next-generation technology for optimal decision-making through location
                    intelligence. Our specialized offerings include GIS Mapping, Remote Sensing, Photogrammetry,
                    UAV/DRONE Survey, LiDAR Point Cloud, Advanced Analytics, Artificial Intelligence (AI), Internet
                    of Things (IoT), and Modeling &amp; Simulation. Join us in crafting innovative solutions for a sustainable
                    tomorrow. </p>
                {/*   <p className=' md:col-span-full text-justify text-lg my-auto'>Tiller presents together independent expertise and cutting-edge technology to make it easier
                    to make the best decisions possible with location intelligence. UAV / Drone, LiDAR,
                    GIS and Remote Sensing, Photogrammetry, Advanced Analytics, Apps
                    and Dashboard, Modelling & Simulation, and Artificial Intelligence
                    are some of the technology-based specializations are offered to provide
                    groundbreaking innovative solutions to achieve sustainability.</p> */}
                {/* <img src={aboutus1} className='md:col-start-4 col-span-2  w-full ' /> */}
            </div>
            <div className='grid grid-cols-1 md:grid-cols-5 gap-x-4'>
                {/*  <img src={aboutus2} className=' w-full col-span-2 ' /> */}
                {/*   <p className='md:col-start-4 md:col-span-2 text-justify text-lg my-auto'>Tiller presents together independent expertise and cutting-edge technology to make it easier
                    to make the best decisions possible with location intelligence. UAV / Drone, LiDAR,
                    GIS and Remote Sensing, Photogrammetry, Advanced Analytics, Apps
                    and Dashboard, Modelling & Simulation, and Artificial Intelligence
                    are some of the technology-based specializations are offered to provide
                    groundbreaking innovative solutions to achieve sustainability.</p> */}

            </div>

        </div>
    )
}

export default AboutUs