import React from 'react'
import { services_list } from '../Utils/utils'
import { servicesArray } from '../Utils/service_util'
import { useNavigate } from 'react-router'
import { Link } from 'react-router-dom'

import clmnt from '../Assets/Icons/services/Climate.svg'

const two_item_border = (index) => `${index % 2 == 0 ? "border-r-[1px] border-b-[1px]" : " border-b-[1px]"} ${index == 6 || index == 7 ? " !border-b-0" : " "}`
const fous_item_border = (index) => `${index < 4 ? "lg:border-b-[1px] " : " lg:border-r-[1px] "}  ${index > 3 ? " lg:border-b-0" : ""} lg:border-r-[1px] ${(index == 3 || index == 7) ? " lg:!border-r-0" : " "}`




const Services = () => {
    const navigate = useNavigate()
    const handleClick = (event, dataItem) => {
        navigate((`services/${dataItem.title}`))
    }

    return (
        <div className='services-div' id='services-div'>
            <h2 className='text-5xl py-20 uppercase font-bold text-main'><a href='/services'>Services</a></h2>
            <div className='grid grid-cols-2 lg:grid-cols-4  text-center'>

                {servicesArray.sort((prev, next) => prev.order - next.order).map((item, index) => {

                    return (<div className={`p-4 group   border-black ${two_item_border(index)} ${fous_item_border(index)}  `}>
                        <div className='  bg-opacity-40  mx-auto flex justify-center items-center 
                           rounded-full border-black hover:border-main border-2  w-[70%]'>
                            <Link className='h-full w-full p-8 md:p-9 lg:p-14    lg:px-10 xl:px-16' to={`/services/${item.title}`}>  <div className='cursor-pointer h-full w-full' ><img src={item.icon} />
                            </div>
                            </Link>
                        </div>

                        <Link to={`/services/${item.title}`}>  <p className='my-2 group-hover:text-main cursor-pointer' >{item.title}</p></Link>
                    </div>)
                })}
            </div>
            <a className=' my-4 float-right  py-2 bg-main text-[#f2f2f2] px-6 rounded-full' href='/services'>   Learn More</a>
        </div>
    )
}

export default Services