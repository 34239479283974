import React, { useEffect } from 'react'
import { servicesArray } from '../Utils/service_util'
import BreadCrumb from '../Components/BreadCrumb'
// import '../Assets/Styles/serviceitem.css'

import FullPage from '@fullpage/react-fullpage';
import { useParams } from 'react-router'
import { projects, sectors } from '../Utils/Projects'

import ServiceSideNav from '../Components/Service_Layouts/ServiceSideNav'
import CoverSectionContent from '../Components/Serviceitems/CoverSection'
import MaskedImage from '../Components/Serviceitems/MaskedImage'
import SectionFrame from '../Components/Serviceitems/SectionFrame'




const ServiceItem = () => {

  const service_name = useParams().name
  const serviceItem = servicesArray.find(item => item.title == service_name)

  const coverContent = {
    title: serviceItem.title,
    description: serviceItem.description,
    listItems: serviceItem.list?.map(el => el.title) || null,
    relatedProjects: projects?.filter(item => {
      /* This is Shit Code ###NEEDS TO BE CHANGED###  */
      return serviceItem.related_projects
        .includes(item.id) && []
          .concat(...sectors.map(el => el.projects))
          .find(el => el.project_id == item.id)?.status != 'dev'
    })
  }
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [service_name]);


  useEffect(() => {
    if (!(serviceItem?.display_component)) {

      const header_ele = document.getElementsByClassName('header')
      const breadcrumb_ele = document.getElementsByClassName('breadcrumbs-container')
      header_ele[0]?.classList?.remove('header')
      breadcrumb_ele[0]?.classList?.remove('breadcrumbs-container')
    }
  }, [serviceItem?.display_component])



  return (
    <div className=' w-full '>
      <BreadCrumb />
      <ServiceSideNav id={serviceItem.id} />
      {serviceItem?.display_component ? <div>{serviceItem?.display_component}</div> :
        (<div className='section-container' key={serviceItem.id}
         /*  style={{  scrollSnapType: 'y mandatory',  overflowY: 'scroll', }} */ >
          <FullPage
            scrollingSpeed={1000}
            verticalCentered={false}

            render={({ state, fullpageApi }) =>
            (<div id="fullpage-wrapper"
              className=' w-full h-full  '>
              {/* COVER PAGE */}
              <section
                className='relative section  overflow-hidden h-full ' >

                <CoverSectionContent props={coverContent} />
                <MaskedImage src={serviceItem.cover_image} extra_classes={' lg:!bottom-[18rem]'} />
                <div className=' cursor-pointer absolute bottom-0 left-1/2 -translate-x-1/2 text-white hover:bg-gradient-to-t
       from-[#1a19199d] to-[#818080a1] hover:px-4 hover:pt-2 hover:rounded-t-full'>
                  <i className="fa-solid fa-chevron-down fa-xl fa-beat-fade"></i>
                </div>


              </section>
              {/* COVER PAGE */}

              {serviceItem?.list?.map((item, index) => {
                const formatedItem = {
                  title: item.title, titleImage: item.titleImage,
                  bodyVideo: item.bodyVideo,
                  single_image: item.single_image,
                  bodyImage: item.bodyImage, color: item.color,
                  content_type: item.type,
                  mobile_body: item?.mobile_body,
                  subElements: item.sub, type: index % 2 == 0 ? "ltr" : "rtl"
                }
                return <SectionFrame cover={serviceItem.cover_image} content={formatedItem} />
              })}
            </div>)}
          />

        </div>)}

    </div>
  )
}

export default ServiceItem